import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import QuickReport from "../components/QuickReport"
import Loader from "../components/Loader"
import { system_param_api_getParams } from "../api/system_param_api"
import history from "../utils/history"

const SystemParameters = () => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [pageNo, setPageNo] = useState(1)

  useEffect(() => {
    refreshData(pageNo)
  }, [])

  const fetchNextPage = () => {
    let _pageNo = pageNo
    _pageNo++
    refreshData(_pageNo)
    setPageNo(_pageNo)
  }
  const fetchPreviousPage = () => {
    let _pageNo = pageNo
    if (_pageNo !== 1) {
      _pageNo--
      refreshData(_pageNo)
      setPageNo(_pageNo)
    }
  }

  const refreshData = async (page) => {
    setIsLoading(true)
    let _res = await system_param_api_getParams(page)
    setData(_res.data.data)
    setIsLoading(false)
  }

  const search = async (searchText) => {
    setIsLoading(true)
    let _res = await system_param_api_getParams(1, searchText)
    setData(_res.data.data)
    setIsLoading(false)
  }

  // const handleRowAction = (index, action) => {
  //   //console.log(data.data.detail[index]);
  //   switch (action) {
  //     case "zoom":
  //       history.push(`/viewTransaction/${data.data.detail[index].remit_id}`)
  //       break;

  //     default:
  //       break;
  //   }
  // }

  return (
    <>
      <Helmet>
        <title>{isLoading ? "Querying..." : data && data.config.title}</title>
      </Helmet>
      <QuickReport
        data={data}
        refreshData={refreshData}
        rowAction={null}
        search={search}
        fetchPreviousPage={fetchPreviousPage}
        fetchNextPage={fetchNextPage}
        currentPageNo={pageNo}
        isLoading={isLoading}
        //source="PostedTransactionReport"
      />
    </>
  )
}

export default SystemParameters