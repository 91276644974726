
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import React, { useEffect, useState } from 'react'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft, faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { getCountryName } from '../components/CountryList'
import DocumentPreviewer from '../components/DocumentPreviewer/DocumentPreviewer'
import SectionHead from '../components/SectionHead'
// import { sender_api_create } from '../api/sender_api';

const TransactionWizardStepOne = ({ data, showAddSenderPopup }) => {
  const [flagUrl, setFlagUrl] = useState()

  useEffect(() => {
    if ((typeof data !== 'undefined') && (data.sender.country_code !== "")) {
      setFlagUrl(process.env.REACT_APP_FRONTEND_URL + "/flags/" + data.sender.country_code + ".svg",)
    }

  }, [data])

  const handleNull = (val) => {
    return "" || val
  }

  if (data.sender.id === "") {
    return (
      <div className="text-center text-gray-600 mt-4 font-semibold text-sm py-2 bg-gray-100 rounded">Please search and select a sender or you can <button className="text-blue-400 font-semibold underline" onClick={() => showAddSenderPopup(true)}>add new here</button>.</div>
    )
  }
  return (
    <div className="mt-3 font-publicSans rounded border border-gray-200 shadow px-1 pb-1">
      <SectionHead titleColor="gray-500" title="Sender Details" topMargin="mt-61" borderColor="blue" />
      {/* <div className="grid grid-cols-6 gap-4 text-sm items-baseline">
        <div className="text-right font-semibold text-gray-700">Sender Code:</div>
        <input type="text" name="code" className="text-gray-400 font-semibold h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={handleNull(data.sender.id)} readOnly />
      </div> */}
      <div className="grid grid-cols-6 gap-4 text-sm items-baseline mt-1">
        <div className="text-right font-semibold text-gray-700">First Name:</div>
        <div className=""><input type="text" name="first_name" className="text-gray-400 text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={handleNull(data.sender.first_name)} readOnly /></div>
        <div className="text-right font-semibold text-gray-700">Middle Name:</div>
        <input type="text" name="middle_name" className="text-gray-400 text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={handleNull(data.sender.middle_name)} readOnly />
        <div className="text-right font-semibold text-gray-700">Last Name:</div>
        <input type="text" name="last_name" className="text-gray-400 text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={handleNull(data.sender.last_name)} readOnly />
      </div>
      <div className="grid grid-cols-6 gap-4 text-sm items-baseline mt-1">
        <div className="text-right font-semibold text-gray-700">Gender:</div>
        <input type="text" name="gender" className="text-gray-400 text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={handleNull(data.sender.gender)} readOnly />
        <div className="text-right font-semibold text-gray-700">Date of Birth:</div>
        <input type="text" name="dob" className="text-gray-400 text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={handleNull(data.sender.dob)} readOnly />
      </div>
      <div className="grid grid-cols-6 gap-4 text-sm items-baseline mt-1">
        <div className="text-right font-semibold text-gray-700">Mobile Number:</div>
        <input type="text" name="mobile_no" className="text-gray-400 text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={handleNull(data.sender.mobile_no)} readOnly />
        <div className="text-right font-semibold text-gray-700">Home Number:</div>
        <input type="text" name="home_no" className="text-gray-400 text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={handleNull(data.sender.home_no)} readOnly />
        <div className="text-right font-semibold text-gray-700">Email:</div>
        <input type="text" name="email" className="text-gray-400 text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none " value={handleNull(data.sender.email)} readOnly />
      </div>
      <div className="grid grid-cols-6 gap-4 text-sm items-baseline mt-1">
        <div className="col-span-1 text-right font-semibold text-gray-700">Street Address:</div>
        <input type="text" name="street_address" className="text-gray-400 text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={handleNull(data.sender.street_address)} readOnly />
        <div className="col-span-1 text-right font-semibold text-gray-700">City:</div>
        <input type="text" name="city" className="text-gray-400 text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none " value={handleNull(data.sender.city)} readOnly />
      </div>
      <div className="grid grid-cols-6 gap-4 text-sm items-baseline mt-1">
        <div className="text-right font-semibold text-gray-700">Country:</div>
        <div className="flex">
          <input type="text" name="country_code" className="text-sm text-gray-400 font-semibold h-7 mt-1 focus:outline-none uppercase" value={handleNull(getCountryName(data.sender.country_code))} readOnly />
          <img className="w-8" src={flagUrl} alt="" />
        </div>
        <div className="text-right font-semibold text-gray-700">Postal Code:</div>
        <input type="text" name="postal_code" className="text-gray-400 text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={handleNull(data.sender.postal_code)} readOnly />
      </div>
      <hr className="my-4" />
      <div className="grid grid-cols-3 gap-4 text-sm">
        <div>
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-1">
            <div className="text-right font-semibold text-gray-700">ID Type:</div>
            <input type="text" name="id_type" className="text-gray-400 text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={handleNull(data.sender.id_type)} readOnly />
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-1">
            <div className="text-right font-semibold text-gray-700">ID Number:</div>
            <input type="text" name="id_number" className="text-gray-400 text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={handleNull(data.sender.id_number)} readOnly />
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-1">
            <div className="text-right font-semibold text-gray-700">ID Expiry Date:</div>
            <input type="text" name="id_expiry" className="text-gray-400 text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={handleNull(data.sender.id_expiry)} readOnly />
          </div>
          {/* <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-1">
            <div className="text-right font-semibold text-gray-700">KYC File:</div>
            <input type="text" name="kyc_file" className="text-gray-400 text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={handleNull(data.sender.kyc_file)} readOnly />
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-1">
            <div className="text-right font-semibold text-gray-700">Risk Score:</div>
            <div className="text-gray-400 flex items-baseline">
              <input type="text" name="risk_score" className="text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={handleNull(data.sender.risk_score)} readOnly />
              <span>%</span>
            </div>
          </div> */}
        </div>
        <DocumentPreviewer
          documents={data.sender.documents}
          cssClass="shadow"
          colSpan="2"
          docColumns="6"
          readOnly />
      </div>
    </div>
  )
}

export default TransactionWizardStepOne