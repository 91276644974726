import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes, faSpinner, faArrowDown, faArrowRight, faCalculator, faUndo } from '@fortawesome/free-solid-svg-icons';
import { getBankName } from '../components/BankList';
import { getBranchName } from '../components/BankBranchList';
import PaymentMethodList from '../components/PaymentMethodList';
import CurrencyList from '../components/CurrencyList';
import { getCountryName } from '../components/CountryList';
import { fetchExchangeRates } from "../utils/currency_rates"
import SectionHead from '../components/SectionHead';
import { formatNumberToCurrency, formatCurrencyToNumber } from "../utils/currency_util"
import { isNumber } from '../utils/number_util';
import DocumentPreviewer from '../components/DocumentPreviewer/DocumentPreviewer';
import DocumentUploader from '../components/DocumentUploader/DocumentUploader';
import { tx_fee_api_get_fees } from "../api/tx_fee_api"
import { initial } from 'lodash';

const TransactionWizardStepTwo = ({ data, updateData, updateDataItem, showAddReceiverPopup, transactionOwner, exchangeRates, setExchangeRatesInParent, fetchData }) => {
  const [txDetailsMarkup, setTxDetailsMarkup] = useState()
  const [fees, setFees] = useState({ status: "", type: "", data: "", code: "", message: "" })
  
  useEffect(() => {
    const init = async () => {
      var ret = await tx_fee_api_get_fees()
      if (typeof ret === 'object' && ret !== null) {
        setFees(ret)
      }
    }
    init()
  }, [])

  var emptyTxDetail = {
    id: "",
    payment_method: "",
    currency_code: "",
    amount: "",
    rate: "",
    transfer_rate: "",
    transfer_amount: "",
    created_at: "",
    updated_at: ""
  }

  const getExchangeRateX = (currency) => {
    return exchangeRates[currency]
  }

  const getGridSum = () => {
    var grid_sum = [...data.tx_details].reduce((acc, item) => {
      if (isNumber(item.amount) && isNumber(item.rate)) {
        acc += (10000 * parseFloat(item.amount) / (10000 * parseFloat(item.rate)))
      }
      return acc
    }, 0)
    return grid_sum
  }

  useEffect(() => {
    const grid_sum = getGridSum()
    updateData("transaction", {
      ...data.transaction,
      usd_balance: Math.ceil((data.transaction.usd_to_pay - grid_sum) * 100) / 100
    })
  }, [data.transaction.usd_to_pay, data.tx_details])

  const revCalc = () => {
    var tr = 0
    if (typeof data.transaction.out_rate !== 'undefined') {
      tr = data.transaction.out_rate
    }

    var grid_sum = parseInt(getGridSum() * 100) / 100
    var fee = getFee(grid_sum)
    var usd_to_send = (grid_sum + parseFloat(data.transaction.discount) - parseFloat(fee))
    console.log(data)
    updateData("transaction", {
      ...data.transaction,
      usd_to_pay: grid_sum,
      discount: 0,
      fees: fee,
      usd_to_send: usd_to_send,
      out_amount: getOutAmount(usd_to_send),
    })
  }

  const handleTxDetailsInputChange = (e, index) => {
    const _getTransferAmount = (val) => {
      return (10000 * val / (10000 * data.tx_details[index].rate)) * data.tx_details[index].transfer_rate
    }

    var alteredRec = [...data.tx_details]
    if (e.target.name === "amount") {
      alteredRec[index] = {
        ...data.tx_details[index],
        amount: e.target.value,
        transfer_amount: _getTransferAmount(e.target.value)
      }
    } else {
      alteredRec[index] = {
        ...data.tx_details[index],
        [e.target.name]: e.target.value
      }
    }

    updateData("tx_details", alteredRec)

    if (e.target.name === "currency_code") {
      let exchange_rate_obj = getExchangeRateX(e.target.value)
      const [curr_amount, curr_transfer_amount] = calculateRowValues(e, index, alteredRec[index], exchange_rate_obj)
      alteredRec[index] = {
        ...alteredRec[index],
        rate: exchange_rate_obj.rate,
        transfer_rate: exchange_rate_obj.transfer_rate,
        amount: curr_amount,
        transfer_amount: curr_transfer_amount
      }
      updateData("tx_details", alteredRec)
    }
  }
  const resetUsd = useRef(null)
  // task014 & task015 start
  const reset = () => {
    resetTxDetails()
    updateData("transaction", {
      id: "",
      tx_date: new Date(),
      usd_to_pay: 0,
      discount: 0,
      fees: 0,
      usd_to_send: 0,
      out_currency_code: "LKR",
      out_amount: 0,
      out_rate: getExchangeRateX("LKR").transfer_rate,
      tx_notes: "",
      tx_status: "",
      tx_creator_id: "",
      tx_owner_id: "",
      sender_id: "",
      receiver_id: "",
      transfer_type_id: 1,
      documents: [],
      created_at: "",
      updated_at: ""
    })
    resetUsd.current.value=0
  }
  // end

  useEffect(() => {
    if ((typeof data.transaction.out_currency_code !== "undefined") && (data.transaction.out_currency_code !== "") && (exchangeRates)) {
      let exchange_rate_obj = exchangeRates[data.transaction.out_currency_code]
      updateData("transaction", { ...data.transaction, out_rate: exchange_rate_obj.rate, out_transfer_rate: exchange_rate_obj.transfer_rate, out_transfer_amount: 1111 })
    }
  }, [data.transaction.out_currency_code])

  useEffect(() => {
    var tr = 0
    if (typeof data.transaction.out_rate !== 'undefined') {
      tr = data.transaction.out_rate
    }
    var temp = data.transaction.usd_to_send * parseFloat(tr)
    temp = parseInt(temp * 100) / 100
    updateData("transaction", { ...data.transaction, out_amount: temp })
  }, [data.transaction.out_rate])

  const adjustTxDetails = (newOutAmount) => {
    var usdRow = Object.assign({}, data.tx_details.filter(item => item.currency_code === "USD")[0])
    var ilsRow = Object.assign({}, data.tx_details.filter(item => item.currency_code === "ILS")[0])
    var usdAmount = parseFloat(usdRow.amount)
    var ilsAmount = parseFloat(ilsRow.amount)
    var ilsRate = parseFloat(ilsRow.rate)
    var newOutAmountInUsd = 10000 * newOutAmount / (10000 * parseFloat(data.transaction.out_rate))
    var balanceInUsd = newOutAmountInUsd - usdAmount
    var balanceInIls = balanceInUsd * ilsRate

    ilsRow.amount = balanceInIls.toFixed(2)

    var newTxDetails = data.tx_details.map(item => {
      if (item.currency_code === "ILS") {
        return ilsRow
      }
      return item
    })
  }

  const resetTxDetails = () => {
    const TxDetails = Object.assign({}, data.tx_details)
    const tx_details = data.tx_details.map(item => {
      item.amount = 0
      return item
    })
    updateData("tx_details", tx_details)
  }

  const getFee = (usd_to_send) => {
    let feeSlot = fees.data
      .filter(item => {
        if ((usd_to_send >= item.from_amount) && (usd_to_send <= item.to_amount)) {
          return true
        }
      })
    if (Array.isArray(feeSlot) && (feeSlot.length > 0)) {
      return feeSlot[0].fee
    }
    return 0
  }

  const getUsdToPay = (usd_to_send, fees) => {
    resetTxDetails()
    let ret = usd_to_send + fees
    return ret
  }

  const getOutAmount = (usd_to_send) => {
    var out_amount = 0

    if (data.transaction.currency_code === "USD") {
      out_amount = usd_to_send
    } else {
      out_amount = Math.round(parseFloat(usd_to_send) * data.transaction.out_rate * 100) / 100
      if (isNaN(out_amount)) {
        out_amount = 0
      }
    }

    return out_amount
  }

  const getUsdToSend = (out_amount) => {
    var usd_to_send = 0

    if (data.transaction.currency_code === "USD") {
      usd_to_send = out_amount
    } else {
      usd_to_send = 10000 * parseFloat(out_amount) / (10000 * data.transaction.out_rate)
      if (isNaN(usd_to_send)) {
        usd_to_send = 0
      }
    }

    return usd_to_send
  }

  // task014 start
  const handleOutput = (e) => {
    let usdtopay = e.target.value
    let fee = getFee(usdtopay)
    let finalAmount = usdtopay - fee
    let lastAmount = finalAmount*data.transaction.out_rate
    updateData("transaction", { ...data.transaction, out_amount: lastAmount,  usd_to_pay: usdtopay, fees:fee, usd_to_send: finalAmount})

  }
  // end
  
  const handleTransactionInputChange = (e) => {
    var usd_to_send = 0
    var usd_to_pay = 0
    var out_amount = 0
    var fees = 0

    if (e.target.name === "out_currency_code") {

      updateData("transaction", { ...data.transaction, [e.target.name]: e.target.value, out_amount: "loading" })

    } else if (e.target.name === "out_amount") {

      if (e.target.value === "") {
        updateData("transaction", {
          ...data.transaction,
          out_amount: ""
        })
      } else {
        usd_to_send = getUsdToSend(e.target.value)
        fees = getFee(parseFloat(usd_to_send))
        usd_to_pay = getUsdToPay(parseFloat(usd_to_send), parseFloat(fees))

        updateData("transaction", {
          ...data.transaction,
          usd_to_pay: usd_to_pay.toFixed(2),
          discount: 0,
          fees: fees,
          usd_to_send: usd_to_send.toFixed(2),
          out_amount: parseFloat(e.target.value)
        })
      }
    } else if (e.target.name === "usd_to_send") {

      if (e.target.value === "") {
        updateData("transaction", {
          ...data.transaction,
          usd_to_send: ""
        })
      } else {
        var usd_to_send = parseFloat(e.target.value)
        fees = getFee(parseFloat(usd_to_send))
        usd_to_pay = getUsdToPay(parseFloat(usd_to_send), parseFloat(fees))
        out_amount = getOutAmount(parseFloat(usd_to_send))

        updateData("transaction", {
          ...data.transaction,
          usd_to_pay: usd_to_pay.toFixed(2),
          discount: 0,
          fees: fees,
          usd_to_send: parseFloat(e.target.value),
          out_amount: out_amount,
        })
      }
    }
    else {
      updateData("transaction", { ...data.transaction, [e.target.name]: e.target.value })
    }
  }

  const handleDelete = (index) => {
    var temp = [...data.tx_details]
    temp.splice(index, 1)
    updateData("tx_details", temp)
    // updateGridSum(temp)
    temp = null
  }

  const addNewTxDetail = () => {
    updateData("tx_details", [...data.tx_details, emptyTxDetail])
  }

  const setAmountOnCellClick = (e, index) => {
    const [curr_amount, curr_transfer_amount] = calculateRowValues(e, index)

    const updated_tx_details = data.tx_details.map((item, i) => {
      if (index === i) {
        item.amount = curr_amount
        item.transfer_amount = curr_transfer_amount
      }
      return item
    })

    updateData("tx_details", [...updated_tx_details])
  }

  const calculateRowValues = (e, index, row_object, rate_object) => {
    const _convertToOtherCurrency = (p_amount, this_currency_code, p_ils_currency_rate) => {
      var amount = parseFloat(p_amount)
      var ils_currency_rate = parseFloat(p_ils_currency_rate)
      if ((typeof ils_currency_rate === 'undefined') || (ils_currency_rate === 0)) {
        return 0
      }

      if (this_currency_code === "USD") {
        return amount * ils_currency_rate
      } else {
        return 10000 * amount / (10000 * ils_currency_rate)
      }
    }

    var curr_amount = 0
    var curr_transfer_amount = 0
    var curr_row_rate = data.tx_details[index].rate
    var curr_currency_code = data.tx_details[index].currency_code
    var curr_transfer_rate = data.tx_details[index].transfer_rate
    var usd_to_pay = data.transaction.usd_to_pay

    if (typeof row_object !== 'undefined') {
      curr_row_rate = rate_object.rate
      curr_currency_code = row_object.currency_code
      curr_transfer_rate = rate_object.transfer_rate
      usd_to_pay = data.transaction.usd_to_pay
    }

    const other_row_index = () => {
      if (index === 0) {
        return 1
      }
      return 0
    }

    if (parseFloat(usd_to_pay) > 0) {
      if ((data.tx_details.length > 1)) {
        // && (data.tx_details[other_row_index()].amount !== "") && (!isNaN(data.tx_details[other_row_index()].amount))
        var other_amount = 0
        var other_currency_code = ""
        var other_rate = 0
        var other_transfer_amount = 0
        var other_transfer_rate = 0

        const { amount, currency_code, rate, transfer_amount, transfer_rate } = data.tx_details[other_row_index()]
        other_amount = amount
        other_currency_code = currency_code
        other_rate = rate
        other_transfer_amount = transfer_amount
        other_transfer_rate = transfer_rate

        if (curr_currency_code === "USD") {
          if (_convertToOtherCurrency(other_amount, "ILS", other_rate) !== parseFloat(usd_to_pay)) {
            curr_amount = parseFloat(usd_to_pay) - _convertToOtherCurrency(other_amount, "ILS", other_rate)
            curr_amount = Math.ceil(parseFloat(curr_amount) * 100) / 100
            curr_transfer_amount = parseFloat(usd_to_pay) - _convertToOtherCurrency(other_transfer_amount, "ILS", other_transfer_rate)
            curr_transfer_amount = Math.round(parseFloat(curr_transfer_amount) * 100) / 100
          }
        } else {
          if (usd_to_pay !== parseFloat(other_amount)) {
            curr_amount = _convertToOtherCurrency(usd_to_pay, "USD", curr_row_rate) - _convertToOtherCurrency(other_amount, "USD", curr_row_rate)
            curr_amount = Math.ceil(parseFloat(curr_amount) * 100) / 100
            curr_transfer_amount = _convertToOtherCurrency(usd_to_pay, "USD", curr_transfer_rate) - _convertToOtherCurrency(other_amount, "USD", curr_transfer_rate)
            curr_transfer_amount = Math.round(parseFloat(curr_transfer_amount) * 100) / 100
          }
        }
      } else {
        if (curr_currency_code === "USD") {
          curr_amount = parseFloat(usd_to_pay)
          curr_transfer_amount = curr_amount
        } else {
          curr_amount = _convertToOtherCurrency(usd_to_pay, "USD", curr_row_rate)
          curr_amount = Math.ceil(parseFloat(curr_amount) * 100) / 100
          curr_transfer_amount = _convertToOtherCurrency(usd_to_pay, "USD", curr_transfer_rate)
          curr_transfer_amount = Math.round(parseFloat(curr_transfer_amount) * 100) / 100
        }
      }
    }
    return [curr_amount, curr_transfer_amount]
  }

  useEffect(() => {
    console.log('START DATA EFFECT')
    console.log(data);
    console.log('END DATA EFFECT')
    setTxDetailsMarkup(
      data.tx_details.map((rec, index) => {
        return (
          <tr key={index} className="hover:bg-yellow-100 h-7">
            <td className="border-l border-b border-gray-200 text-center">
              <button className="focus:outline-none w-full py-1 hover:bg-gray-200" onClick={() => handleDelete(index)}>
                <FontAwesomeIcon icon={faTimes} className="text-center text-red-400" />
              </button>
            </td>
            <td className="border border-gray-200">
              <select name="currency_code" value={rec.currency_code} onChange={(e) => handleTxDetailsInputChange(e, index)} className="text-blue-400 py-1 font-semibold focus:outline-none w-full">
                <CurrencyList hide={["LKR"]} />
              </select>
            </td>
            <td className="border border-gray-200">
              <input name="amount" type="text"
                onChange={(e) => handleTxDetailsInputChange(e, index)}
                onFocus={(e) => setAmountOnCellClick(e, index)}
                value={rec.amount}
                className="w-full h-7 px-1 text-sm text-blue-400 font-semibold text-right focus:outline-none" />
            </td>
            <td className={"border border-gray-200 text-center " + (rec.rate === "loading" ? "bg-pink-200" : "")}>
              {
                (rec.rate === "loading") &&
                <FontAwesomeIcon icon={faSpinner} className="spinner text-gray-600" />
              }
              {
                (rec.rate !== "loading") &&
                <input name="rate" type="number"
                  value={rec.rate}
                  readOnly
                  className="w-full h-7 px-1 text-sm text-gray-400 text-right font-semibold focus:outline-none" />
              }
            </td>
          </tr>
        )
      })
    )
  }, [data])

  if (data.receiver.id === "") {
    return (
      <div className="text-center text-gray-600 mt-4 font-semibold text-sm py-2 bg-gray-100 rounded">Please search and select a receiver or you can <button className="text-blue-400 font-semibold underline" onClick={() => showAddReceiverPopup(true)}>add new here</button>.</div>
    )
  }

  const setAttachedDocuments = (doc) => {
    if (data.transaction.documents.length > 0) {
      updateData("transaction", {
        ...data.transaction,
        documents: [...data.transaction.documents, doc]
      })
    } else {
      updateData("transaction", {
        ...data.transaction,
        documents: [doc]
      })
    }
  }

  const deleteDoc = (index) => {
    let _docs = [...data.transaction.documents.filter((item, i) => (i !== index))]
    updateData("transaction", {
      ...data.transaction,
      documents: [..._docs]
    })
  }

  

  return (
    <>
      <div className="grid grid-cols-12 gap-4 text-sm mt-4">
        <div className="col-span-4">
          <div className=" rounded border border-gray-200 shadow px-1 pb-3">
            <SectionHead titleColor="gray-700" title="Receiver Details" topMargin="mt-61" borderColor="blue" />
            <div className="px-1">
              <div className="grid grid-cols-3 gap-2 mt-5 font-inter">
                <div className=" text-gray-400 text-sm">Receiver Name:</div>
                <input type="text" name="recipient"
                  className="col-span-2 text-left border-b1 font-semibold overflow-ellipsis text-sm h-8a p-1a text-gray-600 focus:outline-none focus:border-gray-300 uppercase1"
                  value={data.receiver.first_name + " " + (data.receiver.middle_name === null ? '' : data.receiver.middle_name) || '' + " " + (data.receiver.last_name === null ? '' : data.receiver.last_name)}
                  readOnly />
                <div className=" text-gray-400 text-sm mt-4">Account:</div>
                <input type="text" name="account"
                  className="col-span-2 text-left font-semibold mt-4 border-b1 text-sm h-8a p-1a w-fulla text-gray-600 focus:outline-none focus:border-gray-300 overflow-ellipsis"
                  value={data.receiver.account_no}
                  readOnly />
                <input type="text" name="account"
                  className="col-span-2 col-start-2 text-left font-semibold mt-1a border-b1 text-sm h-8a p-1a w-fulla text-gray-600 focus:outline-none focus:border-gray-300 overflow-ellipsis"
                  value={getBankName(data.receiver.bank)}
                  readOnly />
                <input type="text" name="account"
                  className="col-span-2 col-start-2 text-left font-semibold mt-1a border-b1 text-sm h-8a p-1a w-fulla text-gray-600 focus:outline-none focus:border-gray-300 overflow-ellipsis"
                  value={getBranchName(data.receiver.bank, data.receiver.branch)}
                  readOnly />
              </div>            
            </div>
          </div>
          <div className=" rounded border border-gray-200 shadow mt-3 p-1 h-40a overflow-y-auto1">
            <SectionHead titleColor="gray-700" title="Documents" topMargin="mt-61" borderColor="blue" />
            <DocumentPreviewer
              documents={data.transaction.documents}
              setAttachedDocuments={(doc) => setAttachedDocuments(doc)}
              deleteDoc={deleteDoc}
              cssClass=""
              colSpan="3"
              docColumns="3"
              hideLabel="true"
            />
          </div>
        </div>
        {/* task014 start */}
        <div className="col-span-8">
          <IlsUsdSection
            data={data}
            resetUsd={resetUsd}
            txDetailsMarkup={txDetailsMarkup}
            addNewTxDetail={addNewTxDetail}
            handleTransactionInputChange={handleTransactionInputChange}
            revCalc={revCalc}
            reset={() => reset()}
            transactionOwner={transactionOwner}
            handleOutput={handleOutput}  
          />
        </div>
      </div>
    </>
  )
}

const IlsUsdSection = ({ data, txDetailsMarkup, addNewTxDetail, handleTransactionInputChange, revCalc, reset, transactionOwner ,handleOutput ,resetUsd}) => {
// end
  return (
    <div className="border rounded border-gray-200 shadow pb-2 px-1 mb-3">
      <SectionHead titleColor="gray-700" title="Transaction Details" topMargin="mt-61" />
      <div className="w-full px-1 mt-3 text-right">
        <button
          className="col-start-2 px-3 py-1 bg-gradient-to-b from-green-100 to-green-300 hover:from-green-200 hover:to-green-400 rounded border border-green-200 text-gray-700"
          onClick={() => reset()}
        >
          <FontAwesomeIcon icon={faUndo} className={"mr-2 text-gray-500"} />
          Reset
        </button>
      </div>
      <div className="w-full px-1 mt-3">
        <div className="grid grid-cols-4 gap-3">
          <div>
            <div className="font-semibold text-gray-700 text-left col-span-1 mb-1">USD to Send:</div>
            <input type="text" name="usd_to_send" value={data.transaction.usd_to_send} onChange={(e) => handleTransactionInputChange(e)} className="text-right col-span-1 border shadow rounded-md text-sm font-semibold h-8 p-1 1w-full text-blue-400 focus:outline-none focus:border-gray-300" />
          </div>
          <div>
            <div className="font-semibold text-gray-700 text-left mb-1">Currency Out:</div>
            <select name="out_currency_code" value={data.transaction.out_currency_code} onChange={(e) => handleTransactionInputChange(e)} className="text-right col-span-1 border shadow rounded-md text-sm font-semibold h-8 p-1 w-full text-blue-400 focus:outline-none focus:border-gray-300">
              <CurrencyList selected={data.transaction.out_currency_code} hide={["ILS"]} />
            </select>
          </div>
          <div>
            <div className="font-semibold text-gray-700 text-left mb-1">Currency Out Rate:</div>
            <input type="text" name="out_rate" value={data.transaction.out_rate} readOnly className="text-right border shadow rounded-md text-sm font-semibold h-8 p-1 w-full text-gray-400 focus:outline-none focus:border-gray-300" />
          </div>
          <div>
            <div className="font-semibold text-gray-700 text-left mb-1">Amount to Receive:</div>
            {
              (data.transaction.out_amount === "loading") &&
              <div className="flex items-center justify-center border rounded shadow h-8 py-1 bg-pink-200"><FontAwesomeIcon icon={faSpinner} className="spinner text-gray-600" /></div>
            }
            {
              (data.transaction.out_amount !== "loading") &&
              <input type="text" name="out_amount" value={data.transaction.out_amount} onChange={(e) => handleTransactionInputChange(e)} className="text-right border shadow rounded-md text-sm font-semibold h-8 p-1 1w-full text-blue-400 focus:outline-none focus:border-gray-300" />
            }
          </div>
          <div className="mt-4">
            <div className="font-semibold text-gray-700 text-left mb-1">Discount:</div>
            <input type="text" name="discount" value={data.transaction.discount} onChange={(e) => handleTransactionInputChange(e)} className="text-right border shadow rounded-md text-sm font-semibold h-8 p-1 text-blue-400 focus:outline-none focus:border-gray-300" />
          </div>
          <div className="row-span-3 col-span-3 rounded-t-md border-t overflow-hidden mt-4 border rounded-md">
            <table className="text-sm w-full">
              <thead>
                <tr className="bg-gradient-to-b from-gray-100 to-gray-200 text-gray-700 h-7">
                  <th className="w-1/12 border-l border-b"></th>
                  <th className="w-3/12 border-l border-b">Currency</th>
                  <th className="w-3/12 border-l border-b">Amount</th>
                  <th className="w-2/12 border-l border-b border-r">Rate</th>
                </tr>
              </thead>
              <tbody>
                {
                  txDetailsMarkup
                }
                <tr className="h-7 border-l ">
                  <td className="border-b border-r border-gray-200 ">
                    <button onClick={() => addNewTxDetail()} className="w-full h-full1 text-green-500 py-2 focus:outline-none hover:bg-gray-100">
                      <FontAwesomeIcon icon={faPlus} className={""} />
                    </button>
                  </td>
                  <td colSpan='4' className="text-right pt-2">
                    <button
                      className="bg-gradient-to-b from-red-100 to-red-300 rounded border hover:from-red-200 hover:to-red-400 px-2 py-1 border-red-200 text-gray-700"
                      onClick={() => revCalc()}
                    >
                      <FontAwesomeIcon icon={faCalculator} className={"mr-2 text-gray-500"} />
                      Rev. Calc.
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div className="font-semibold text-gray-700 text-left mb-1">Total Fees:</div>
            <input type="text" name="fees" value={data.transaction.fees} onChange={(e) => handleTransactionInputChange(e)} className="text-right border shadow rounded-md text-sm font-semibold h-8 p-1 text-blue-400 focus:outline-none focus:border-blue-300" />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-3">
          <div className="grid grid-cols-2 gap-2 items-center col-start-2">
            <div className="font-semibold text-gray-700 text-right1 mb-1">USD to Pay:</div>
            <input type="text" name="usd_to_pay" value={data.transaction.usd_to_pay} onChange={(e) => handleOutput(e)} className="text-right border shadow rounded-md text-sm font-semibold h-8 p-1 w-full text-blue-400 focus:outline-none focus:border-gray-300" />
          </div>
          <div className="grid grid-cols-2 gap-2 items-center">
            <div className="font-semibold text-gray-700 text-right1">USD Balance:</div>
            <input type="text" name="usd_to_pay" ref={resetUsd} value={data.transaction.usd_balance} className="text-right border shadow rounded-md text-sm font-semibold h-8 p-1 text-gray-400 focus:outline-none focus:border-gray-300" readOnly />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionWizardStepTwo