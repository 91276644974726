import React, { useContext, useEffect, useState } from "react"
import { Helmet } from 'react-helmet-async';
import GlobalVarContext from "../utils/GlobalVarContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity, faCreditCard, faCalendar, faMoneyBill, faDollarSign} from '@fortawesome/free-solid-svg-icons';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, AreaChart, Area, BarChart,Bar, Legend} from 'recharts';
import { report_utility_api_getDashboardBank } from "../api/report_utility_api"

// import Layout from "../components/Layout"

const Dashboard = () => {
  const [apiResponse, setApiResponse] = useState({ status: "", type: "", data: "", code: "", message: "" })
  const [localData, setLocalData] = useState({})
  const data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400}];


  useEffect(() => {
    retreiveBankData()
  }, [])

  const retreiveBankData = async (page) => {
    //setIsLoading(true)
    let _res = await report_utility_api_getDashboardBank()
    console.log(_res.data.data);
    setLocalData(_res.data.data)
    //setIsLoading(false)
  }


  let nav = useContext(GlobalVarContext).navigator
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className="max-w-7xl mx-auto px-5 ">
      {
      nav && (nav.length > 0) &&
        <div>
        <div class="py-3 text-xl font-poppins font-semibold text-gray-600">Dashboard</div>  
        <div className="grid grid-cols-12 gap-6 items-center font-poppins">
          <div className="flex justify-items-center phone:flex-col phone:max-w-sm tab:max-w-max desktop:max-w-full tab:flex-row col-span-12 bg-dashboardgray-50 py-6">
            <div className="col-span-2  ml-24 mr-5 font-poppins  text-gray-600  h-20  ">
              <div className="flex items-center justify-center">
                <div className="px-3">
                  <FontAwesomeIcon icon={faUniversity} className={"text-xl text-dashboardblue-400"} />
                </div>
                <div>
                  <h2 className="w-full text-left text-xs pt-1 font-semibold">BANK TX COUNT</h2>
                  <h4 className="w-full text-left text-xs  text-gray-400">Today</h4>
                </div>
              </div>
              <h1 className="text-center text-3xl  font-inter text-gray-700">12</h1>            
            </div>
            <div className="col-span-2  mx-12  font-poppins  text-gray-600  h-20  ">
              <div className="flex items-center justify-center">
                <div className="px-3">
                  <FontAwesomeIcon icon={faCreditCard} className={"text-xl text-dashboardblue-400"} />
                </div>
                <div>
                  <h2 className="w-full text-left text-xs pt-1 font-semibold">BANK FEE TOTAL</h2>
                  <h4 className="w-full text-left text-xs  text-gray-400">Today</h4>
                </div>
              </div>
              <h1 className="text-center text-3xl  font-inter text-gray-700">225</h1>
            
            </div>
            <div className="col-span-2  mx-12  font-poppins  text-gray-600  h-20  ">
              <div className="flex items-center justify-center">
                <div className="px-3">
                  <FontAwesomeIcon icon={faDollarSign} className={"text-xl text-dashboardblue-400"} />
                </div>
                <div>
                  <h2 className="w-full text-left text-xs pt-1 font-semibold">BANK Tx TOTAL</h2>
                  <h4 className="w-full text-left text-xs  text-gray-400">Today</h4>
                </div>
              </div>
              <h1 className="text-center text-3xl font-inter text-gray-700">10,200</h1>
            
            </div>
            <div className="col-span-2  mx-12  font-poppins  text-gray-600  h-20  ">
              <div className="flex items-center justify-center">
                <div className="px-3">
                  <FontAwesomeIcon icon={faCalendar} className={"text-xl text-yellow-500"} />
                </div>
                <div>
                  <h2 className="w-full text-left text-xs pt-1 font-semibold">BANK TX COUNT</h2>
                  <h4 className="w-full text-left text-xs  text-gray-400">This Month</h4>
                </div>
              </div>
              <h1 className="text-center text-3xl  font-inter text-gray-700">65</h1>            
            </div>
            <div className="col-span-2  mx-12  font-poppins  text-gray-600  h-20  ">
              <div className="flex items-center justify-center">
                <div className="px-3">
                  <FontAwesomeIcon icon={faMoneyBill} className={"text-xl text-yellow-500"} />
                </div>
                <div>
                  <h2 className="w-full text-left text-xs pt-1 font-semibold">BANK FEE TOTAL</h2>
                  <h4 className="w-full text-left text-xs  text-gray-400">This Month</h4>
                </div>
              </div>
              <h1 className="text-center text-3xl  font-inter text-gray-700">1,200</h1>
            </div>
          </div>
          <div className="col-span-6  max-w-full rounded-md bg-white overflow-hidden  font-poppins  text-gray-500 text-xs ">
            <h3 className="font-poppins pb-5 text-gray-600 font-semibold">Daily Performance</h3>
                <AreaChart
                  width={500}
                  height={300}
                  data={localData.dayData}
                  syncId="anyId"
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                </AreaChart>
          </div>
          <div className="col-span-6  max-w-full rounded-md bg-white overflow-hidden  font-poppins  text-gray-500 text-xs ">
          <h3 className="font-poppins pb-5 text-gray-600 font-semibold">Monthly Performance</h3>
            <BarChart
              width={500}
              height={300}
              data={localData.monthData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              {/* <Bar dataKey="pv" fill="#8884d8" /> */}
              <Bar dataKey="uv" fill="#5194D6" />
            </BarChart>
          </div>

        </div>
      </div>
      } 
      {
      nav && (nav.length === 0) &&
        <h1 className="prose lg:prose-xl">        
       Account has been suspended, Please contact the system administrator</h1>
      } 
      {/* <pre>{JSON.stringify(nav,null,4)}</pre> */}
      </div>
    </>
  )
}

export default Dashboard