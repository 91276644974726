import _ from "lodash"
import React, { useState, useCallback, useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { _query as agent_api_query } from "../api/agent_api"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faSpinner, faChevronLeft, faChevronRight, faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import history from "../utils/history"
import { report_utility_api_getAgentTxSummary } from "../api/report_utility_api"
import { formatNumberToCurrency } from "../utils/currency_util";

const AgentReport = () => {
  const [apiResponse, setApiResponse] = useState({ status: "", type: "", data: "", code: "", message: "" })
  const [searchResults, setSearchResults] = useState([])
  const [searchText, setSearchText] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [pageNo, setPageNo] = useState(1)

  const delayedQuery = useCallback(_.debounce((searchText) => {
    var ret = ""
    setSearchResults()
    setIsLoading(true)
    setPageNo(1)
    report_utility_api_getAgentTxSummary(1, searchText)
      .then(res => {
        setSearchResults(res.data)
        setIsLoading(false)
      })
      .catch(err => {
        if (err.response) {
          ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
        } else if (err.request) {
          ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
        } else {
          ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
        }
        setIsLoading(false)
      });

  }, 100), []);

  const fetchAll = () => {
    setIsLoading(true)
    setSearchResults()
    report_utility_api_getAgentTxSummary(pageNo, "")
      .then(res => {
        setSearchResults(res.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchAll()
  }, [])

  useEffect(() => {
    (searchText !== "") && delayedQuery(searchText);
    (searchText === "") && fetchAll()
  }, [searchText, pageNo])

  const openStatement = (agent_id) => {
    history.push(`/agents/${agent_id}/payments`)
  }

  return (
    <>
      <Helmet>
        <title>Agent Report</title>
      </Helmet>
      <div className={"max-w-7xl mx-auto px-4 mt-6"}>
        {/* <div className="flex justify-between items-center bg-gray-100 py-1 rounded-md px-2 mb-6">
          <div className="font-publicSans text-lg font-semibold text-gray-700">Agent Report</div>
        </div> */}

        <div className="flex items-center">
          <label className="text-lg font-inter mr-2">Agent Statement:</label>
          <input
            className="h-8a border-ba focus:outline-none text-left text-gray-500"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            name="agent_name"
            placeholder="search..."
          />
          {
            isLoading &&
            <FontAwesomeIcon icon={faSpinner} className="spinner text-gray-500" />
          }
        </div>

        <div className="mt-8">
          {/* <div className="flex justify-between items-center bg-gray-50 py-1 rounded-md px-2 mb-6">
            <div className="font-publicSans text-base font-semibold text-gray-500">Agent Statement</div>
          </div> */}
          <div className="mt-4 rounded-md border-yellow-5001 border-t-4 border-blue-400">
            <table className="table-fixed w-full font-publicSans">
              <thead>
                <tr className="border text-xs shadow bg-gray-100 border-l-2 border-r-2">
                  <th className="px-1 w-10 border text-gray-600 text-left py-2">Agent</th>
                  <th className="px-1 w-10 border text-gray-600 text-left py-2">Status</th>
                  <th className="px-1 w-10 border text-gray-600 text-right py-2">Submit Limit (USD)</th>
                  <th className="px-1 w-10 border text-gray-600 text-right py-2">Balance in USD</th>
                  <th className="px-1 w-10 border text-gray-600 text-right py-2">USD Cash Balance</th>
                  <th className="px-1 w-10 border text-gray-600 text-right py-2">ILS Cash Balance</th>
                  <th className="px-1 w-10 border text-gray-600 text-right py-2">Total Tx Balance (USD)</th>
                  <th className="px-1 w-5 border text-gray-600 text-center py-2">Statement</th>
                </tr>
              </thead>
              {
                Array.isArray(searchResults) && (searchResults.length > 0) &&
                <tbody className=" text-sm">
                  {
                    searchResults.map((row, index) => {
                      return (
                        <tr key={index} className="text-gray-700 font-inter border-l-2 border-r-2 hover:bg-gray-50 hover:text-blue-800 hover:shadow hover:border-blue-400">
                          <td className="px-1 border text-left py-2">{row.agent}</td>
                          <td className="px-1 border text-left">{row.status}</td>
                          <td className="px-1 border text-right">{formatNumberToCurrency(row.submit_limit_usd, 2)}</td>
                          <td className="px-1 border text-right">{formatNumberToCurrency(row.balance_usd, 2)}</td>
                          <td className="px-1 border text-right">{formatNumberToCurrency(row.usd_to_pay, 2)}</td>
                          <td className="px-1 border text-right">{formatNumberToCurrency(row.ils_to_pay, 2)}</td>
                          <td className="px-1 border text-right">{formatNumberToCurrency(row.total_tx_balance_usd, 2)}</td>
                          <td className="px-1 border text-center"><button onClick={() => openStatement(row.id)} className="focus:outline-none"><FontAwesomeIcon icon={faLink} className={"text-gray-500 hover:text-blue-500 text-sm"} /></button></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              }
              {
                Array.isArray(searchResults) && (searchResults.length === 0) && (!isLoading) &&
                <tbody>
                  <tr><td colSpan="8" className="text-center font-semibold text-sm text-gray-500 py-6">No records found</td></tr>
                </tbody>
              }
              {
                isLoading &&
                <tbody>
                  <tr><td colSpan="8" className="text-center font-semibold text-sm text-gray-500 py-6">Searching...</td></tr>
                </tbody>
              }
            </table>
            <hr className=" border-gray-100 my-3" />
            <div className="flex justify-end items-center font-publicSans text-xs mt-3">
              <div className="mr-2 font-publicSans text-gray-500 font-semibold">Page No: <span className="ml-1 mr-2">{pageNo}</span></div>
              <button
                disabled={pageNo === 1 ? true : false}
                onClick={() => setPageNo(pageNo - 1)}
                className={"w-24 h-8 rounded py-1 bg-gray-50 font-semibold shadow focus:outline-none " + (pageNo === 1 ? "text-gray-400 cursor-default" : "text-gray-600 hover:bg-blue-500 hover:text-white cursor-pointer")}
              >
                {
                  !isLoading &&
                  <FontAwesomeIcon icon={faCaretLeft} className="mr-3" />
                }
                {
                  isLoading &&
                  <FontAwesomeIcon icon={faSpinner} className="spinner mr-2" />
                }
                Previous
              </button>
              <button
                onClick={() => setPageNo(pageNo + 1)}
                className="ml-3 w-24 h-8 rounded py-1 bg-gray-50 font-semibold shadow focus:outline-none text-gray-600 hover:bg-blue-500 hover:text-white"
              >
                Next
                {
                  !isLoading &&
                  <FontAwesomeIcon icon={faCaretRight} className="ml-3" />
                }
                {
                  isLoading &&
                  <FontAwesomeIcon icon={faSpinner} className="spinner ml-2" />
                }
              </button>
            </div>
          </div>
        </div>
        {/* <pre>{JSON.stringify(searchResults, null, 4)}</pre> */}
      </div>
    </>







  )
}

export default AgentReport