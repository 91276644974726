import React, { useContext, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faFileUpload, faTimes, faDollarSign, faMoneyBillAlt, faCheck, faBuilding } from '@fortawesome/free-solid-svg-icons';
// import { file_uploader_api_upload } from "../../api/file_uploader_api"
// import { AlertBoxType, AlertBoxResult } from "../../utils/alert_box"
import { exchange_house_api_get_exch_houses } from "../api/exchange_house_api"
import GlobalVarContext from "../utils/GlobalVarContext";
import { fetchExchangeRates } from "../utils/currency_rates";

const TransactionOwnerSelector = ({ setTransactionOwner, closeTransactionOwnerModal, setExchangeRates, data, updateData }) => {
  const [isCash, setIsCash] = useState(true)
  const [fetchingExchangeRates, setFetchingExchangeRates] = useState(false)

  let exHouses = useContext(GlobalVarContext).exchangeHouses

  const selectCash = () => {
    setIsCash(true)
  }

  const selectBank = () => {
    setIsCash(false)
  }

  const handleClickOk = () => {
    setFetchingExchangeRates(true)
    fetchExchangeRates()
      .then(res => {
        setExchangeRates(res)
        updateData("transaction", {
          ...data.transaction,
          out_currency_code: "LKR",
          out_rate: res.LKR.rate,
          out_transfer_rate: res.LKR.transfer_rate
        })
        setTransactionOwner(isCash ? exHouses[1] : exHouses[0])
      })
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="font-sans inline-block align-bottom bg-white rounded text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md phone:max-w-lg phone:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:text-left font-publicSans w-full">
                <div className="flex justify-between py-2">
                  <h3 className="flex text-lg leading-6 font-semibold items-center text-gray-700 mr-3 h-7" id="modal-title">
                    Transaction Type
                  </h3>
                  <button
                    onClick={() => closeTransactionOwnerModal()}
                    className="flex justify-center items-center h-8 w-8 roundedz -mt-8 -mr-6 text-red-500 bg-red-50 hover:bg-red-500 hover:text-gray-50 focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faTimes} className={"text-lg"} />
                  </button>
                </div>
                <hr className="border-b-1 border-gray-300" />
                <div
                  onClick={() => selectCash()}
                  className={"flex transition-colors justify-between items-center mt-3 p-2 rounded-xl border cursor-pointer " + ((isCash == true) ? "border-blue-500 text-blue-500 bg-blue-100" : "border-gray-300 text-gray-200 bg-gray-50")}
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faCheck} className={"text-2xl mr-2 "} fixedWidth />
                    <span className="font-semibold">Cash</span>
                  </div>
                  <FontAwesomeIcon icon={faMoneyBillAlt} className={"text-4xl "} fixedWidth />
                </div>
                <div
                  onClick={() => selectBank()}
                  className={"flex justify-between items-center mt-3 p-2 rounded-xl border cursor-pointer " + ((isCash == false) ? "border-blue-500 text-blue-500 bg-blue-100" : "border-gray-300 text-gray-200 bg-gray-50")}
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faCheck} className={"text-2xl mr-2 "} fixedWidth />
                    <span className="font-semibold">Bank</span>
                  </div>
                  <FontAwesomeIcon icon={faBuilding} className={"text-4xl "} fixedWidth />
                </div>
                {/* <div className="flex justify-center items-center mt-6 h-12">
                  <div className={"flex text-xs items-center justify-center cursor-pointer transition-all "}
                    onClick={() => setIsCash(!isCash)}
                    onKeyDown={(e) => handleToggleKeyDown(e)}
                  >
                    <div className={"mr-2 text-xl " + (isCash ? "font-semibold text-gray-700" : "text-gray-400")}>{exchgHouses[1].description}</div>
                    <div className={"flex border border-blue-300 shadow-inner rounded-full h-4 w-28 bg-gray-50 m-0 items-center " + (isCash ? "justify-start" : "justify-end")}>
                      <div className="rounded-full shadow-md h-7 w-7 bg-blue-500 my-0 mx-1 p-0"></div>
                    </div>
                    <div className={"ml-2 text-xl " + (isCash ? "text-gray-400" : "font-semibold text-gray-700")}>{exchgHouses[0].description}</div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse flex items-center">
            <button
              onClick={() => handleClickOk(isCash)}
              type="button"
              disabled={fetchingExchangeRates}
              className={"inline-flex h-8 justify-center items-center rounded-md border w-20 px-4 text-xs font-semibold focus:outline-none sm:ml-3 uppercase " + (fetchingExchangeRates ? "bg-gradient-to-b from-gray-50 to-gray-100 text-gray-400 cursor-default" : "bg-blue-500 text-white hover:bg-blue-600 hover:shadow-md ")}
            >
              <span className={(fetchingExchangeRates ? "hidden" : "")}>OK</span>
              <FontAwesomeIcon icon={faSpinner} className={"text-4xla " + (fetchingExchangeRates ? "spinner" : "hidden")} fixedWidth />
            </button>
            {
              fetchingExchangeRates && <span className="text-xs text-blue-500">Fetching information from server...</span>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionOwnerSelector