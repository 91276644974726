import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const PrintPreviewer = ({ reportKey, reportId, closeDialog }) => {
  const [fileLoaded, setFileLoaded] = useState(false)

  const onLoadCapture = (e) => {
    setFileLoaded(true)
  }

  return (
    <div className="fixed z-10 inset-0  overflow-y-auto top-5 h-full" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
      <div className="flex items-end justify-center min-h-full pt-1 px-4 pb-10 text-center sm:block sm:p-0 top-10 h-auto">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity top-0" aria-hidden="false"></div>
        {/* <span className="hidden sm:inline-block sm:align-middle sm:h-full h-full top-0" aria-hidden="false">&#8203;</span> */}
        <div className="font-sans inline-block align-bottom bg-white rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:max-h-6xl w-full h-full ">
          <div className="justify-center px-1 pt-1 pb-1 ">
            <div className="bg-gray-50 px-1 pb-2 flex justify-end">
              <button
                onClick={() => closeDialog()}
                type="button"
                className="inline-flex justify-center items-center rounded-md border border-transparent shadow-sm w-20 px-4 py-2 bg-blue-500 text-white text-xs font-semibold hover:bg-blue-600 hover:shadow-md focus:outline-none sm:ml-3 uppercase "
              >
                {
                  !fileLoaded &&
                  <FontAwesomeIcon icon={faSpinner} className="spinner mr-2" />
                }
                Close
              </button>
              {

              }
            </div>
        
            <embed
              src={`${process.env.REACT_APP_BACKEND_URL}/api/printPreviewers?reportKey=${reportKey}&reportId=${reportId}#toolbar=1&navpanes=0&scrollbar=0`}
              //width="500"
              className={fileLoaded ? "w-full" : "invisible"}
              onLoadCapture={(e) => onLoadCapture(e)}
              height="600" 
              />
          </div>
</div>
      </div>
    </div>
  )
}

export default PrintPreviewer