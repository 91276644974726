const ErrorMessage = ({popupClose }) => {
    return (
        <div className="fixed inset-0 z-50 grid place-items-center bg-slate-900 bg-opacity-10">
            <div className="py-6 px-4 flex gap-6 flex-col relative bg-white shadow-md rounded-md w-96">
                <p className="text-center text-lg text-bold">Cannot be deleted. This agent already has transations.</p>
                <div className="text-center space-x-4">
                <button className="bg-gray-100 p-1 border-2 border-gray-300 px-4 py-1" onClick={popupClose}>Ok</button>
            </div>
            </div>
        </div>
    );
}

export default ErrorMessage;