import React from "react"

const SectionHead = ({ title, titleColor, topMargin }) => {
  return (
    <div className={" px-2 rounded bg-gradient-to-b from-gray-100 to-gray-300 py-2 my-1 " + topMargin}>
      <div className="flex">
        <h1 className={"text-" + titleColor + " text-base font-semibold leading-4"}>
          {title}
        </h1>
      </div>
      {/* <hr className={"border-" + borderColor + "-400 border-b"} /> */}
    </div>
  )
}

SectionHead.defaultProps = {
  title: "Untitled",
  topMargin: "mt-0",
  borderColor: "gray"
}

export default SectionHead