
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCheck, faUserEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import SearchResults from "./SearchResults"
import { useState } from "react";

const SearchBox = ({show, click,searchText, handleSearchTextChange, showEditDlg, showAddDlg, stepNumber, updateData, showEditButton, showAddButton, section, api, displayColumns, additionalParameters }) => {
  const [arrowCounter, setArrowCounter] = useState(0)

  const processArrowCounter = (e) => {
    if (e.code === "ArrowDown") {
      setArrowCounter(arrowCounter + 1)
    } else if (e.code === "ArrowUp") {
      setArrowCounter(arrowCounter - 1)
    }
  }

  return (    
    <div className="col-span-4">
      <div className="flex  leading-6">
        <input
          type="text"
          value={searchText}
          onClick={click}
          onChange={(e) => handleSearchTextChange(e.target.value)}
          // onKeyDown={(e) => processArrowCounter(e)}
          className="shadow rounded-full bg-white px-2 h-7 w-60 text-gray-600 focus:outline-none focus:border-blue-400"
          placeholder="search..." />
              
        <button
          onClick={() => showEditDlg(true)}
          className="ml-2 text-sm font-semibold rounded-full border border-gray-300 text-gray-500 w-8 h-8 bg-white hover:bg-gray-100 hover:text-gray-500 hover:border-gray-400 hover:shadow-md focus:outline-none "
          hidden={!showEditButton}
        >
          <FontAwesomeIcon icon={faUserEdit} className="text-blue-500" />
        </button>
        {
          <button
            onClick={() => showAddDlg(true)}
            className="ml-2 text-sm font-semibold rounded-full border border-gray-300 text-gray-500 w-8 h-8 bg-white hover:bg-gray-100 hover:text-gray-500 hover:border-gray-400 hover:shadow-md focus:outline-none "
            hidden={!showAddButton}
          >
            <FontAwesomeIcon icon={faUserPlus} className="text-green-500" />
          </button>
        }
      </div>
      <div className="absolute bg-white"> 
        {show &&
        <SearchResults
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
          stepNumber={stepNumber}
          updateData={updateData}
          section={section}
          api={api}
          displayColumns={displayColumns}
          arrowCounter={arrowCounter}
          additionalParameters={additionalParameters}
        />}
      </div>
    </div>
  )
}

export default SearchBox