import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const JsonViewer = ({ displayTxt, isFomratted, closeDialog }) => {
  return (
    <div className="fixed z-10 inset-0  overflow-y-auto top-10 h-full" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
      <div className="flex items-end justify-center min-h-full pt-1 px-4 pb-10 text-center sm:block sm:p-0 top-10 h-auto">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity top-0" aria-hidden="false"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-full h-full top-0" aria-hidden="false">&#8203;</span>
        <div className="font-sans inline-block align-bottom bg-white rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:max-h-6xl w-full h-full ">
          <div className="justify-center px-1 pt-1 pb-1 h-full">
            <div className="bg-gray-50 px-2 py-1 flex justify-end">
              <button
                onClick={() => closeDialog()}
                type="button"
                className="inline-flex justify-center items-center rounded-md border border-transparent shadow-sm w-10 px-2 py-2 bg-blue-500 text-white text-xs font-semibold hover:bg-blue-600 hover:shadow-md focus:outline-none uppercase "
              >
                X
              </button>
            </div>
            <div className="w-full flex h-96 gap-2 mt-1 mb-2 flex-wrap">
              <textarea className="h-full text-sm w-full border rounded outline-none mx-2 px-2 mt-1 mb-1 font-semibold text-gray-500" type="text" name="title" value={displayTxt} />
            </div>
          </div>
        </div>
      </div>
      </div>
  )
}

export default JsonViewer