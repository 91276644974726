import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Loader = ({ className }) => {
  return (
    <div className={"flex w-screen-sm justify-center items-center " + className}>
      <FontAwesomeIcon icon={faSpinner} className="spinner" />
    </div>
  )
}

export default Loader