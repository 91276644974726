import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react'
import { agent_api_get_agents } from "../../api/agent_api"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import AgentDlg from "./AgentDlg"
import history from "../../utils/history"
import axios from 'axios';
import DeletePopup from '../DeletePopup'
import ErrorMessage from '../ErrorMessage';

const AgentOverview = () => {
  const [searchText, setSearchText] = useState("")
  const [transHtml, setTransHtml] = useState()
  const [fetchingInProgress, setFetchingInProgress] = useState(false)
  const [apiResponse, setApiResponse] = useState({ status: "", type: "", data: "", code: "", message: "" })
  const [agentDlgProps, setAgentDlgProps] = useState({ mode: "", show: "false" })
  const [dataToBeEdited, setDataToBeEdited] = useState()
  const [isClicked, setIsClicked] = useState(false)
  const [selected, setSelected] = useState(4);
  const [showPopup, setShowPopup] = useState(false);
  const [currentId, setCurrentId] = useState()
  const [errorPopup, setErrorPopup] = useState(false);



  const emptyAgentData = {
    agent: {
      id: "",
      code: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      gender: "",
      dob: "",
      mobile_no: "",
      home_no: "",
      email: "",
      street_address: "",
      city: "",
      country_code: "IL",
      postal_code: "",
      id_type: "",
      id_number: "",
      id_expiry: "",
      submit_limit: "",
      status: "",
      commission_percentage: 0,
      documents: [],
      user_id: "",
      created_at: "",
      updated_at: ""
    }
  };

  const [data, setData] = useState({ ...emptyAgentData })

  const fetchData = async () => {
    setFetchingInProgress(true)
    setApiResponse(await agent_api_get_agents(1))
    setFetchingInProgress(false)
  }

  const updateDataDlg = () => {
    fetchData()
  }

  useEffect(() => {
    fetchData()
    


  }, [])

  useEffect(() => {
    if (apiResponse.status === "OK") {
      setData({ ...data, "agent": apiResponse.data })
      // console.log(JSON.stringify(apiResponse.data, null, 4))
    }
    // console.log(apiResponse)
  }, [apiResponse])

  const handleSearch = (e) => {
    setSearchText(e.target.value)
  }

  const showDlgNewMode = (val) => {
    if (val) {
      setAgentDlgProps({ mode: "add", show: "true" })
    } else {
      setAgentDlgProps({ mode: "", show: "false" })
    }
  }

  const showDlgEditMode = (val) => {
    if (val) {
      setAgentDlgProps({ mode: "edit", show: "true" })
    } else {
      setAgentDlgProps({ mode: "", show: "false" })
    }
  }

  const editAgent = (e, index) => {
    setDataToBeEdited({ agent: data.agent[index] })
    showDlgEditMode(true)
  }
  const openStatement = (agent_id) => {
    history.push(`/agents/${agent_id}/payments`)
  }

  // task001 start
  const deleteAgent = async (id) => {
    setCurrentId(id)
    setShowPopup(true)

  }

  const popupClose = () => {
    setShowPopup(false)
  }

  const handlePopupDelete = async () => {
    try {

      const res = await axios.get(`/api/deleteAgent/${currentId}`)
      console.log(res.data)
      fetchData()

    } catch (error) {
      console.log(error)
      setErrorPopup(true)
      setTimeout(()=>setErrorPopup(false), 3000); 
    }

    setShowPopup(false)
  }
  // end

  useEffect(() => {
    if ((typeof data.agent !== 'undefined') && (Array.isArray(data.agent)) && (data.agent.length > 0)) {
      setTransHtml(
        data.agent
          .map((agent, index) => {
            return (
              <tr key={index} className={"text-gray-700 font-inter border-l-2 border-r-2 hover:bg-gray-50 hover:text-blue-800 hover:shadow hover:border-blue-400"} >
                <td className="px-1 border">{agent.first_name + " " + agent.last_name}</td>
                <td className="px-1 border text-center">{agent.gender}</td>
                <td className="px-1 border text-center">{agent.dob}</td>
                <td className="px-1 border text-center">{agent.mobile_no}</td>
                <td className="px-1 border text-left"><span className="w-full">{agent.email}</span></td>
                <td className="px-1 border text-center"> <span className={agent.status == 'Inactive' ? 'text-red-600' : 'text-gray-600'}>{agent.status == 'Inactive' ? 'BLOCKED' : ''}</span></td>
                <td className="border py-1">
                  <div className="flex justify-center">
                    <button
                      className="bg-gray-100 text-gray-600 border1 border-gray-300 hover:border-blue-700 hover:bg-blue-500 hover:text-white rounded px-2 py-1 text-xs focus:outline-none"
                      onClick={(e) => editAgent(e, index)}
                    >
                      Edit
                    </button>
                  </div>
                </td>
                <td className="border py-1">
                  <div className="flex justify-center">
                    <td className="px-1 border text-center">
                      <button className="bg-gray-100 text-gray-600 border1 border-gray-300 hover:border-blue-700 hover:bg-blue-500 hover:text-white rounded px-2 py-1 text-xs focus:outline-none"
                        onClick={() => openStatement(agent.id)}
                      >
                        Statement
                      </button>
                    </td>
                  </div>
                </td>
                {  agent.status!="Deleted" &&             
                 <td className="border py-1">
                    <div className="flex justify-center">
                      <button
                        className="bg-gray-100 text-gray-600 border1 border-gray-300 hover:border-blue-700 hover:bg-blue-500 hover:text-white rounded px-2 py-1 text-xs focus:outline-none"
                        onClick={() => deleteAgent(agent.id)}
                      >
                        Delete
                      </button>

                    </div>
                  </td>
           }
              </tr>
            )
          })
      )
    }

  }, [data])


  // task001
  const handleClick = async (id) => {

    setSelected(id);

    if (id === 1) {
      try {
        const res = await axios.get('/api/activeAgents');
        setData({ agent: res.data.data });
        console.log(res.data)
      } catch (err) {
        console.log(err);
      }
    } else if (id === 2) {
      try {
        const res = await axios.get('/api/inactiveAgents');
        setData({ agent: res.data.data });
        console.log(res.data)
      } catch (err) {
        console.log(err);
      }
    } else if (id === 3) {
      try {
        const res = await axios.get('/api/deletedAgents');
        setData({ agent: res.data.data });
        console.log(res.data)
      } catch (err) {
        console.log(err);
      }

    } else if (id === 4) {
      setData({ agent: apiResponse.data })
    }
  };
  // end

  return (
    <>
      <Helmet>
        <title>Agents</title>
      </Helmet>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-3">
        <div className="flex items-center justify-betweenz">
          <h1 className="text-gray-600 text-lg font-semibold mr-4 font-publicSans">Agents</h1>
          <button
            className="flex items-center px-3 py-2 border rounded bg-gray-100 text-gray-600 hover:shadow hover:bg-blue-500 hover:text-white hover:border-transparent mr-4"
            onClick={() => showDlgNewMode(true)}
          >
            <FontAwesomeIcon icon={faUserPlus} className={"text-base"} />
            {/* <span className="font-publicSans ml-3 text-sm text-gray-600 font-semibold">Create New</span> */}
          </button>
          <button
            className={`px-4 py-2 border-b-2 border-transparent  ${selected === 4 ? 'border-red-500' : ''}`}
            onClick={() => handleClick(4)}
          >
            All
          </button>
          <button
            className={`px-4 py-2 border-b-2 border-transparent  ${selected === 1 ? 'border-red-500' : ''}`}
            onClick={() => handleClick(1)}
          >
            Active
          </button>
          <button
            className={`px-4 py-2 border-b-2 border-transparent  ${selected === 2 ? 'border-red-500' : ''}`}
            onClick={() => handleClick(2)}
          >
            Inactive
          </button>
          <button
            className={`px-4 py-2 border-b-2 border-transparent  ${selected === 3 ? 'border-red-500' : ''}`}
            onClick={() => handleClick(3)}
          >
            Deleted
          </button>


        </div>
        <hr className="my-3" />
        <div className="mt-4 rounded border-yellow-5001 border-t-4 border-blue-400 w-full overflow-x-auto">
          {/* style={{ borderColor: "rgb(255,100,0)" }} */}
          <table className="table-fixed text-sm w-full font-publicSans" style={{ width: "100%" }}>
            <thead>
              <tr className="border shadow bg-gray-100 border-l-2 border-r-2">
                <th style={{ width: "250px" }} className=" border bg-gray-1001 text-gray-600">Name</th>
                <th style={{ width: "80px" }} className=" border bg-gray-1001 text-gray-600">Gender</th>
                <th style={{ width: "100px" }} className=" border bg-gray-1001 text-gray-600">DOB</th>
                <th style={{ width: "150px" }} className=" border bg-gray-1001 text-gray-600">Mobile No.</th>
                <th style={{ width: "250px" }} className=" border bg-gray-1001 text-gray-600">Email</th>
                <th style={{ width: "150px" }} className=" border bg-gray-1001 text-grey-500">Status</th>
                <th style={{ width: "50px" }} className=" border bg-gray-1001 text-gray-600"></th>
                <th style={{ width: "100px" }} className=" border bg-gray-1001 text-gray-600"></th>
                {data.agent.status =="Deleted" && <th style={{ width: "75px" }} className=" border bg-gray-1001 text-gray-600"></th>}
              </tr>
            </thead>
            {
              fetchingInProgress &&
              <tbody>
                <tr><td colSpan="8" className="text-center">Please wait...</td></tr>
              </tbody>
            }
            {
              !fetchingInProgress &&
              <tbody>
                {transHtml}
              </tbody>
            }
          </table>
        </div>

      </div>
      {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
      {
        (agentDlgProps.show === "true") &&
        <AgentDlg emptyDataObject={emptyAgentData} showDlg={showDlgNewMode} agentDlgProps={agentDlgProps} data={dataToBeEdited} updateData={updateDataDlg} fetchData={fetchData} />
      }

      {/* task001 */}
      {
        showPopup && (
          <div>
            <DeletePopup popupClose={popupClose} handlePopupDelete={handlePopupDelete} />
          </div>
        )
      }
      {
        errorPopup && (
          <div>
            <ErrorMessage />
          </div>
        )
      }
      {/* end */}
    </>
  )
}

export default AgentOverview