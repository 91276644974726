
// import bank_branches from "../utils/bank_branches"

import { useContext } from "react"
import GlobalVarContext from "../utils/GlobalVarContext"

export const BankBranchList = ({ bankCode }) => {
  let banks = useContext(GlobalVarContext).banks

  const sortFunc = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  if (bankCode === "") {
    return (<></>)
  }
  let branches = banks.filter(item => item.code === bankCode)[0].bank_branches.sort(sortFunc).map(item => {
    return <option key={item.code} value={item.code} className="">{item.name}</option>
  })

  branches.unshift(<option key="" value="" className=""></option>)

  return branches
}

export const getBranchName = (bank_code, branch_code) => {
  let banks = JSON.parse(sessionStorage.getItem("banks"))
  var branch_name = ""
  if (!((bank_code === "") || (branch_code === "")))
    banks.filter(item => item.code === bank_code)[0]?.bank_branches.forEach(element => {
      if (branch_code === element.code) {
        branch_name = element.name
      }
    })
  return branch_name
}