import React, { useContext, useState, useEffect, Suspense } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import CheckingAuthMessage from "./CheckingAuthMessage";
// import CheckingAuthMessage from "../pages/CheckingAuthMessage";
import { AuthContext } from "../utils/AuthContext";
import GlobalVarContext from "../utils/GlobalVarContext";
// import history from "../utils/history"
// import { useAuthState } from '../../Context'


const AppRoute = ({ component: Component, componentName, path, isPrivate, isAuthed, ...rest }) => {
  const auth = useContext(AuthContext)
  const nav = useContext(GlobalVarContext).navigator
  // const userDetails = useAuthState()
  const location = useLocation()

  return (
    <Route
      path={path}
      render={props => {
        if (isPrivate) {
          if ((typeof auth.isAuthed === 'undefined') || (auth.isAuthed && !Array.isArray(nav))) {
            return <CheckingAuthMessage />
          } else {
            if (auth.isAuthed) {
              if (componentName === "Login") {
                return <Redirect to="/" />
              }
              return <Component {...props} />
            } else {
              return <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location }
                }}
              />
            }
          }
        } else {
          return <Component {...props} />
        }
      }
      }
      {...rest}
    />
  )
}

export default AppRoute