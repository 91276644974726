import { currency_rates_api_fetch } from "../api/currency_api"

const fetchExchangeRates = async () => {
  var ret = await currency_rates_api_fetch()
  if (ret.status === "OK") {
    return ret.data
  }
  return []
}

export { fetchExchangeRates }