import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { currency_api_update, currency_api_getRatesForEditing, currency_api_saveRates } from "../api/currency_api"

const CurrencyMarkup = () => {
  const [data, setData] = useState([])
  const [apiResponse, setApiResponse] = useState({ status: "", type: "", data: "", code: "", message: "" })
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  const roundToFour = (num) => {
    var m = Number((Math.abs(num) * 10000).toPrecision(15));
    return Math.round(m) / 10000 * Math.sign(num);
}

  const handleFixedRate = (e, index) => {
    let obj = [...data]
    let rate = 0
    rate = e.target.value
    obj[index].rate = rate
    setData(obj)
    resetApiResponse()
  }

  const calcMarkupRate = (data)=> {
    let obj = [...data]
      data.map((row, row_index) =>{
      obj[row_index].calcrate = roundToFour(parseFloat(row.transfer_rate) + parseFloat(row.markup))
    })
    setData(obj)
  }

  const handleInputChange = (e, index) => {
    let obj = [...data]
    let calcrate = 0
    let rate = obj[index].rate
    let markup = 0
    // if (e.target.name === "rate") {
    //   rate = e.target.value
    //   //markup = Math.round(10000 * (parseFloat(e.target.value) - parseFloat(obj[index].transfer_rate))) / 10000
    // } else 
    if (e.target.name === "markup") {
      calcrate = parseFloat(obj[index].transfer_rate) + parseFloat(e.target.value)
      markup = e.target.value
    }
    obj[index] = { ...obj[index], rate: rate, markup: markup, calcrate:calcrate }
    setData(obj)
    resetApiResponse()
  }

  const resetApiResponse = () => {
    setApiResponse({ status: "", type: "", data: "", code: "", message: "" })
  }

  const save = async () => {
    setIsSaveDisabled(true)
    // let xx = await currency_api_saveRates(data)
    setApiResponse(await currency_api_saveRates(data))
    setIsSaveDisabled(false)
  }

  useEffect(() => {
    currency_api_getRatesForEditing()
      .then(res => {
        if (res.status === "OK") {
          calcMarkupRate(res.data)
          //setData(res.data)
          //console.log(res.data)
          setIsSaveDisabled(false)
        }
      })
  }, [])

  return (
    <>
      <Helmet>
        <title>Currency Markup</title>
      </Helmet>
      <div className={"max-w-7xl mx-auto px-4 mt-6"}>
        <div className="flex justify-between items-center bg-gray-100 py-1 rounded-md px-2">
          <div className="font-publicSans text-lg font-semibold text-gray-700">Currency Markup</div>
          <div className={"font-inter px-2 rounded " + (["OK", "ERR"].includes(apiResponse.status) ? "" : "hidden") + (apiResponse.status === "OK" ? "  text-green-600" : " text-red-600")}>
            {
              (apiResponse.status === "OK") && apiResponse.message
            }
            <ul>
              {
                (apiResponse.status === "ERR") && Object.values(JSON.parse(apiResponse.message)).map(item => <li>{item}</li>)
              }
            </ul>
          </div>
        </div>

        <div className="rounded mt-6 grid grid-cols-2 gap-4 ">
          <div>
            <table className="border-t-2  border-blue-400">
            <thead>
              <tr className="font-publicSans font-semibold text-sm bg-gray-50 text-gray-600 h-8 border-b shadow">
                <th className="border-b w-20 text-left pl-2">Currency</th>
                <th className="border-b w-32 text-right">Online Rate</th>
                <th className="border-b w-32 text-right">Markup</th>
                <th className="border-b w-32 text-right">Real-Time Rate</th>
                <th className="border-b w-32 text-right pr-2">Fixed Rate</th>
              </tr>
            </thead>
            <tbody>
              {
                (data.length === 0) && <div>Loading...</div>
              }
              {
                (data.length !== 0) &&
                data.map((row, row_index) => {
                   return (row.code !== "USD") && (
                    <tr key={row_index} className={"text-gray-700 font-inter border-l-2 border-r-2 hover:bg-gray-50 hover:text-blue-800 hover:shadow hover:border-blue-400"} >
                      <td className={"h-8 px-1 border-b font-inter text-sm text-left text-gray-400"} >
                        <span className="pl-2">{row.code}</span>
                      </td>
                      <td className={"h-8 px-1 border-b font-inter text-sm text-right text-gray-400"}>
                        {row.transfer_rate}
                      </td>
                      <td className={"h-8 border-b font-inter text-sm text-right"} >
                        {/* {
                          (row.code !== "ILS") &&
                          <span className={(row.code !== "ILS" ? "text-gray-400" : "text-gray-800")}>{row.markup}</span>
                        } */}
                        {
                          //(row.code === "ILS") &&
                          <input
                            value={row.markup}
                            name="markup"
                            onChange={(e) => handleInputChange(e, row_index)}
                            //readOnly={row.code !== "ILS"}
                            type="text"
                            // className={"h-8 border-lz border-rz rounded1 w-full text-right focus:outline-none " + (row.code !== "ILS" ? "text-gray-400" : "text-gray-800")}
                            className={"h-8 border-lz border-rz rounded1 w-full text-right focus:outline-none text-gray-800"}
                          />
                        }
                      </td>
                      <td className={"h-8 border-b font-inter text-sm text-right"} >{
                          //(row.code === "LKR") &&
                          <input
                            value={row.calcrate}
                            name="calcrate"
                            //onChange={(e) => handleInputChange(e, row_index)}
                             readOnly="true"
                            type="text"
                            className={"h-8 border-lz border-rz rounded1 w-full text-right text-gray-400 focus:outline-none pr-2 "}
                          />
                          }
                      </td>
                      <td className={"h-8 border-b font-inter text-sm text-right"}>
                        {/* {
                          (row.code !== "LKR") &&
                          <span className={" pr-2 " + (row.code !== "LKR" ? "text-gray-400" : "text-gray-800")}>{row.rate}</span>
                        } */}
                        {
                           //(row.code === "LKR") &&
                          <input
                            value={row.rate}
                            name="rate"
                            onChange={(e) => handleFixedRate(e, row_index)}
                            // readOnly={row.code !== "LKR"}
                            type="text"
                            className={"h-8 border-lz border-rz rounded1 w-full text-right focus:outline-none pr-2 "}
                          />
                        }
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          </div>
          <div>
          <span  className={" pr-2 text-blue-600"}> NOTE - To use Real Time Rates, set the Fixed Rates to 0</span>
          </div>
        </div>
        <button
          onClick={() => save()}
          className={"focus:outline-none mt-3 text-sm font-semibold font-publicSans rounded shadow px-3 py-2 " + (isSaveDisabled ? " text-gray-400 bg-gray-100" : " text-white bg-blue-500 hover:bg-blue-600")}
          disabled={isSaveDisabled}
        >
          <FontAwesomeIcon icon={faSpinner} className={"spinner " + (isSaveDisabled ? "mr-2" : "hidden")} /><span>Save</span>
        </button>
      </div>
    </>
  )
}

export default CurrencyMarkup