import api from "../utils/api"

export const transaction_api_submit = async (data, exchangeRates, transactionOwner) => {
  var ret = {}
  var suffix = "Bank"

  if (transactionOwner.description === "CASH") {
    suffix = "Cash"
  }
  await api().post("/api/transactions/submitTransaction" + suffix, { ...data, rates: exchangeRates })
    .then((response) => {
      switch (response.data.status) {
        case "success":
          ret = { status: "OK", data: response.data.data, code: "", message: "Successfully submitted." }
          break;
        case "error":
          ret = { status: "ERR", data: "", code: "", message: response.data.message }
          break;

        default:
          break;
      }
    })
    .catch(err => {
      if (err.response) {
        ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
      } else if (err.request) {
        ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
      } else {
        ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
      }
    });
  return ret
}

export const transaction_api_attach_document = async (tx_id, doc) => {
  var ret = {}

  await api().post("/api/transactions/attachDocuments", {tx_id:tx_id, doc: doc })
    .then((response) => {
      switch (response.data.status) {
        case "success":
          ret = { status: "OK", data: response.data.data, code: "", message: "Successfully Saved." }
          break;
        case "error":
          ret = { status: "ERR", data: "", code: "", message: response.data.message }
          break;

        default:
          break;
      }
    })
    .catch(err => {
      if (err.response) {
        ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
      } else if (err.request) {
        ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
      } else {
        ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
      }
    });
  return ret
}

export const transaction_api_get_pending_transactions = async () => {
  var ret = {}

  await api().get("/api/transactions/getSubmittedTransactions")
    .then((response) => {
      switch (response.data.status) {
        case "success":
          ret = { status: "OK", data: response.data.data, code: "", message: "Successfully submitted." }
          break;
        case "error":
          ret = { status: "ERR", data: "", code: "", message: response.data.message }
          break;

        default:
          break;
      }
    })
    .catch(err => {
      if (err.response) {
        ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
      } else if (err.request) {
        ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
      } else {
        ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
      }
    });
  return ret
}

export const transaction_api_get_transactions = async (page_no) => {
  var ret = {}

  await api().get("/api/transactions/getTransactions", { params: { page_no: page_no } })
    .then((response) => {
      switch (response.data.status) {
        case "success":
          ret = { status: "OK", data: response.data.data, code: "", message: "Successfully submitted." }
          break;
        case "error":
          ret = { status: "ERR", data: "", code: "", message: response.data.message }
          break;

        default:
          break;
      }
    })
    .catch(err => {
      if (err.response) {
        ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
      } else if (err.request) {
        ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
      } else {
        ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
      }
    });
  return ret
}



export const transaction_api_get_transaction = async (transaction_id) => {
  var ret = {}

  await api().get("/api/transactions/getTransaction", { params: { tx_id: transaction_id } })
    .then((response) => {
      switch (response.data.status) {
        case "success":
          ret = { status: "OK", data: response.data.data, code: "", message: "" }
          break;
        case "error":
          ret = { status: "ERR", data: "", code: "", message: response.data.message }
          break;

        default:
          break;
      }
    })
    .catch(err => {
      if (err.response) {
        ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
      } else if (err.request) {
        ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
      } else {
        ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
      }
    });
  return ret
}

export const transaction_api_cancel_transaction = async (transaction_id, reason) => {
  var ret = {}
  console.log('reason---->', reason)
  await api().post("/api/transactions/cancelTransaction" , {transaction_id: transaction_id, reason: reason})
    .then((response) => {
      switch (response.data.status) {
        case "success":
          ret = { status: "OK", data: response.data.data, code: "", message: "Tranaction Cancelled." }
          break;
        case "error":
          ret = { status: "ERR", data: "", code: "", message: response.data.message }
          break;

        default:
          break;
      }
    })
    .catch(err => {
      if (err.response) {
        ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
      } else if (err.request) {
        ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
      } else {
        ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
      }
    });
  return ret
}