import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import QuickReport from "../components/QuickReport"
import JsonViewer from "../components/JsonViewer"
import Loader from "../components/Loader"
import { event_log_api_get_event_log } from "../api/event_log_api"
import history from "../utils/history"

const EventViewer = () => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [pageNo, setPageNo] = useState(1)
  const [showJosnViewer, setShowJsonViewer] = useState(false)
  const [displayText, setDisplayText] = useState("")
  

  useEffect(() => {
    refreshData(pageNo)
  }, [])

  const fetchNextPage = () => {
    let _pageNo = pageNo
    _pageNo++
    refreshData(_pageNo)
    setPageNo(_pageNo)
  }
  let displayTxt = ""
  const fetchPreviousPage = () => {
    let _pageNo = pageNo
    if (_pageNo !== 1) {
      _pageNo--
      refreshData(_pageNo)
      setPageNo(_pageNo)
    }
  }

  const refreshData = async (page) => {
    setIsLoading(true)
    let _res = await event_log_api_get_event_log(page)
    setData(_res.data.data)
    setIsLoading(false)
  }

  const search = async (searchText) => {
    setIsLoading(true)
    let _res = await event_log_api_get_event_log(1, searchText)
    setData(_res.data.data)
    setIsLoading(false)
  }

/*   const setJsonViewerResult = (result) => {
    if (result === AlertBoxResult.MB_RESULT_YES) {
      setshowCancelTransaction(false)
      history.go(0)
    } else if (result === AlertBoxResult.MB_RESULT_NO) {
      setshowCancelTransaction(false)
    }
  } */

  const handleRowAction = (index, action) => {
    let _data = data.data.detail.filter((item, i) => index === i)
    
    setDisplayText(_data[0]._message)
    setShowJsonViewer(true);
  }

  return (
    <>
      <Helmet>
        <title>{isLoading ? "Querying..." : data && data.config.title}</title>
      </Helmet>
      
      <QuickReport
        data={data}
        refreshData={refreshData}
        rowAction={handleRowAction}
        search={search}
        fetchPreviousPage={fetchPreviousPage}
        fetchNextPage={fetchNextPage}
        currentPageNo={pageNo}
        isLoading={isLoading}
        searchTitle="Search By Key"
        //source="PostedTransactionReport"
      />
      {
        showJosnViewer &&
        <JsonViewer displayTxt={displayText} isFormatted={false} closeDialog={() => setShowJsonViewer(false)} />
      }
    </>
  )
}

export default EventViewer