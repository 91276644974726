import { config } from "@fortawesome/fontawesome-svg-core"
import React, { useState } from "react"
import { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faSearch, faSync, faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons';
import history from "../utils/history";
import Loader from "./Loader";
import { DatePicker, Space } from 'antd';
// import axios from "axios";

const { RangePicker } = DatePicker;

const QuickReport = ({ data, refreshData, rowAction, search, fetchPreviousPage, fetchNextPage, currentPageNo, isLoading, source, searchTitle, setDate, handleDateSubmit }) => {
  const [tableWidth, setTableWidth] = useState()
  const [searchText, setSearchText] = useState("")


  useEffect(() => {

    if (data) {
      setTableWidth(data.config.columns.detail.reduce((acc, curr) => {
        return acc += curr.colSpan
      }, 0) + "px")
    }
  }, [data])
  useEffect(() => {
    // console.log(document.getElementById("table_container").offsetWidth)
  }, [])

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      search(searchText)
    }
  }



  const refresh = () => {
    setSearchText("")
    refreshData()
  }
  if (!data) {
    return <></>
  } else {

    return (
      <div className={"max-w-" + data.config.pageSpan + " mx-auto px-5 mt-3"}>
        {(!["AgentStatement"].includes(source)) && <div className="flex justify-between items-center font-sora mb-2 text-md font-semibold text-gray-500  py-1 rounded-md  shadow1 px-2 ">
          <span>{data.config.title}</span>

        </div>}
        <div className=" w-full flex justify-between gap-4  ">
          {
            <div className="flex items-center  gap-2">
              <label className="max-w-md block text-xs text-gray-500 font-noto font-semibold pl-2 mr-2">{searchTitle ? searchTitle : "Search"}</label>
              <input
                type="text"
                className="h-7 rounded border focus:outline-none px-1 max-w-md "
                value={searchText}
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div className="ml-2 ">
                <button
                  className="rounded-full h-8 w-8 text-white bg-gradient-to-b from-steel-400 to-steel-500 hover:from-steel-500 hover:to-steel-600 focus:outline-none"
                  onClick={() => search(searchText)}
                >
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                </button>
              </div>
              <button onClick={() => refresh()} className="rounded-full h-8 w-8 text-white bg-gradient-to-b from-steel-400 to-steel-500 hover:from-steel-500 hover:to-steel-600 focus:outline-none">
                <FontAwesomeIcon icon={faSync} className="" />
              </button>

              {/* task003 start */}
              <Space direction="vertical" size={12}>
                <RangePicker
                  onChange={(values) => {
                    setDate(values)
                  }}
                />
              </Space>
              <button className="rounded-md  text-white bg-gradient-to-b from-steel-400 to-steel-500 hover:from-steel-500 hover:to-steel-600 focus:outline-none p-1" onClick={handleDateSubmit}>Filter</button>

              {/* end */}

            </div>
          }
  
          <div className=" flex items-center text-xs text-gray-500">
            <div className="font-noto font-semibold px-3">Total count :
             {/* {data.count? data.count>=15?data.count+1:data.count : data.config.count>=15?data.config.count+1:data.config.count} */}
             {data.count?data.count:data.config.count}
             </div>
            <div className="font-noto font-semibold px-3"> page no : {currentPageNo}  </div>
            <button
              className={"focus:outline-none uppercase font-noto text-center text-steel-500 border rounded-l-full px-1 py-1   w-8 bg-gradient-to-b  hover:from-steel-400 hover:to-steel-500 hover:text-white hover:border-transparent " + (currentPageNo === 1 ? "cursor-not-allowed" : "")}
              disabled={currentPageNo === 1}
              onClick={() => fetchPreviousPage()}
            >
              <FontAwesomeIcon icon={faLessThan} fixedWidth />
              {/* <span className="ml-2">..</span> */}
            </button>

            <button
              className={"ml-2 focus:outline-none uppercase font-noto text-center text-steel-500  border rounded-r-full px-1 py-1 w-8   bg-gradient-to-b hover:from-steel-400 hover:to-steel-500 hover:text-white hover:border-transparent " + (data && data.data.detail.length === 0 ? " cursor-not-allowed" : "")}
              disabled={data && data.data.detail.length === 0}
              onClick={() => fetchNextPage()}
            >
              {/* <span className="mr-2 font-nova font-semibold">></span> */}
              <FontAwesomeIcon icon={faGreaterThan} fixedWidth />
            </button>
          </div>
        </div>
        <div className="borrder rrounded mt-3 w-full overflow-x-auto pb-2" id="table_container">
          <table className="borrder rounded borrder-grey-400 w-full" style={{ width: tableWidth + 60 }}>
            <thead className="mb-5">
              <tr className="font-noto font-semibold rounded text-xs bg-trueGray-100 text-gray-500 h-10 borderrr-b sshadow mb-5">
                {
                  data.config.columns.detail.filter(item => {
                    if ((typeof item.visible !== 'undefined') && (item.visible !== true)) {
                      return false
                    }
                    return true
                  }).map((item, index) => {
                    return (
                      <th
                        key={index}
                        className={((data.config.columns.detail.length === (index + 1)) ? "borrder-r" : "borderr-r") + " text-" + item.colTextAlign + " text-xss"}
                        style={{ width: item.colSpan }}
                      >
                        {item.colCaption}
                      </th>
                    )
                  })
                }
                {
                  (!["AgentStatement"].includes(source)) &&
                  <th style={{ width: "60px" }}></th>
                }
              </tr>
            </thead>
            <tbody>
              {
                isLoading && data.config.columns.detail &&
                <tr>
                  <td colSpan={data.config.columns.detail.length}>
                    <Loader className="my-1" />
                  </td>
                </tr>
              }
              {
                !isLoading && data.data.detail.length > 0 && data.data.detail.map((row, row_index) => {
                  return (
                    <tr key={row_index} className={"text-gray-700 font-noto h-7 " + (row_index % 2 ? "bg-mygrey-50" : "bg-gray-0") + " hover:bg-blue-100 hover:text-blue-800 hover:shadow hover:border-blue-400"} >
                      {
                        data.config.columns.detail.filter(item => {
                          if ((typeof item.visible !== 'undefined') && (item.visible !== true)) {
                            return false
                          }
                          return true
                        }).map((item, index) => {
                          return (
                            <td
                              key={index}
                              className={"h-8 px-1 font-noto  font-semibold text-xs " + ((data.config.columns.detail.length === (index + 1)) ? "borrder-r" : "borrder-r") + " text-" + item.colTextAlign + " bg" + item.colBackColor}
                            >
                              <div dangerouslySetInnerHTML={{ __html: row[item.colName] }}></div>
                            </td>
                          )
                        })
                      }
                      {
                        (!["AgentStatement"].includes(source)) && row_index!=data.data.detail.length-1 &&
                        <td className="text-center px-3 rounded"><button onClick={() => rowAction(row_index, "zoom")} className="text-xs font-noto font-semibold text-trueGray-500 rounded text-truGray-600 px-3  border-2 border-trueGray-300 ">View</button></td>
                      }
                     
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        {/* <div className="grid grid-cols-2">
                  <pre>{JSON.stringify(data, null, 4)}</pre>
                  </div> */}
      </div>

    )
  }
}

export default QuickReport