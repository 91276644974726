import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faFileUpload,faMinusCircle, faTimes, faInfo, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { file_uploader_api_upload } from "../api/file_uploader_api"
import { AlertBoxType, AlertBoxResult } from "../utils/alert_box"
import { transaction_api_cancel_transaction } from "../api/transaction_api"


const TransactionCancelDialog = ({ type, title, reasonLabel, txId, setResult }) => {

  const [reason, setReason] = useState()
  const [isCancelling, setIsCancelling] = useState(false)

  const handleDlgResult = (ret_type) => {
    setResult(ret_type, reason);    
  }

  const handleInputChange = (e) => {
    
    if (e.target.value !== "") {
      setReason(e.target.value);
    }
    else {
     setReason();
    }

  }

  const cancelTransaction = async() => {
    try{
    setIsCancelling(true)
    let x = await transaction_api_cancel_transaction(txId, reason)
    alert(x["message"])
    handleDlgResult(AlertBoxResult.MB_RESULT_YES)
    }

    catch(e){
      alert(e);
    }
    setIsCancelling(false)
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="font-sans inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className={"mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"}>
                <FontAwesomeIcon icon={faQuestion} className={"text-red-600"} />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left font-publicSans w-full">
                <div className="flex justify-between py-2 font-semibold text-gray-700">
                  {title}
                </div>
                 <div className="mt-3">
                  <label className="text-sm ">{reasonLabel} </label>
                  <textarea className="h-7 text-sm w-full border rounded outline-none px-2 mt-1 font-semibold text-gray-500" type="text" name="title" onChange={(e) => handleInputChange(e)}/>
                </div> 
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button disabled={reason === ""} onClick={() => handleDlgResult(AlertBoxResult.MB_RESULT_NO)} type="button" className="inline-flex justify-center items-center rounded-md border border-transparent shadow-sm w-20 px-4 py-2 bg-blue-500 text-white text-xs font-semibold hover:bg-blue-600 hover:shadow-md focus:outline-none sm:ml-3 uppercase ">
              No
            </button>
            <button
              disabled={reason ? false : true}
              onClick={() => cancelTransaction()}
              className={"inline-flex justify-center items-center rounded-md border shadow-sm w-20 px-4 py-2 text-xs font-semibold focus:outline-none sm:ml-3 uppercase "
                + ((reason ? false : true) ? " text-gray-400 bg-gray-100" : "text-red-700 hover:bg-red-700 border-red-400 hover:text-white hover:shadow-md focus:outline-none")}
            >
              <FontAwesomeIcon icon={faMinusCircle} className="mr-3" />
              {
                isCancelling &&
                <FontAwesomeIcon icon={faSpinner} className="spinner mr-2" />

              }
              Yes
              </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionCancelDialog