
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faFileContract, faPlus, faPlusCircle, faSearchPlus, faTimes, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import DocumentUploader from "../DocumentUploader/DocumentUploader";
import { useEffect, useState } from "react";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { split } from "lodash";

const DocumentPreviewer = ({ colSpan, docColumns, cssClass, documents, setAttachedDocuments, readOnly, hideLabel, deleteDoc }) => {
  const [showFileSelectorDlg, setShowFileSelectorDlg] = useState(false)
  const [imageZoomerProps, setImageZoomerProps] = useState({ index: "", show: false })
  const [showDelMsg, setShowDelMsg] = useState()
  const [mesBoxResult, setMsgBoxResult] = useState()
  const [delDocIndex, setDelDocIndex] = useState()

  var docs = <></>

  const deleteDocument = (index) => {
    setMsgBoxResult()
    setDelDocIndex(index)
    setShowDelMsg(true)
  }

  useEffect(() => {
    if (mesBoxResult) {
      setShowDelMsg()
      if (mesBoxResult === "yes") {
        deleteDoc(delDocIndex)
      }
    }
  }, [mesBoxResult])

  if ((documents !== null) && (Array.isArray(documents))) {
    docs = documents.map((item, index) => {
      return (
        <DocCard
          key={index}
          index={index}
          item={item}
          docColumns={docColumns}
          readOnly={readOnly}
          setImageZoomerProps={setImageZoomerProps}
          deleteDoc={deleteDocument}
        />
      )
    })
  }

  return (
    <>
      <div className={"grid grid-cols-12 gap-2 " + " col-span-" + colSpan}>
        <div className={cssClass + " w-full border rounded border-gray-200 font-publicSans max-h-44 overflow-y-scroll " + (readOnly ? "col-span-12" : "col-span-10")}>
          <div className={"grid grid-cols-" + docColumns + " gap-4 p-2"}>
            {docs}
          </div>
          {
            showFileSelectorDlg &&
            <DocumentUploader
              setShowFileSelectorDlg={setShowFileSelectorDlg}
              setAttachedDocuments={(doc) => setAttachedDocuments(doc)}
            />
          }
        </div>
        {
          !readOnly &&
          <div className="col-span-2">


            <div className="">
              {
                (hideLabel !== "true") &&
                <div className={"text-sm"}>
                  Documents
                </div>
              }
              <button
                key="start"
                className="ml-5 mt-3 flex justify-center items-center border rounded-full text-center w-9 h-9 hover:shadow hover:border-gray-200 bg-gradient-to-b from-gray-50 to-gray-100  text-blue-400 hover:text-blue-500 focus:outline-none"
                onClick={() => setShowFileSelectorDlg(true)}
                type="button"
              >
                <div className="flex w-full justify-center my-4 ">
                  <FontAwesomeIcon icon={faPlus} className={"block text-lg text-yellow-600"} />
                </div>
              </button>
            </div>
          </div>
        }
      </div >
      {
        imageZoomerProps.show &&
        <ImageZoomerDlg imageArray={documents} imageZoomerProps={imageZoomerProps} setImageZoomerProps={setImageZoomerProps} />
      }
      {
        showDelMsg &&
        <MessageDialog prompt="Are you sure to delete the dicument?" setResult={setMsgBoxResult} />
      }
    </>
  )
}

const DocCard = ({ index, item, readOnly, setImageZoomerProps, deleteDoc }) => {
  const [fileLoaded, setFileLoaded] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const getDocumentPath = (doc_id) => {
    return process.env.REACT_APP_BACKEND_URL + "/api/documents/" + doc_id + "/thumb"
  }

  return (
    <div key={index} className={"border " + (isHover ? "shadow-lg" : "") + " rounded-md p-1 overflow-hidden"} onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} >
      <div className={"relative rounded-md overflow-hidden grid grid-cols-1 gap-2 place-content-between h-24"}>
        {
          isHover &&
          <div className="flex items-end absolute  w-full h-full">
            <div className="flex justify-around gridz grid-cols-1z gap-0z rounded-md bg-gray-100 w-full place-content-endz h-fullz p-1 text-gray-600">
              <button
                className="w-fullz bg-gradient-to-ba from-blue-400a to-blue-600a h-20 flex justify-center items-center rounded-full w-7a h-7a p-1 hover:shadowa focus:outline-none font-semibold hover:from-blue-500a hover:to-blue-700a hover:text-whitea"
                onClick={() => setImageZoomerProps({ show: true, index: index })}
              >
                <FontAwesomeIcon icon={faSearchPlus} className={"text-sm text-gray-400 hover:text-blue-500"} />
              </button>
              {
                !readOnly && !(typeof(deleteDoc) !== 'undefined') &&
                <button
                  className="w-fullz bg-gradient-to-ba from-blue-400a to-blue-600a flex justify-center items-center rounded-full w-7a h-7a p-1 hover:shadowa focus:outline-none font-semibold hover:from-blue-500a hover:to-blue-700a hover:text-whitea"
                  onClick={() => deleteDoc(index)}
                >
                  <FontAwesomeIcon icon={faTrash} className={"text-sm text-gray-400 hover:text-red-500"} />
                </button>
              }
            </div>
          </div>
        }
        <div className="flex justify-center">
          <img
            style={{
              display: "block",
              // maxWidth: "230px",
              maxHeight: "65px",
              width: "auto",
              height: "auto"
            }}
            src={getDocumentPath(item.doc_id)}
            alt=""
          />
        </div>
        <div className="bg-gray-200 text-center font-semibold text-gray-500">{item.description}</div>
      </div>
    </div >
  )
}

const ImageZoomerDlg = ({ imageArray, imageZoomerProps, setImageZoomerProps }) => {
  const [fileExtension, setFileExtension] = useState()
  const [fileLoaded, setFileLoaded] = useState(false)

  const onLoadCapture = (e) => {
    setFileLoaded(true)
  }

  useEffect(() => {
    setFileExtension(split(imageArray[imageZoomerProps.index].doc_id, ".")[1])
  }, [])

  const getDocumentPath = (doc_id) => {
    return process.env.REACT_APP_BACKEND_URL + "/api/documents/" + doc_id + "/full"
  }

  return (
    <div className="absolute z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-full h-full pt-4 px-4 pb-20a text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-full" aria-hidden="true">&#8203;</span>
        <div className="h-full font-sans inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8a sm:align-middle sm:max-w-5xl sm:w-full">
          <div className="h-full px-4 pt-5a pb-4a sm:p-6 sm:pb-4a">
            <div className="h-full pb-6 sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0  sm:text-left font-publicSans h-full w-full">
                <div className="flex justify-between py-2">
                  <div></div>
                  <button
                    onClick={() => setImageZoomerProps({ show: false, index: "" })}
                    className="flex justify-center h-7 w-7 border border-gray-400 rounded-full -mt-4 -mr-2"
                  >
                    <FontAwesomeIcon icon={faTimes} className={"text-blue-500 text-lg mt-1"} />
                  </button>
                </div>
                <div className={(fileExtension === "pdf" ? "" : "h-full") + " overflow-y-scroll"}>
                  {
                    (fileExtension !== "pdf") &&
                    <img
                      className="h-auto w-full"
                      src={getDocumentPath(imageArray[imageZoomerProps.index].doc_id)}
                      alt=""
                    />
                  }
                </div>
                {
                  (fileExtension === "pdf") &&
                  <embed
                    src={`${getDocumentPath(imageArray[imageZoomerProps.index].doc_id)}#toolbar=1&navpanes=1&scrollbar=0`}
                    // width="500"
                    className={fileLoaded ? "h-full w-full" : "invisible"}
                    onLoadCapture={(e) => onLoadCapture(e)}
                  // height="500"
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const MessageDialog = ({ prompt, setResult }) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screena" aria-hidden="true">&#8203;</span>
        <div className="font-sans inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <span className="text-sm text-red-500 font-semibold">{prompt}</span>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button onClick={() => setResult("no")} className="border rounded-md px-3 py-1 focus:outline-none">No</button>
            <button onClick={() => setResult("yes")} className="bg-red-500 text-white rounded-md shadow px-3 py-1  mr-2 focus:outline-none">Yes</button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default DocumentPreviewer