import { Helmet } from 'react-helmet-async';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { sender_api_get_senders } from "../../api/sender_api"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import SenderDlg from './SenderDlg';
import { AuthContext } from '../../utils/AuthContext';
import { grant_api_getUserGrants } from '../../api/grant_api';
import axios from 'axios';

const SenderOverview = () => {
  const [searchText, setSearchText] = useState("")
  const [transHtml, setTransHtml] = useState()
  const [fetchingInProgress, setFetchingInProgress] = useState(false)
  const [apiResponse, setApiResponse] = useState({ status: "", type: "", data: "", code: "", message: "" })
  const [senderDlgProps, setSenderDlgProps] = useState({ mode: "", show: "false" })
  const [dataToBeEdited, setDataToBeEdited] = useState()
  const [permissions, setPermissions] = useState()
  const [selected, setSelected] = useState(null);




  const emptySenderData = {
    sender: {
      id: "",
      code: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      gender: "",
      dob: "",
      mobile_no: "",
      home_no: "",
      email: "",
      street_address: "",
      city: "",
      country_code: "IL",
      postal_code: "",
      status:"",
      id_type: "",
      id_number: "",
      id_expiry: "",
      risk_score: "",
      compliance_score: "",
      documents: [],
      created_at: "",
      updated_at: "",
      bank: "",
      branch: "",
      account_no: ""
    }
  };

  const [data, setData] = useState({ ...emptySenderData })

  const updateData = (section, rec) => {
    setData({
      ...data, [section]: rec
    })
  }

  const fetchData = async () => {
    setFetchingInProgress(true)

    let _res = await grant_api_getUserGrants()
    setPermissions(_res.data.data)

    setApiResponse(await sender_api_get_senders(1))
    setFetchingInProgress(false)

  }

  useEffect(() => {
    fetchData()
    console.log(data.sender)
  }, [])

  useEffect(() => {
    //console.log(apiResponse.data)
    updateData("sender", apiResponse.data)
    // if (apiResponse.status === "OK") {
    //   updateData("sender", apiResponse.data)
    // }
  }, [apiResponse])

  const showDlgNewMode = (val) => {
    if (val) {
      setSenderDlgProps({ mode: "add", show: "true" })
    } else {
      setSenderDlgProps({ mode: "", show: "false" })
    }
  }

  const showDlgEditMode = (val, index) => {
    if (val) {
      setSenderDlgProps({ mode: "edit", show: "true", index: index })
    } else {
      setSenderDlgProps({ mode: "", show: "false" })
    }
  }

  const editSender = (e, index) => {
    setDataToBeEdited({ sender: data.sender[index] })
    showDlgEditMode(true, index)
  }
  

  // task005 start
  const activateSender = async (id) => {
    try {

      const res = await axios.get(`/api/shiftSender/activate/${id}`)
      console.log(res.data)

      fetchData()

    } catch (error) {
      console.log(error)
    }
  }
  
  const deactivateSender = async (id) => {
    try {

      const res = await axios.get(`/api/shiftSender/deactivate/${id}`)
      console.log(res.data)

      fetchData()

    } catch (error) {
      console.log(error)
    }
  }
  // end

  const search = async () => {
    setFetchingInProgress(true)
    setApiResponse(await sender_api_get_senders(1, searchText))
    setFetchingInProgress(false)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      search()
    }
  }
  const handleClick = async (id) => {

    setSelected(selected === id ? null : id);

    if (id === 1) {
      try {
        const res = await axios.get('/api/activeSenders');
        setData({ sender: res.data.data });
        console.log(res.data)
      } catch (err) {
        console.log(err);
      }
    } else if (id === 2) {
      try {
        const res = await axios.get('/api/inactiveSenders');
        setData({ sender: res.data.data });
        console.log(res.data)
      } catch (err) {
        console.log(err);
      }
    
  }else if (id === 0) {
    setData({ sender: apiResponse.data })
  }
  }

  useEffect(() => {
    if ((typeof data.sender !== 'undefined') && (Array.isArray(data.sender))) {
      setTransHtml(
        data.sender
          .map((sender, index) => {
            return (
              <tr key={index} className={"text-gray-700 font-inter border-l-2 border-r-2 hover:bg-gray-50 hover:text-blue-800 hover:shadow hover:border-blue-400"} >
                {/* <td className="px-1 border text-right">{sender.code}</td> */}
                <td className="px-1 border">{sender.first_name + " " + sender.last_name}</td>
                <td className="px-1 border text-center">{sender.gender}</td>
                <td className="px-1 border text-center">{sender.dob}</td>
                <td className="px-1 border text-center">{sender.mobile_no}</td>
                {/* <td className="px-1 border text-center">{sender.home_no}</td> */}
                <td className="px-1 border text-left">
                  <span className="w-full">{sender.email}</span>
                </td>
                <td className="border py-1">
                  <div className="flex justify-center">
                    <button
                      className="bg-gray-100 text-gray-600 border1 border-gray-300 hover:border-blue-700 hover:bg-blue-500 hover:text-white rounded px-2 py-1 text-xs focus:outline-none"
                      onClick={(e) => editSender(e, index)}
                    >
                      Edit
                    </button>
                  </div>
                </td>
                {
                  permissions && permissions.includes('sender.activate') && sender.status==0 &&
                  
                  <td className="border py-1">
                    <div className="flex justify-center">
                      {/* task005 start */}
                      <button
                        className={`bg-gray-100 text-gray-600 border1 border-gray-300 hover:border-blue-700 hover:bg-blue-500 hover:text-white rounded px-2 py-1 text-xs focus:outline-none ${sender.status==1 ? ' opacity-50' : ''}`}
                        onClick={() => activateSender(sender.id)}
                        disabled={sender.status==1}
                      >
                        Active
                      </button>
                    </div>
                  </td>
                  
                }
                { permissions && permissions.includes('sender.activate') && sender.status==1 &&
                 <td className="border py-1">
                    <div className="flex justify-center">
                      <button
                        className={`bg-gray-100 text-gray-600 border1 border-gray-300 hover:border-blue-700 hover:bg-blue-500 hover:text-white rounded px-2 py-1 text-xs focus:outline-none ${sender.status==0 ? 'opacity-50' : ''}`}
                        onClick={() => deactivateSender( sender.id)}
                        disabled={sender.status==0}
                      >
                        Inactive
                      </button>
                      {/* end */}
                    </div>
                  </td>
          }
          
              </tr>
            )
          })
      )
    }
  }, [data])

  return (
    <>
      <Helmet>
        <title>Senders</title>
      </Helmet>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-3">
        <div className="flex items-center justify-betweenz">
          <h1 className="text-gray-600 text-xl font-semibold mr-4 font-publicSans">Senders</h1>
          {
            permissions && permissions.includes('sender.create') &&
            <button
              className="flex focus:outline-none items-center px-3 py-2 border rounded bg-gray-100 text-gray-600 hover:shadow hover:bg-blue-500 hover:text-white hover:border-transparent"
              onClick={() => showDlgNewMode(true)}
            >
              <FontAwesomeIcon icon={faUserPlus} className={"text-base"} />
            </button>
          }
          <button
            className={`px-4 py-2 border-b-2 border-transparent  ${selected === 0 ? 'border-red-500' : ''}`}
            onClick={() => handleClick(0)}
          >
            All
          </button>
          <button
            className={`px-4 py-2 border-b-2 border-transparent  ${selected === 1 ? 'border-red-500' : ''}`}
            onClick={() => handleClick(1)}
          >
            Active
          </button>
          <button
            className={`px-4 py-2 border-b-2 border-transparent  ${selected === 2 ? 'border-red-500' : ''}`}
            onClick={() => handleClick(2)}
          >
            Inactive
          </button>
          
        </div>
        <div className='flex mt-2'>
          <input
            type="text"
            className="h-8 w-1/4 border rounded-full focus:outline-none px-2"
            value={searchText}
            onKeyPress={(e) => handleKeyPress(e)}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <button
            className="h-8 w-8 ml-2 flex items-center focus:outline-none  justify-center px-3 py-2 border rounded-full bg-gray-100 text-gray-600 hover:shadow hover:bg-blue-500 hover:text-white hover:border-transparent"
            onClick={() => search()}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        <hr className="my-3" />
        <div className="mt-4 rounded border-yellow-5001 border-t-4 border-blue-400 w-full overflow-x-auto">
          {/* style={{ borderColor: "rgb(255,100,0)" }} */}
          <table className="table-fixed text-sm w-ful font-publicSans" style={{ width: "100%" }}>
            <thead>
              <tr className="border shadow bg-gray-100 border-l-2 border-r-2">
                {/* <th className="w-10 border bg-gray-1001 text-gray-600 py-2">Code</th> */}
                <th style={{ width: "250px" }} className=" border bg-gray-1001 text-gray-600">Name</th>
                <th style={{ width: "80px" }} className=" border bg-gray-1001 text-gray-600">Gender</th>
                <th style={{ width: "100px" }} className=" border bg-gray-1001 text-gray-600">DOB</th>
                <th style={{ width: "150px" }} className=" border bg-gray-1001 text-gray-600">Mobile No.</th>
                {/* <th style={{ width: "150px" }} className=" border bg-gray-1001 text-gray-600">Home Tel. No.</th> */}
                <th style={{ width: "350px" }} className=" border bg-gray-1001 text-gray-600">Email</th>
                <th style={{ width: "50px" }} className=" border bg-gray-1001 text-gray-600"></th>
                {
                  permissions && permissions.includes('sender.activate') &&
                  <th style={{ width: "75px" }} className=" border bg-gray-1001 text-gray-600"></th>
                }
              </tr>
            </thead>
            {
              fetchingInProgress &&
              <tbody>
                <tr><td colSpan="8" className="text-center">Please wait...</td></tr>
              </tbody>
            }
            {
              !fetchingInProgress &&
              <tbody>
                {transHtml}
              </tbody>
            }
          </table>
        </div>
      </div>
      {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
      {
        (senderDlgProps.show === "true") &&
        <SenderDlg emptyDataObject={emptySenderData} showDlg={showDlgNewMode} senderDlgProps={senderDlgProps} data={dataToBeEdited} updateData={updateData} fetchData={fetchData} />
      }
    </>
  )
}

export default SenderOverview