
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";

const WizardControlButtons = ({ currentStep, handlePrevStep, handleNextStep, isNextButtonEnabled, isPreviousButtonEnabled }) => {

  return (
    <div className="leading-6">
      <button
        className={"mr-2 text-xs font-semibold rounded-full border border-gray-300 w-32 py-1 bg-white focus:outline-none uppercase " + (isPreviousButtonEnabled ? "bg-gradient-to-b from-gray-100 to-gray-200 hover:shadow-md hover:bg-gray-100 hover:border-gray-400 text-gray-500" : "text-gray-400 cursor-default")}
        onClick={() => handlePrevStep()}
        disabled={!isPreviousButtonEnabled}
      >
        <div className="flex mx-3 justify-between items-center">
          <FontAwesomeIcon icon={faArrowLeft} className={"ml-41 text-blue-400"} />
          <div>Previous</div>
        </div>
      </button>
      <button
        className={"mr-2 text-xs font-semibold rounded-full border border-gray-300 w-32 py-1 bg-white focus:outline-none uppercase " + (isNextButtonEnabled ? "bg-gradient-to-b from-gray-100 to-gray-200 hover:shadow-md hover:bg-gray-100 hover:border-gray-400 text-gray-500" : "text-gray-400 cursor-default")}
        onClick={() => handleNextStep()}
        disabled={!isNextButtonEnabled}
      >
        <div className="flex mx-3 justify-between items-center">
          <div>Next</div>
          <FontAwesomeIcon icon={faArrowRight} className={""} />
        </div>
      </button>
    </div>
  )
}

export default WizardControlButtons