import { Helmet } from 'react-helmet-async';
import { AuthContext } from "../utils/AuthContext"
import React, { useContext, useEffect, useState } from "react"
import { transaction_api_get_transaction } from "../api/transaction_api"
import { formatNumberToCurrency } from "../utils/currency_util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faMinusCircle, faPrint, faSpinner, faEdit, faTimes, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import SenderDlg from '../components/Sender/SenderDlg';
import ReceiverDlg from '../components/Receiver/ReceiverDlg';
import TxDetail from '../components/TxDetail/TxDetail';
import DocumentPreviewer from '../components/DocumentPreviewer/DocumentPreviewer';
import PrintPreviewer from '../components/PrintPreviewer/PrintPreviewer';
import ImageZoomerDlg from '../components/ImageZoomerDlg';
import TransactionSubmitter from '../components/TransactionSubmitter'
import { getBankName } from '../components/BankList';
import { getBranchName } from '../components/BankBranchList';
import { getCountryName } from '../components/CountryList';
import TransactionCancelDialog from '../components/TransactionCancelDialog';
import { AlertBoxResult } from '../utils/alert_box';
import { grant_api_getUserGrants } from '../api/grant_api';
import history from "../utils/history";
import { times } from 'lodash';
import { transaction_api_attach_document } from "../api/transaction_api"
import { FaAngleLeft } from "react-icons/fa";
import { Link } from 'react-router-dom'


const ViewTransaction = (props) => {
  let auth = useContext(AuthContext)

  const { id } = props.match.params;
  const [apiResponse, setApiResponse] = useState({ status: "", type: "", data: "", code: "", message: "" })
  const [senderDlgProps, setSenderDlgProps] = useState({ mode: "edit", show: "false" })
  const [receiverDlgProps, setReceiverDlgProps] = useState({ mode: "edit", show: "false" })
  const [spinnerColor, setSpinnerColor] = useState()
  const [fetchingInProgress, setFetchingInProgress] = useState()
  const [showTransactionSubmitter, setShowTransactionSubmitter] = useState(false)
  const [isShowCancelTransaction, setshowCancelTransaction] = useState(false)
  const [showPrintPreview, setShowPrintPreview] = useState(false)
  const [printParams, setPrintParams] = useState({})
  const [permissions, setPermissions] = useState()
  const [localData, setLocalData] = useState({})


  useEffect(() => {
    if ((typeof fetchingInProgress !== 'undefined') && (fetchingInProgress)) {
      setSpinnerColor("red")
    }
  }, [fetchingInProgress])

  const sender = {
    id: "",
    code: "",
    status: "", 
    first_name: "",
    middle_name: "",
    last_name: "",
    gender: "",
    dob: "",
    mobile_no: "",
    home_no: "",
    email: "",
    street_address: "",
    city: "",
    country_code: "",
    postal_code: "",
    id_type: "",
    id_number: "",
    id_expiry: "",
    kyc_file: "",
    risk_score: "",
    compliance_score: "",
    declaration_file: "",
    documents: [],
    created_at: "",
    updated_at: ""
  };

  const receiver = {
    id: "",
    sender_id: "",
    code: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    gender: "",
    dob: "",
    phone_no: "",
    street_address: "",
    city: "",
    country_code: "",
    postal_code: "",
    account_no: "",
    bank: "",
    branch: "",
    relationship_to_sender: "",
    documents: [],
    payment_method: "",
    currency_code: "",
    rate: "",
    amount: "",
    transaction_id: "",
    created_at: "",
    updated_at: ""
  }

  const transaction = {
    id: "",
    tx_date: "",
    usd_to_pay: 0,
    discount: 0,
    fees: 0,
    usd_to_send: 0,
    out_currency_code: "",
    out_amount: 0,
    tx_notes: "",
    tx_status: "",
    tx_creator_id: "",
    tx_owner_id: "",
    sender_id: "",
    receiver_id: "",
    transfer_type_id: 1,
    created_at: "",
    updated_at: ""

  }

  const tx_details = []

  var emptyDataObject = { sender: sender, receiver: receiver, transaction: transaction, tx_details: tx_details }

  const [data, setData] = useState(emptyDataObject)

  const showSenderDlg = (val) => {
    if (val) {
      setSenderDlgProps({ mode: "edit", show: "true" })
    } else {
      setSenderDlgProps({ mode: "", show: "false" })
    }
  }

  const showReceiverDlg = (val) => {
    if (val) {
      setReceiverDlgProps({ mode: "edit", show: "true" })
    } else {
      setReceiverDlgProps({ mode: "", show: "false" })
    }
  }

  useEffect(() => {
    const fetch = async () => {
      setFetchingInProgress(true)
      let x = await transaction_api_get_transaction(id)

      let _res = await grant_api_getUserGrants()
      setPermissions(_res.data.data)

      setApiResponse(x)
      setFetchingInProgress(false)
    }
    fetch()
  }, [id])

  useEffect(() => {
    if (apiResponse.status === "OK") {
      setData(apiResponse.data)
    }
  }, [apiResponse])

  const updateData = (section, rec) => {
    setData({
      ...data, [section]: rec
    })
  }

  const updateDataItem = (section, column, value) => {
    setData({
      ...data, [section]: { ...data[section], [column]: value }
    })
    
  }

  const updateTxDetails = () => { }
  const updateGridTotal = () => {
    return;
  }

  const postToExhouse = () => {
    setShowTransactionSubmitter(true)
  }


  const printTransaction = () => {
    setPrintParams({ tx_id: apiResponse.data.id })
    setShowPrintPreview(true)
  }

  const setCancelTxAlertBoxResult = (result, reason) => {
    if (result === AlertBoxResult.MB_RESULT_YES) {
      setshowCancelTransaction(false)
      history.go(0)
    } else if (result === AlertBoxResult.MB_RESULT_NO) {
      setshowCancelTransaction(false)
    }
  }

  const setAttachedDocuments = (doc) => {
    if (Object.entries(doc).length > 0) {
      setFetchingInProgress(true)
      setLocalData({ ...localData, documents: [doc] });
      (async () => {
        let docs = await transaction_api_attach_document(id, doc)
        updateDataItem('transaction', 'documents', docs.data)
      })()
      setFetchingInProgress(false)
    }     
  }

      {/*task006 start  */}
  const handleBackButton = () => {
    window.history.go(-1);
    sessionStorage.setItem('back',1)
  }
  // end

  return (
    <>
      <Helmet>
        <title>View Transaction</title>
      </Helmet>
      <div className="max-w-5xl mx-auto px-4 mt-3 font-poppins">
        <div>
          <h1 className="text-gray-600 text-lg font-semibold mr-3 ">Transaction - <span className="text-grey-600 font-semibold">{id.padStart(6, '0')}</span><span className="mx-1"></span><span className="text-gray-500 font-semibold"></span></h1>
        </div>
        <div className="h-9 flex items-end justify-between">
          <div className={(fetchingInProgress ? "hidden" : "flex items-center")}>           
            <span class="block text-xs text-center font-semibold rounded h-6 w-24 bg-opacity-50 bg-yellow-400 brightness-50 px-1 py-1 mr-2">{data ? data.transaction.payment_method : ""}</span>
            <span className={"block text-xs text-center font-semibold rounded w-28 bg-opacity-50 h-6 px-1 py-1  mr-2 " + (data.transaction.exhouse_tx_status === "POSTED" ? "bg-green-400" : "bg-red-300") }>{data.transaction.tx_status === "Cancelled" ? 'CANCELLED' : data.transaction.exhouse_tx_status==='POSTED'? 'POSTED' : 'PENDING'}</span>
          </div>
          <div className={(fetchingInProgress ? "hidden" : "flex")}>
              {               
                (data.transaction.tx_status !== "Cancelled") &&
                permissions && permissions.includes('transaction.cancel') &&
              <button
                onClick={() => setshowCancelTransaction(true)}
                className={"mr-2 text-xs font-semibold rounded border w-32 border-red-300 px-3 py-2 bg-white focus:outline-none uppercase" + " hover:shadow-md hover:bg-red-100 hover:border-red-400 text-red-500"}
              >
                <FontAwesomeIcon icon={faMinusCircle} className="mr-3" />
                  Cancel            
              </button>
              }
              {
                (data.transaction.tx_status !== "Cancelled") &&
              <button
                onClick={() => printTransaction()}
                className={"mr-2 text-xs font-semibold rounded border w-32 border-green-300 px-3 py-2 bg-white focus:outline-none uppercase" + " hover:shadow-md hover:bg-green-100 hover:border-green-400 text-green-500"}
              >
                <FontAwesomeIcon icon={faPrint} className="mr-3" />
                Print
              </button>
              }
              <button
                onClick={handleBackButton}
                className={"mr-2 text-xs font-semibold rounded border w-32 border-blue-300 px-3 py-2 bg-white focus:outline-none uppercase" + " hover:shadow-md hover:bg-blue-100 hover:border-blue-400 text-blue-500"}
              >
                <FontAwesomeIcon icon={faArrowLeft} className="mr-3" />
                Back
              </button>
              {
                (data.transaction.tx_status !== "Cancelled") &&
                (data.transaction.exhouse_tx_status !== "POSTED") &&
                <button
                  className={"mr-2 text-xs font-semibold rounded w-32 border border-steel-400 px-3 py-2 text-steel-500  focus:outline-none uppercase" + " hover:shadow-md hover:text-white hover:bg-steel-600 hover:border-steel-600"}
                  onClick={() => postToExhouse()}
                >
                  Post Ex-House
                  {/* <FontAwesomeIcon icon={faArrowRight} className="ml-3" /> */}
                </button>
              }
            </div>
        </div>
        {/* <hr className="my-3" /> */}
        <div className="grid  gap-x-12 grid-cols-12 items-baseline mt-5">
          <div className="col-span-4 div max-w-sm rounded-md bg-redgray-100 overflow-hidden shadow-lg font-poppins  text-gray-100 text-sm  h-80 border-b border-yellow-500">
            <div className="flex items-center justify-center py-2 pl-5 border-b-2 border-backblue-400  bg-gray-100 text-backblue-400">
              <div className="  text-sm te">Sender</div>
              {
                !fetchingInProgress &&
                <button className={"text-xs font-semibold rounded-md border ml-5  px-3 py-1  text-gray-800 focus:outline-none" + " hover:shadow-md1 hover:text-white hover:bg-blue-500 hover:lue-500"}
                  onClick={() => showSenderDlg(true)}
                >
                  <FontAwesomeIcon className="fa-1x" icon={faEdit} />
                </button>
              }
            </div>
            {
              fetchingInProgress &&
              <div className="w-full flex justify-center mt-6">
                <FontAwesomeIcon icon={faSpinner} className={"spinner text-" + spinnerColor + "-700 text-3xl"} />
              </div>
            }
            {
              !fetchingInProgress &&
              <div className="grid grid-cols-2 text-center  gap-1.5 mt-4 mb-6 text-sm text-redgray-500">
                <div className="mt-2 col-span-2 text-gray-800 w-full font-semibold">
                  {data.sender.first_name + " " + data.sender.middle_name + " " + data.sender.last_name}
                </div>
                <div className="col-span-2  text-gray-800 w-full ">
                  {data.sender.gender}
                </div>
                <div className=" col-span-2 text-gray-800 w-full ">
                  {data.sender.dob}
                </div>
                <div className="  text-gray-800 mt-3 text-left pl-14">
                  <span >Type :</span>                
                </div>
                <div className="  text-gray-800 mt-3 text-left ">
                  <span> {data.sender.id_type}</span>              
                </div>
                <div className="    text-gray-800 text-left pl-14">
                  <span className="w-16"> ID : </span>
                </div>
                <div className="    text-gray-800 text-left ">
                  <span>{data.sender.id_number}</span>
                </div>
                <div className="   text-gray-800 text-left pl-14">
                  <span className="w-16">Expiry : </span> 
                </div>
                <div className="  text-gray-800 text-left ">
                  <span>{data.sender.id_expiry}</span>
                </div>
                <div className="  text-gray-800 text-right pl-14">
                <span ></span>
                </div>
                <div className="  text-gray-800 text-left ">
                <span > {getCountryName(data.sender.country_code)}</span>
                </div>
              </div>
            }
          </div>
          <div className="col-span-4 text-sm div max-w-sm rounded-md bg-redgray-100 overflow-hidden shadow-lg font-poppins h-80 border-b  border-blue-500">
            <div className="flex items-center justify-center py-2  border-b-2 border-backblue-400 bg-gray-100 text-backblue-400">
              <h1 className="  text-sm">Receiver</h1>
              {
                !fetchingInProgress &&
                <button className={"text-xs font-semibold rounded-md border ml-5  px-3 py-1  text-gray-800 focus:outline-none" + " hover:shadow-md1 hover:text-white hover:bg-blue-500 hover:lue-500"}
                  onClick={() => showReceiverDlg(true)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
              }
            </div>
            {
              fetchingInProgress &&
              <div className="w-full flex justify-center mt-6">
                <FontAwesomeIcon icon={faSpinner} className={"spinner text-" + spinnerColor + "-700 text-2xl"} />
              </div>
            }
            {
              !fetchingInProgress &&
              <div className="grid grid-cols-2 text-center gap-1.5 mt-4 mb-6">
                <div className="mt-2 col-span-2 text-gray-700 w-full font-semibold ">
                 {data.receiver.first_name + " " + data.receiver.last_name}
                </div>
                <div className=" col-span-2 text-gray-600 w-full ">
                  {data.receiver.gender}
                </div>
                <div className="  text-gray-500 mt-2 text-left pl-14">
                  <span className="w-20">Type :</span>
                </div>
                <div className="  text-gray-500 mt-2 text-left ">
                  <span> DFCC</span>
                </div>
                <div className="   text-gray-500 text-left pl-14">
                  <span >Account :</span>
                </div>
                <div className="   text-gray-500 text-left">
                  <span> {data.receiver.account_no}</span>
                </div>
                <div className="   text-gray-500 text-left pl-14">
                  <span >Bank :</span>
                </div>
                <div className="   text-gray-500 text-left ">
                  <span> {getBankName(data.receiver.bank)}</span>
                </div>
                <div className="  text-gray-500 text-left pl-14">
                  <span ></span>
                </div>
                <div className="  text-gray-500 text-left">
                  <span>{getBranchName(data.receiver.bank, data.receiver.branch)}</span>
                </div>
                <div className="  text-gray-500 text-left pl-14">
                  <span ></span>
                </div>
                <div className="  text-gray-500 text-left pl-2">
                  <span>{getCountryName(data.receiver.country_code)}</span>
                </div>
              </div>
            }
          </div >
          <div className="col-span-4">
            <div className="col-span-4 text-sm div max-w-sm rounded-md bg-redgray-100 overflow-hidden shadow-lg font-poppins  h-50 border-b  border-green-500">
              <div className="flex items-center justify-center py-2 border-b-2 border-backblue-400 bg-gray-100 text-backblue-400">
                <h1 className="text-sm">Payment</h1>
              </div>
              {
                fetchingInProgress &&
                <div className="w-full flex justify-center mt-6">
                  <FontAwesomeIcon icon={faSpinner} className={"spinner text-" + spinnerColor + "-700 text-2xl"} />
                </div>
              }
              {
                !fetchingInProgress &&
                <div className="grid grid-cols-2 text-center pl-5 gap-2 mt-4 mb-6">
                  <div className="flex mt-1 col-span-2 text-gray-700 w-full font-semibold">
                    <span className="w-32">USD To Send :</span><span class="text-right"> {formatNumberToCurrency(data.transaction.usd_to_send, 2)}</span>
                  </div>
                  <div className="flex  col-span-2  text-gray-500 ">
                    <span className="w-32">Fee :</span><span class="text-right"> {formatNumberToCurrency(data.transaction.fees, 2)}</span>
                  </div>
                  <div className="flex col-span-2   text-gray-500">
                    <span className="w-32">USD To Pay :</span><span class="text-right"> {formatNumberToCurrency(data.transaction.usd_to_pay, 2)}</span>
                  </div>
                  <div className="flex col-span-2   text-gray-500">
                    <span className="w-32">Out Amount :</span><span class="text-right"> {formatNumberToCurrency(data.transaction.out_exhouse_amount, 2)} {data.transaction.out_currency_code}</span>
                  </div>
                </div>
              }
            </div>
            {
              !fetchingInProgress &&
              <div className="col-span-4 text-sm div max-w-sm rounded-md bg-redgray-100 overflow-hidden shadow-lg font-poppins  h-35 border-b mt-5 pt-2  border-yellow-500">
                <>
                  <TxDetail data={data.tx_details} readOnly />
                </>
              </div>
            }

          </div>
        </div>
        <div className=" div rounded-md bg-redgray-100 overflow-hidden shadow-lg font-poppins mr-5 mt-4 px-5 py-5">
            <DocumentPreviewer
              documents={data.transaction.documents}
              setAttachedDocuments={(doc) => setAttachedDocuments(doc)}
              //deleteDoc={deleteDoc}
              cssClass=""
              colSpan="8"
              docColumns="6"
              hideLabel="true"
            />
          </div>
      </div>
      {
        (senderDlgProps.show === "true") &&
        <SenderDlg emptyDataObject={emptyDataObject} showDlg={showSenderDlg} senderDlgProps={senderDlgProps} data={data} updateData={updateData} updateDataItem={updateDataItem} />
      }
      {
        (receiverDlgProps.show === "true") &&
        <ReceiverDlg emptyDataObject={emptyDataObject} showDlg={showReceiverDlg} receiverDlgProps={receiverDlgProps} data={data} updateData={updateData} updateDataItem={updateDataItem} />
      }
      {
        showTransactionSubmitter &&
        <TransactionSubmitter
          data={data}
          closeTransactionSubmitter={setShowTransactionSubmitter}
        />
      }
      {
        showPrintPreview &&
        <PrintPreviewer reportKey="txReceipt" reportId={data.transaction.id} closeDialog={() => setShowPrintPreview(false)} />
      }
      {
        isShowCancelTransaction &&
        <TransactionCancelDialog type="" title="Cancel Transaction?" reasonLabel='Please enter the Cancellation Reason' txId={id} setResult={setCancelTxAlertBoxResult} />
      }

    </>
  )
}

export default ViewTransaction