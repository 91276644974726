
import axios from "axios";

const BankDlg = ({ currentBank, setEditPopup ,setCurrentBank}) => {
    
    const handleInputChange = (e) => {
        setCurrentBank({ ...currentBank, [e.target.name]: e.target.value })
    }

    const handleClose = () => {
        setEditPopup(false)
    }

    const handleSave = async () => {
        const formData = new FormData()
        formData.append('bank_id',currentBank.bank_id)
        formData.append('bank_name',currentBank.name)
        formData.append('branch_name',currentBank.branch)
        formData.append('country',currentBank.country)
        formData.append('code',currentBank.code)
        formData.append('id',currentBank.id)

        try {

            const res = await axios.post("/api/editBank", formData)
            console.log(res.data)
      
            setEditPopup(false)
            window.location.reload();
      
          } catch (error) {
            console.log(error)
          }
    }

    return (

        <div className="fixed inset-0 z-50 grid place-items-center bg-slate-900 bg-opacity-10">
            <div className="py-6 px-4 flex gap-6 flex-col relative bg-white shadow-md rounded-md w-88">
                <div className="grid grid-cols-1 gap-4">

                    <div className="flex justify-between items-center gap-2">
                        <div className="text-right font-semibold text-gray-500">Code:</div>
                        <div className="text-blue-400">
                            <input type="text" name="code" className="border-b border-red-400 font-semibold h-7 mt-1  w-40 focus:outline-none focus:border-blue-400" value={currentBank.code} onChange={(e) => handleInputChange(e)} />
                        </div>
                    </div>

                    <div className="flex justify-between items-center gap-2">
                        <div className="text-right font-semibold text-gray-500">Bank:</div>
                        <div className="text-blue-400">
                        <div className="text-blue-400">
                            <input type="text" name="name" className="border-b border-red-400 font-semibold h-7 mt-1  w-40 focus:outline-none focus:border-blue-400" value={currentBank.name} onChange={(e) => handleInputChange(e)} />
                        </div>
                        </div>
                    </div>

                    <div className="flex justify-between items-center gap-2">
                        <div className="text-right font-semibold text-gray-500">Branch:</div>
                        <div className="text-blue-400">
                        <div className="text-blue-400">
                            <input type="text" name="branch" className="border-b border-red-400 font-semibold h-7 mt-1  w-40 focus:outline-none focus:border-blue-400" value={currentBank.branch} onChange={(e) => handleInputChange(e)} />
                        </div>
                        </div>
                    </div>

                    <div className="flex justify-between items-center gap-2">
                        <div className="text-right font-semibold text-gray-500">Country:</div>
                        <div className="text-blue-400">
                            <input type="text" name="country" className="border-b border-red-400 font-semibold h-7 mt-1 w-40 focus:outline-none focus:border-blue-400" value={currentBank.country} onChange={(e) => handleInputChange(e)} />
                        </div>
                    </div>

                </div>
                <div className="text-center">
                    <button onClick={handleClose} className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm w-20 px-4 py-2 bg-white text-gray-700 text-xs font-semibold hover:bg-gray-100 hover:shadow-md focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto uppercase">cancel</button>
                    <button onClick={handleSave} className="inline-flex justify-center items-center rounded-md border border-transparent shadow-sm w-20 px-4 py-2 bg-blue-500 text-white text-xs font-semibold hover:bg-blue-600 hover:shadow-md focus:outline-none sm:ml-3 uppercase ">Save</button>
                </div>
            </div>
        </div>
    );
}

export default BankDlg;