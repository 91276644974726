import React, { useEffect, useState, useContext } from 'react';
// import api from "./api"
import axios from "axios"
// import { auth_api_logout } from '../api/auth_api';
// import { country_api_get_countries } from "../api/country_api"
// import { bank_api_get_banks } from "../api/bank_api"
import { AuthContext } from "./AuthContext"
// import { exchange_house_api_get_exch_houses } from '../api/exchange_house_api';
// import { permission_api_get_navigator } from '../api/permission_api';
// import { client_initializer_api_fetchInitData } from '../api/client_initializer_api';
import _ from "lodash"

export const GlobalVarContext = React.createContext();

export const GlobalVarProvider = ({ children }) => {
  const [countries, setCountries] = useState()
  const [banks, setBanks] = useState()
  const [exchangeHouses, setExchangeHouses] = useState()
  const [navigator, setNavigator] = useState()

  const auth = useContext(AuthContext)

  useEffect(() => {
    if (auth.isAuthed) {
      axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL
      axios.defaults.withCredentials = true

      axios({
        method: "get",
        url: "/api/clientInitializer/fetchInitData"
      })
        .then(res => {
          let ret = res.data
          setNavigator(ret.navs.items)
          setExchangeHouses(ret.exHouses)
          setCountries(ret.countries)
          sessionStorage.setItem("countries", JSON.stringify(ret.countries))
        })

      axios({
        method: "get",
        url: "/api/banks/getBanks"
      })
        .then(res => {
          sessionStorage.setItem("banks", JSON.stringify(res.data.data))
          setBanks(res.data.data)
        })
    }
  }, [auth.isAuthed])

  return (
    <GlobalVarContext.Provider
      value={{
        countries: countries,
        banks: banks,
        exchangeHouses: exchangeHouses,
        navigator: navigator,
        resetNavigator: () => setNavigator()
      }}
    >
      {children}
    </GlobalVarContext.Provider>
  );
}

export default GlobalVarContext;