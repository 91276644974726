import React, { useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faSave } from '@fortawesome/free-solid-svg-icons';
import { AlertBoxResult } from "../utils/alert_box"
import { agent_account_api_receiveBank } from "../api/agent_account_api";
import { processException } from "../utils/err_handler";
import { isNull } from "lodash";


const AgentSettlementBankDialog = ({ data, setResult }) => {

  const [err, setErr] = useState()
  const [isApiFetching, setIsApiFetching] = useState(false)
  const [localData, setLocalData] = useState({ agent_id: data.agent_id })
  const initialFocusRef = useRef()
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  const settle = async () => {
    try {
      setIsApiFetching(true)
      setIsSaveDisabled(true)
      await agent_account_api_receiveBank(localData)
      setIsApiFetching(false)
      setResult(AlertBoxResult.MB_RESULT_YES);
    } catch (e) {
      setIsApiFetching(false)
      setErr(processException(e))
    }
  }

  const handleInputChange = (e) => {
    setLocalData(
      {
        ...localData,
        [e.target.name]: e.target.value
      }
    )
  }

  useEffect(() => {
    let btnSaveDisabled = true
    if (
      (localData.hasOwnProperty('usd_amount') && localData.usd_amount !== "") ||
      (localData.hasOwnProperty('ils_amount') && localData.ils_amount !== "") ||
      (localData.hasOwnProperty('commission') && localData.commission !== "")
    ) {
      btnSaveDisabled = false
    }
    setIsSaveDisabled(btnSaveDisabled)
  },[localData])

  useEffect(() => {
    initialFocusRef.current.focus();
  },[])

  const formatNumber = (number) => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    if (!(isNull(number))) {
      const formatted = Number(number).toLocaleString('en', options);
      return formatted;
    }
    else {
      return number;
    }
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="font-sans inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full px-2">
          <div className="bg-white px-4">
            <div className="sm:flex sm:items-start">
            <div className=" font-sora w-full ">
                <div className="py-2 font-semibold text-gray-700 mb-4 bg-green-100 pl-5">
                 <span className="inline-flex items-center justify-center px-1 py-1  leading-none text-green-700 border-1 border-green-600 rounded"> Bank Settlement</span>
                </div>
                <div class="grid grid-cols-3 gap-2">
                  <div className="mtt-1">
                  </div>
                  <div className="mt-1">
                    <label className="text-sm font- ">USD Balance </label>
                  </div> 
                  <div className="mt-1">
                    <label className="text-sm ">ILS Balance </label>
                  </div>
                   <div className="mtt-1 text-right">
                    <label className="text-sm pr-5">Amount to Due  </label>
                  </div>   
                  <div className="mtt-1">
                    <input type="text" name="amount_to_receive_usd"   className="h-7 text-sm w-full  text-right rounded outline-none px-2 mt-1 font-semibold text-gray-500" value={formatNumber(data.amount_to_receive_usd)} disabled/>
                  </div>
                  <div className="mtt-1">
                    <input type="text" name="amount_to_receive_ils"  className="h-7 text-sm w-full  text-right rounded outline-none px-2 mt-1 font-semibold text-gray-500" value={formatNumber(data.amount_to_receive_ils)} disabled/>
                  </div>
                  <div className="mtt-1 text-right pr-5">
                    <label className="text-sm ">Received Amount </label>
                  </div> 
   
                  <div className="mtt-1">
                    <input ref={initialFocusRef} type="text" name="usd_amount"  className="h-7 text-sm w-full border text-right rounded outline-none px-2 mt-1 font-semibold text-gray-500" value={localData?.usd_amount} onChange={(e) => handleInputChange(e)}/>
                  </div> 
                  <div className="mtt-1">
                    <input type="text" name="ils_amount"  className="h-7 text-sm w-full border text-right rounded outline-none px-2 mt-1 font-semibold text-gray-500" value={localData?.ils_amount} onChange={(e) => handleInputChange(e)}/>
                  </div> 
 
                  <div className="mt-5 text-right pr-5">
                    <label className="text-sm ">Commision Balance (USD) </label>
                  </div> 

                  <div className="mt-5">
                    <input type="text" name="commission_to_be_paid"  className="h-7 text-sm w-full  text-right rounded outline-none px-2 mt-1 font-semibold text-gray-500" value={formatNumber(data.commission_to_be_paid)} disabled/>
                  </div>  
                  <div className="mt-5">
                  
                  </div>
                  <div className="mtt-1 text-right pr-5">
                  <label className="text-sm ">Commission Amount Paid </label>
                    </div>
                  <div className="mtt-1">
                    <input type="text" name="commission" put className="h-7 text-sm w-full border text-right rounded outline-none px-2 mt-1 font-semibold text-gray-500" value={localData?.commission} onChange={(e) => handleInputChange(e)}/>
                  </div> 
                  <div className="mtt-1">
                  <label className="text-xs text-red-500 pr-5">  * to receive extra paid commission enter (-) negative amount </label>
                    </div>
                  <div className="mt-5 text-right">
                    <label className="text-sm pr-5">Notes </label>
                  </div>    
                 <div className="mt-5 col-span-2">
                    <input type="text" name="notes"  className="h-7 text-sm w-full border text-left rounded outline-none px-2 mt-1 font-semibold text-gray-500" value={localData?.notes} onChange={(e) => handleInputChange(e)}/>
                  </div> 
                </div> 
              </div>
            </div>
          </div>
          <div className={ (err ? "" : "hidden") + " px-4 py-2 text-xs font-inter"}>
            <div className=" rounded-md text-red-500 bg-red-100 p-2">{err}</div>
          </div>
          <div className="bg-gray-50 py-3 px-4 sm:flex sm:flex-row-reverse mt-4">
            <button onClick={() => setResult(AlertBoxResult.MB_RESULT_CANCEL)} type="button" className="inline-flex justify-center items-center rounded-md border border-transparent shadow-sm w-20 px-4 py-2 bg-blue-500 text-white text-xs font-semibold hover:bg-blue-600 hover:shadow-md focus:outline-none sm:ml-3 uppercase">
              Cancel
            </button>
            <button
              disabled={isSaveDisabled}
              onClick={() => settle()}
              className={"inline-flex justify-center items-center rounded-md border shadow-sm w-20 px-4 py-2 text-xs font-semibold focus:outline-none sm:ml-3 uppercase "
                + (isSaveDisabled ? " text-gray-400 bg-gray-100 border-transparent" : "text-red-700 hover:bg-red-700 border-red-700 hover:text-white hover:shadow-md focus:outline-none")}>
              <FontAwesomeIcon icon={faSpinner} fixedWidth className={ (isApiFetching ? "" : "hidden") + " spinner mr-2"} />
              <FontAwesomeIcon icon={faSave} fixedWidth className="mr-2" />
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgentSettlementBankDialog