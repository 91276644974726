import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import QuickReport from "../components/QuickReport"
import Loader from "../components/Loader"
import { report_utility_api_get_posted_transactions_cash } from "../api/report_utility_api"
import history from "../utils/history"
import axios from "axios"

const PostedTransactionReportCash = () => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [pageNo, setPageNo] = useState(1)
  const [date,setDate]=useState([])

 let formattedDateOne = null
  let formattedDateTwo = null

  useEffect(() => {
   window.addEventListener("load", sessionStorage.setItem('formattedDateTwo',''),check());
   setPageNo(sessionStorage.getItem('page'))
   // alert(sessionStorage.getItem('page'))
   refreshData(sessionStorage.getItem('page'))
  }, [])

  const check = () => {
    if(sessionStorage.getItem('back')!=1){
    refreshData(1)
    }else {
    sessionStorage.setItem('back',0)
    }
    
  }

  const fetchNextPage = async () => {
    let _pageNo = pageNo
    _pageNo++
    if( sessionStorage.getItem('formattedDateTwo')!=''){
      const res = await axios.get(`/api/reportUtilities/getPostedTransactionsCashFetch?page_no=${_pageNo}&start=${sessionStorage.getItem('formattedDateOne')}&end=${sessionStorage.getItem('formattedDateTwo')}`)
      setData(res.data.data)
    console.log(res.data.data.data.detail)
    }else{
      refreshData(_pageNo)

    }
    setPageNo(_pageNo)
  }
  const fetchPreviousPage = async () => {
    let _pageNo = pageNo
    if (_pageNo !== 1) {
      _pageNo--
      if( sessionStorage.getItem('formattedDateTwo')!=''){
        const res = await axios.get(`/api/reportUtilities/getPostedTransactionsCashFetch?page_no=${_pageNo}&start=${sessionStorage.getItem('formattedDateOne')}&end=${sessionStorage.getItem('formattedDateTwo')}`)
        setData(res.data.data)
      console.log(res.data.data.data.detail)
      }else{
        refreshData(_pageNo)
  
      }
      setPageNo(_pageNo)
    }
  }

  const refreshData = async (page) => {
    sessionStorage.setItem('page',page)
    sessionStorage.setItem('formattedDateTwo','')
    setIsLoading(true)
    let _res = await report_utility_api_get_posted_transactions_cash(page)
    setData(_res.data.data)
    console.log(_res)
    setIsLoading(false)
  }

  const search = async (searchText) => {
    setIsLoading(true)
    sessionStorage.setItem("search",searchText)
    let _res = await report_utility_api_get_posted_transactions_cash(1, searchText)
    setData(_res.data.data)
    setIsLoading(false)
  }

  const handleRowAction = (index, action) => {
    //console.log(data.data.detail[index]);
    switch (action) {
      case "zoom":
        history.push(`/viewTransaction/${data.data.detail[index].remit_id}`)
        break;

      default:
        break;
    }
  }

  // task007 start
  const handleDateSubmit = async () => {
    const dateone = new Date(date[0]);
    const datetwo = new Date(date[1]);
     formattedDateOne = `${dateone.getFullYear()}-${(dateone.getMonth() + 1).toString().padStart(2, '0')}-${dateone.getDate().toString().padStart(2, '0')}`;
     formattedDateTwo = `${datetwo.getFullYear()}-${(datetwo.getMonth() + 1).toString().padStart(2, '0')}-${datetwo.getDate().toString().padStart(2, '0')}`;
     sessionStorage.setItem('formattedDateOne',formattedDateOne)
    sessionStorage.setItem('formattedDateTwo',formattedDateTwo)

    try {
        const res = await axios.get(`/api/reportUtilities/getPostedTransactionsCashFetch?page_no=1&start=${formattedDateOne}&end=${formattedDateTwo}`)
        setData(res.data.data)
      // console.log(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Helmet>
        <title>{isLoading ? "Querying..." : data && data.config.title}</title>
      </Helmet>
      <QuickReport
        data={data}
        setDate={setDate}
        handleDateSubmit={handleDateSubmit}
        refreshData={refreshData}
        rowAction={handleRowAction}
        search={search}
        fetchPreviousPage={fetchPreviousPage}
        fetchNextPage={fetchNextPage}
        currentPageNo={pageNo}
        isLoading={isLoading}
        //source="PostedTransactionReport"
      />
    </>
  )
  // end
}

export default PostedTransactionReportCash