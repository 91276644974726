import api from "../utils/api"

export const sender_api_create = async (data) => {
  var payload = {
    "Sender": {
      "CRE": data
    }
  }

  var ret = {}

  await api().post("/api/masterDetails", payload)
    .then((response) => {
      switch (response.data.status) {
        case "success":
          ret = { status: "OK", data: response.data.data, code: "", message: "Successfully submitted." }
          break;
        case "error":
          ret = { status: "ERR", data: "", code: "", message: response.data.message }
          break;

        default:
          break;
      }
    })
    .catch(err => {
      if (err.response) {
        ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
      } else if (err.request) {
        ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
      } else {
        ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
      }
    });
  return ret
}

export const sender_api_update = async (data) => {
  var payload = {
    "Sender": {
      "UPD": data
    }
  }
  var ret = {}

  await api().post("/api/masterDetails", payload)
    .then((response) => {
      switch (response.data.status) {
        case "success":
          ret = { status: "OK", data: response.data.data, code: "", message: "Successfully submitted." }
          break;
        case "error":
          ret = { status: "ERR", data: "", code: "", message: response.data.message }
          break;

        default:
          break;
      }
    })
    .catch(err => {
      if (err.response) {
        ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
      } else if (err.request) {
        ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
      } else {
        ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
      }
    });
  return ret
}

export const _query = async (searchParams, query) => {
  //console.log(searchParams)
  var ret = {}
  await api().get("/api/senders", { params: { query: query } })
    .then(response => {
      switch (response.data.status) {
        case "success":
          ret = { status: "OK", data: response.data.data, code: "", message: "Successfully submitted." }
          break;
        case "error":
          ret = { status: "ERR", data: "", code: "", message: response.data.message }
          break;

        default:
          break;
      }
    })
  return ret
}

// export const isAlive = (data) => {
//   let promise = api().get("/sanctum/csrf-cookie")
//   return promise
// }

// export const login = () => {
//   api().get("/sanctum/csrf-cookie").then(res => {
//     api().post('/login', { email: 'superadmin@premialabs.com', password: 'pwd' })
//   })

// }

export const sender_api_get_senders = async (page_no, search = null) => {
  var ret = {}

  await api().get("/api/senders/getSenders", { params: { page_no: page_no, search: search } })
    .then((response) => {
      switch (response.data.status) {
        case "success":
          ret = { status: "OK", data: response.data.data, code: "", message: "Successfully submitted." }
          break;
        case "error":
          ret = { status: "ERR", data: "", code: "", message: response.data.message }
          break;

        default:
          break;
      }
    })
    .catch(err => {
      if (err.response) {
        ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
      } else if (err.request) {
        ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
      } else {
        ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
      }
    });
  return ret
}