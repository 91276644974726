import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { auth_api_is_alive, auth_api_logout, auth_api_set_csrf_cookie } from '../api/auth_api';
import history from "../utils/history"
import GlobalVarContext from './GlobalVarContext';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthed, setIsAuthed] = useState()
  const [user, setUser] = useState()
  const [isCsrfOk, setIsCsrfOk] = useState(false)
  const [authErr, setAuthErr] = useState()
  const location = useLocation

   //let resetNavigator = useContext(GlobalVarContext).resetNavigator

  useEffect(() => {
    auth_api_set_csrf_cookie()
      .then(csrf => {
        if (csrf.status === "OK") {
          setIsCsrfOk(true)
          auth_api_is_alive()
            .then(alive => {
              if (alive.status === "OK") {
                setIsAuthed(true)
                setUser(alive.data)
              } else {
                setAuthErr(alive.message)
                setIsAuthed(false)
                setUser()
                const loc = {
                  pathname: '/login',
                  state: { from: location }
                }
                history.push(loc)
              }
            })
        } else {
          setAuthErr(csrf.message)
          setIsCsrfOk(false)
          setIsAuthed(false)
          setUser()
        }
      })
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthed: isAuthed,
        isCsrfOk: isCsrfOk,
        authErr: authErr,
        setIsAuthed: (val) => setIsAuthed(val),
        user: user,
        setUser: (val) => setUser(val),
        logout: (redirect) => {
          auth_api_logout()
            .then(res => {
              setIsAuthed(false)
              //resetNavigator()
              setUser()
              const loc = {
                pathname: '/login',
                state: { from: location }
              }
              if (redirect !== "") {
                history.push(redirect)
              } else {
                history.push(loc)
              }
            })
        }
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}