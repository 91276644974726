import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faSpinner, faTimes, faUserPlus, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { CountryList } from '../CountryList'
import { IdTypeList } from "../IdTypeList";
import { GenderList } from "../GenderList";
import { agent_api_create, agent_api_update } from "../../api/agent_api"
import { fetchResponseMessages } from "../../utils/err_handler"
import DocumentPreviewer from "../DocumentPreviewer/DocumentPreviewer";
import { countries } from "../../utils/countries"

const AgentDlg = ({ emptyDataObject, data, updateData, showDlg, agentDlgProps, fetchData }) => {
  const [localData, setLocalData] = useState(emptyDataObject.agent)
  const [modeColor, setModeColor] = useState("blue")
  const [statusColor, setStatusColor] = useState()
  const [isAlertShown, setIsAlertShown] = useState(false)
  const [isSaveInProgress, setSaveInProgress] = useState(false)
  const [apiResponse, setApiResponse] = useState({ status: "", type: "", data: "", code: "", message: "" })
  const [flagUrl, setFlagUrl] = useState()

  useEffect(() => {
    if (agentDlgProps.mode === "edit") {
      setLocalData(data.agent)
    } else {
      setLocalData(emptyDataObject.agent)
    }
  }, [])

  useEffect(() => {
    switch (localData.status) {
      case "active":
        setStatusColor("green")
        break;
      case "blocked":
        setStatusColor("red")
        break;
      default:
        break;
    }
  }, [data])

  useEffect(() => {
    if (["OK", "ERR"].includes(apiResponse.status)) {
      setIsAlertShown(true)
    }
    if (apiResponse.status === "OK") {
      fetchData()
      setTimeout(() => {
        updateData("agent", apiResponse.data[0])
        showDlg(false)
      }, 500);
    }
  }, [apiResponse])

  const handleInputChange = (e) => {
    if (e.target.name === "status") {
      let curr_status = 'Inactive';
      if (e.target.value === 'Inactive') {
        curr_status = "Active";
      }
      setLocalData({ ...localData, [e.target.name]: curr_status })
    }
    else {
      if (e.target.name === "commission_percentage") {
        if (e.target.value > 50) {
          setApiResponse({ status: "ERR", type: "", data: "", code: "", message: "Commission Percentage cannot be greater than 50" })
          setIsAlertShown(true)
        } else {
          setApiResponse({ status: "", type: "", data: "", code: "", message: "" })
          setIsAlertShown(false)
        }
      }
      setLocalData({ ...localData, [e.target.name]: e.target.value })
    }
  }

  const handleSave = async () => {
    if (localData.commission_percentage > 50) {
      setApiResponse({ status: "ERR", type: "", data: "", code: "", message: "Commission Percentage cannot be greater than 50" })
      setIsAlertShown(true)
      return
    } else {
      setApiResponse({ status: "", type: "", data: "", code: "", message: "" })
      setIsAlertShown(false)
    }
    setSaveInProgress(true)
    if (localData.id === "") {
      setApiResponse(await agent_api_create([localData]))
    } else {
      setApiResponse(await agent_api_update([localData]))
    }
    setSaveInProgress(false)
  }

  useEffect(() => {
    if ((localData.country_code !== "")) {
      setFlagUrl(process.env.REACT_APP_FRONTEND_URL + "/flags/" + localData.country_code + ".svg",)
    }
  }, [localData])

  const setAttachedDocuments = (doc) => {
    if (localData.documents.length > 0) {
      setLocalData({ ...localData, documents: [...localData.documents, doc] })
    } else {
      setLocalData({ ...localData, documents: [doc] })
    }
  }

  const deleteDoc = (index) => {
    let _docs = [...localData.documents.filter((item, i) => (i !== index))]
    setLocalData({ ...localData, documents: _docs })
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="font-sans inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className={"mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-" + modeColor + "-100 sm:mx-0 sm:h-10 sm:w-10"}>
                <FontAwesomeIcon icon={agentDlgProps.mode === "edit" ? faUserEdit : faUserPlus} className={"text-" + modeColor + "-600"} />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left font-publicSans">
                <div className="flex justify-between">
                  <div className="flex items-center py-2">
                    <h3 className="flex text-lg leading-6 font-semibold items-center text-gray-700 mr-3 w-44 h-7" id="modal-title">
                      {
                        (agentDlgProps.mode === "add") && "Add new Agent"
                      }
                      {
                        (agentDlgProps.mode === "edit") && "Edit Agent"
                      }
                    </h3>
                    {
                      isAlertShown &&
                      <div className={"flex w-full justify-between items-center rounded px-3 h-7  text-sm font-semibold bg-" + (apiResponse.status === "ERR" ? "red" : "green") + "-100 text-" + (apiResponse.status === "ERR" ? "red" : "green") + "-500"}>
                        <span>{fetchResponseMessages(apiResponse.message)}</span>
                        <button onClick={() => setIsAlertShown(false)}><FontAwesomeIcon icon={faTimesCircle} className={"ml-3 text-" + (apiResponse.status === "ERR" ? "red" : "green") + "-500"} /></button>
                      </div>
                    }
                  </div>
                  <button
                    onClick={() => showDlg(false)}
                    className="flex justify-center h-7 w-7 border border-gray-400 rounded-full -mt-3 -mr-2"
                  >
                    <FontAwesomeIcon icon={faTimes} className={"text-red-500 text-lg mt-1"} />
                  </button>
                </div>
                <hr className="border-b-1 border-gray-300" />
                <div className="mt-2">
                  <div className="grid grid-cols-6 gap-4 items-baseline text-sm text-red-400" >
                    <div className="text-right font-bold">Blocked</div>
                    <input type="checkbox" name="status" className="text-left" value={localData.status} checked={localData.status==='Active' ? false : true} onChange={(e) => handleInputChange(e)} />
                  </div>
                  <div className="max-w-7xl mx-auto mt-3 text-sm">
                    <input type="text" hidden name="id" value={localData.id} readOnly />
                    <div className="grid grid-cols-6 gap-4 items-baseline">
                      <div className="text-right font-semibold text-gray-500">First Name:</div>
                      <div className="text-blue-400"><input type="text" name="first_name" className="border-b border-red-400 font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400" value={localData.first_name} onChange={(e) => handleInputChange(e)} /></div>
                      <div className="text-right font-semibold text-gray-500">Middle Name:</div>
                      <div className="text-blue-400"><input type="text" name="middle_name" className="border-b font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400" value={localData.middle_name} onChange={(e) => handleInputChange(e)} /></div>
                      <div className="text-right font-semibold text-gray-500">Last Name:</div>
                      <div className="text-blue-400"><input type="text" name="last_name" className="border-b font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400" value={localData.last_name} onChange={(e) => handleInputChange(e)} /></div>
                    </div>
                    <div className="grid grid-cols-6 gap-4 items-baseline">
                      <div className="text-right font-semibold text-gray-500">Gender:</div>
                      <select name="gender" value={localData.gender} onChange={(e) => handleInputChange(e)} className="text-blue-400 font-semibold focus:outline-none  border-b border-red-400 py-1 w1-40">
                        <GenderList />
                      </select>
                      <div className="text-right font-semibold text-gray-500">Date of Birth:</div>
                      <div className="text-blue-400"><input type="date" name="dob" className="border-b font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400 uppercase" value={localData.dob} onChange={(e) => handleInputChange(e)} /></div>
                    </div>
                    <div className="grid grid-cols-6 gap-4 items-baseline">
                      <div className="text-right font-semibold text-gray-500">Mobile Number:</div>
                      <div className="text-blue-400"><input type="text" name="mobile_no" className="border-b border-red-400 font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400 uppercase" value={localData.mobile_no} onChange={(e) => handleInputChange(e)} /></div>
                      <div className="text-right font-semibold text-gray-500">Home Number:</div>
                      <div className="text-blue-400"><input type="text" name="home_no" className="border-b font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400 uppercase" value={localData.home_no} onChange={(e) => handleInputChange(e)} /></div>
                      <div className="text-right font-semibold text-gray-500">Email:</div>
                      <div className="text-blue-400"><input type="text" name="email" className="border-b font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400 " value={localData.email} onChange={(e) => handleInputChange(e)} /></div>
                    </div>
                    <div className="grid grid-cols-6 gap-4 items-baseline">
                      <div className="col-span-1 text-right font-semibold text-gray-500">Street Address:</div>
                      <div className="col-span-3 text-blue-400"><input type="text" name="street_address" className="border-b  pl-1 font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400" value={localData.street_address} onChange={(e) => handleInputChange(e)} /></div>
                      <div className="col-span-1 text-right font-semibold text-gray-500">City:</div>
                      <div className="col-span-1 text-blue-400"><input type="text" name="city" className="border-b font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400" value={localData.city} onChange={(e) => handleInputChange(e)} /></div>
                    </div>
                    <div className="grid grid-cols-6 gap-4 items-baseline mt-1">
                      <div className="text-right font-semibold text-gray-500">Country:</div>
                      <div className="flex">
                        <select name="country_code" value={localData.country_code} onChange={(e) => handleInputChange(e)} className="text-blue-400 font-semibold focus:outline-none  border-b border-red-400 py-1 w1-40">
                          <CountryList countries={countries} />
                        </select>
                        <img className="ml-3 w-8" src={flagUrl} alt={localData.country_code} />
                      </div>
                      <div className="text-right font-semibold text-gray-500">Postal Code:</div>
                      <input type="text" name="postal_code" className="text-blue-400 border-b font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400 uppercase" value={localData.postal_code} onChange={(e) => handleInputChange(e)} />
                    </div>
                    <hr className="my-4" />
                    <div className="grid grid-cols-3 gap-4">
                      <div>
                        <div className="grid grid-cols-2 gap-4 items-baseline">
                          <div className="text-right font-semibold text-gray-500">ID Type:</div>
                          <select name="id_type" value={localData.id_type} onChange={(e) => handleInputChange(e)} className="text-blue-400 font-semibold focus:outline-none border-b border-red-400 py-1 w1-40 uppercase">
                            <IdTypeList />
                          </select>
                        </div>
                        <div className="grid grid-cols-2 gap-4 items-baseline">
                          <div className="text-right font-semibold text-gray-500">ID Number:</div>
                          <div className="text-blue-400"><input type="text" name="id_number" className="border-b border-red-400 font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400 uppercase" value={localData.id_number} onChange={(e) => handleInputChange(e)} /></div>
                        </div>
                        <div className="grid grid-cols-2 gap-4 items-baseline">
                          <div className="text-right font-semibold text-gray-500">ID Expiry Date:</div>
                          <div className="text-blue-400"><input type="date" name="id_expiry" className="border-b font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400 uppercase" value={localData.id_expiry} onChange={(e) => handleInputChange(e)} /></div>
                        </div>
                        <div className="grid grid-cols-2 gap-4 items-baseline">
                          <div className="text-right font-semibold text-gray-500">Submit Limit (USD):</div>
                          <div className="text-blue-400"><input type="text" name="submit_limit" className="border-b font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400" value={localData.submit_limit} onChange={(e) => handleInputChange(e)} /></div>
                        </div>
                        <div className="grid grid-cols-2 gap-4 items-baseline">
                          <div className="text-right font-semibold text-gray-500">Commission Percentage:</div>
                          <div className="text-blue-400 flex items-baseline">
                            <input type="text" name="commission_percentage" className="text-right border-b  pl-1 text-sm font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400" value={localData.commission_percentage} onChange={(e) => handleInputChange(e)} />
                            <span>%</span>
                          </div>
                        </div>
                      </div>
                      <DocumentPreviewer
                        documents={localData.documents}
                        setAttachedDocuments={(doc) => setAttachedDocuments(doc)}
                        deleteDoc={deleteDoc}
                        cssClass="shadow"
                        colSpan="2"
                        docColumns="4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button disabled={isSaveInProgress} onClick={() => handleSave()} type="button" className="inline-flex justify-center items-center rounded-md border border-transparent shadow-sm w-20 px-4 py-2 bg-blue-500 text-white text-xs font-semibold hover:bg-blue-600 hover:shadow-md focus:outline-none sm:ml-3 uppercase ">
              {
                !isSaveInProgress &&
                "Save"
              }
              {
                isSaveInProgress &&
                <FontAwesomeIcon icon={faSpinner} className="spinner" />
              }
            </button>
            <button onClick={() => showDlg(false)} type="button" className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm w-20 px-4 py-2 bg-white text-gray-700 text-xs font-semibold hover:bg-gray-100 hover:shadow-md focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto uppercase">
              Cancel
            </button>
          </div>
          {/* <div>{JSON.stringify(localData, null, 4)}</div> */}
        </div>
      </div>
    </div>
  )
}

export default AgentDlg