import React, { useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { auth_api_change_password } from "../api/auth_api"
import { AuthContext } from "../utils/AuthContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ChangePassword = () => {
  const [data, setData] = useState({ current_pwd: "", new_pwd: "", confirm_pwd: "" })
  const [apiResponse, setApiResponse] = useState({ status: "", type: "", data: "", code: "", message: "" })
  const [showPassword, setShowPassword] = useState(false)
  let auth = useContext(AuthContext)

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const resetApiResponse = () => {
    setApiResponse({ status: "", type: "", data: "", code: "", message: "" })
  }

  const changePwd = async () => {
    auth_api_change_password(data)
      .then(res => {
        setApiResponse(res)
      })
      .catch(err => {
        setApiResponse(err)
      })
  }

  useEffect(() => {
    if (apiResponse.status === "OK") {
      setTimeout(() => auth.logout("/"), 400)
    }
  }, [apiResponse])

  return (
    <>
      <Helmet>
        <title>Change Password</title>
      </Helmet>
      <div className={"max-w-7xl mx-auto px-4 mt-6"}>
        <div className="flex justify-between items-center bg-gray-100 py-1 rounded-md px-2">
          <div className="font-publicSans text-xl font-semibold text-gray-700">Change Password</div>
          <div className={"font-inter px-2 rounded " + (["OK", "ERR"].includes(apiResponse.status) ? "" : "hidden") + (apiResponse.status === "OK" ? "  text-green-600" : " text-red-600")}>
            {
              (apiResponse.status === "OK") && apiResponse.message
            }
            <ul>
              {
                (apiResponse.status === "ERR") && (Array.isArray(apiResponse.message)) &&
                Object.values(JSON.parse(apiResponse.message)).map(item => <li>{item}</li>)
              }
              {
                (apiResponse.status === "ERR") && (!Array.isArray(apiResponse.message)) &&
                <li>{apiResponse.message}</li>
              }
            </ul>
          </div>
          <button
            onClick={() => changePwd()}
            className="font-semibold font-publicSans text-white bg-blue-500 hover:bg-blue-600 rounded shadow px-3 py-1 focus:outline-none focus:ring-offset-transparent focus:ring-offset-2 focus:ring-2 focus:ring-blue-200"
          >
            Save
          </button>
        </div>

        <div className="mt-6 w-6/12 font-publicSans text-sm font-semibold text-gray-600" id="table_container">
          <div className="grid grid-rows-3 gap-4">
            <div className="grid grid-cols-5 items-center">
              <label>Current Password</label>
              <input type="password" name="current_pwd" value={data.current_pwd || ""} onChange={(e) => handleInputChange(e)} className=" px-1 h-8 border rounded col-span-2 text-xl w-full focus:outline-none focus:ring-offset-transparent focus:ring-offset-2 focus:ring-2 focus:ring-blue-200" />
            </div>
            <div className="grid grid-cols-5 items-center">
              <label className="">New Password</label>
              {
                !showPassword &&
                <input type="password" name="new_pwd" value={data.new_pwd || ""} onChange={(e) => handleInputChange(e)} className=" px-1  h-8 border rounded col-span-2 text-xl w-full focus:outline-none focus:ring-offset-transparent focus:ring-offset-2 focus:ring-2 focus:ring-blue-200" />
              }
              {
                showPassword &&
                <input type="text" name="new_pwd" value={data.new_pwd || ""} onChange={(e) => handleInputChange(e)} className=" px-1  h-8 border rounded col-span-2 text-xl w-full focus:outline-none focus:ring-offset-transparent focus:ring-offset-2 focus:ring-2 focus:ring-blue-200" />
              }
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} className={"-ml-6 text-gray-400 hover:text-gray-500"} onMouseDown={() => setShowPassword(!showPassword)} onMouseUp={() => setShowPassword(!showPassword)} />
            </div>
            <div className="grid grid-cols-5 items-center">
              <label>Confirm Password</label>
              <div className="col-span-2 flex items-center">
                {
                  !showPassword &&
                  <input type="password" name="confirm_pwd" value={data.confirm_pwd || ""} onChange={(e) => handleInputChange(e)} className=" pl-1 pr-8 h-8 border rounded text-xl w-full focus:outline-none focus:ring-offset-transparent focus:ring-offset-2 focus:ring-2 focus:ring-blue-200" />
                }
                {
                  showPassword &&
                  <input type="text" name="confirm_pwd" value={data.confirm_pwd || ""} onChange={(e) => handleInputChange(e)} className=" pl-1 pr-8 h-8 border rounded text-lg w-full focus:outline-none focus:ring-offset-transparent focus:ring-offset-2 focus:ring-2 focus:ring-blue-200" />
                }
              </div>
              {
                (data.new_pwd !== data.confirm_pwd) &&
                <div className="text-red-600 text-xs ml-2 col-span-2">Passwords do not match</div>
              }
            </div>
          </div>
        </div>
        {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
      </div>
    </>
  )
}

export default ChangePassword