export const AlertBoxType = {
  MB_Info: 1,
  MB_YesNo: 2,
  MB_YesNoCancel: 3
}

export const AlertBoxResult = {
  MB_RESULT_OK: 10,
  MB_RESULT_YES: 20,
  MB_RESULT_NO: 30,
  MB_RESULT_CANCEL: 40
}