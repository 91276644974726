import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import QuickReport from "../components/QuickReport"
import { report_utility_api_get_pending_transactions } from "../api/report_utility_api"
import history from "../utils/history"
import Loader from "../components/Loader"

const PendingTransactionReport = () => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [pageNo, setPageNo] = useState(1)

  useEffect(() => {
    refreshData(pageNo)
  }, [])

  const fetchNextPage = () => {
    let _pageNo = pageNo
    _pageNo++
    refreshData(_pageNo)
    setPageNo(_pageNo)
  }
  const fetchPreviousPage = () => {
    let _pageNo = pageNo
    if (_pageNo !== 1) {
      _pageNo--
      refreshData(_pageNo)
      setPageNo(_pageNo)
    }
  }

  const refreshData = async (page) => {
    setIsLoading(true)
    let _res = await report_utility_api_get_pending_transactions(page)
    console.log(_res.data);
    setData(_res.data.data)
    setIsLoading(false)
  }

  const search = async (searchText) => {
    setIsLoading(true)
    let _res = await report_utility_api_get_pending_transactions(1, searchText)
    setData(_res.data.data)
    setIsLoading(false)
  }

  const handleRowAction = (index, action) => {
    switch (action) {
      case "zoom":
        history.push(`/viewTransaction/${data.data.detail[index].remit_id}`)
        break;

      default:
        break;
    }
  }

  return (
    <>
      <Helmet>
        <title>{isLoading ? "Querying..." : data && data.config.title}</title>
      </Helmet>
      <QuickReport
        data={data}
        refreshData={refreshData}
        rowAction={handleRowAction}
        search={search}
        fetchPreviousPage={fetchPreviousPage}
        fetchNextPage={fetchNextPage}
        currentPageNo={pageNo}
        isLoading={isLoading}
      />
      {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
    </>
  )
}

export default PendingTransactionReport