import api from "../utils/api"

export const file_uploader_api_upload = async (data) => {
  var formData = new FormData()
  formData.append("document", data.document)
  formData.append("title", data.title)
  var ret = {}

  await api().post("/api/documents", formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then((response) => {
      switch (response.data.status) {
        case "success":
          ret = { status: "OK", data: response.data.data, code: "", message: "Successfully submitted." }
          break;
        case "error":
          ret = { status: "ERR", data: "", code: "", message: response.data.message }
          break;

        default:
          break;
      }
    })
    .catch(err => {
      if (err.response) {
        ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
      } else if (err.request) {
        ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
      } else {
        ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
      }
    });
  return ret
}