export const fetchResponseMessages = (errObject) => {
  try {
    return Object.entries(JSON.parse(errObject))[0][1].reduce((accum, curr) => {
      if (accum === "") {
        return curr
      } else {
        return accum + ", " + curr
      }
    }, "")
  } catch (error) {
    return errObject
  }
}

export const processException = (err) => {
  let msg = ""
  if (err.response) {
    msg = err.response.data.message
  } else if (err.request) {
    console.error(err.request)
    msg = "NETWORK ERROR: Server is not available."
  } else {
    msg = err.message
  }
  return msg
}