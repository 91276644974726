
import genders from "../utils/genders"

export const GenderList = () => {
  return genders.filter(item => item.enabled).map(item => {
    return <option key={item.code} value={item.code} className="">{item.name}</option>
  })
}

export const getGenderName = (code) => {
  var gender_name = ""
  genders.forEach(element => {
    if (code === element.code) {
      gender_name = element.name
    }
  })
  return gender_name
}