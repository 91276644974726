// import { _query } from "../api/sender_api"
import _ from "lodash"
import { useEffect, useCallback, useState } from "react";
import parse from "html-react-parser"

const SearchResults = ({ searchText, handleSearchTextChange, stepNumber, updateData, section, api, displayColumns, arrowCounter, additionalParameters }) => {
  const [searchResults, setSearchResults] = useState()
  const [focussedRow, setFocussedRow] = useState()
  const [prevArrowCounter, setPrevArrowCounter] = useState()

  useEffect(() => {
    setFocussedRow(arrowCounter)
  }, [arrowCounter])

  var query_api = "";

  useEffect(() => {
    (async () => {
      const { _query } = await import('../api/' + api + '_api.js');
      query_api = _query
    })();
  }, [])

  const delayedQuery = useCallback(_.debounce((searchText) => {
    var ret = ""
    setSearchResults()
    query_api(additionalParameters, searchText)
      .then(res => {
        //console.log(res.data)
        setSearchResults(res.data)
      })
      .catch(err => {
        if (err.response) {
          ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
        } else if (err.request) {
          ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
        } else {
          ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
        }
      });
  }, 100), []);

  useEffect(() => {
    (searchText !== "") && delayedQuery(searchText);
    (searchText === "") && setSearchResults()
  }, [searchText])

  const handleRowClick = (item) => {
    updateData(section, item)
    setSearchResults()
    handleSearchTextChange("")
  }

  const highlightSearchText = (searchText, val) => {
    //console.log(val);
    if (val !== null) {
      const regex = new RegExp(searchText, 'i');
      var ret = val.replace(regex, '<span class="font-semibold text-gray-900">' + searchText + '</span>')
      return parse(ret)
    }
  }

  const columnLengths = {
    full_name: "w-60",
    bank: "w-80",
    bank_name: "w-80"
  }

  return (
    <div className={"rounded shadow-lg border mt-1 bg-white border-gray-400 h-48 w-full overflow-hidden  " + (searchResults ? "block" : "hidden")}>
      <table className="table-fixed text-sm shadow-md border-t-2 border-blue-500 ">
        <thead>
          <tr>
            {
              searchResults && searchResults.columns &&
              displayColumns.split(",").map((item, index) => {
                let label = Object.entries(searchResults.columns).filter(col => {
                  if (col[0] === item) {
                    return true
                  }
                })[0]
                {console.log(label)}
                return <td className={" px-2 text-left border " + (columnLengths[label[0]] ? columnLengths[label[0]] : "w-32")} key={index}>{label[1]}</td>
              
              })
            }
          </tr>
        </thead>
        <tbody className="">
          {
            searchResults &&
            searchResults.results.map((item, index) => {
              return (
                <tr
                  key={item.id}
                  onMouseMove={() => setFocussedRow(index)}
                  onMouseLeave={() => setFocussedRow()}
                  onClick={() => handleRowClick(item)}
                  className={(index === focussedRow) ? "bg-gray-100 cursor-default" : ""}
                >
                  {
                    displayColumns.split(",").map(column => {
                      return (
                        <td key={column} className="border py-1 px-2 font-inter">
                          <div className="truncate text-gray-900">{highlightSearchText(searchText, item[column])}</div>
                        </td>
                      )
                    })
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )

}

export default SearchResults