import React, { useContext, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faFileUpload, faTimes, faCircle } from '@fortawesome/free-solid-svg-icons';
// import { file_uploader_api_upload } from "../../api/file_uploader_api"
// import { AlertBoxType, AlertBoxResult } from "../../utils/alert_box"
// import { exchange_house_api_get_exch_houses } from "../api/exchange_house_api"
// import GlobalVarContext from "../utils/GlobalVarContext";
import history from "../utils/history";
import {
  exhouse_api_createSender,
  exhouse_api_createReceiver,
  exhouse_api_createReceiverBank,
  exhouse_api_postTransaction
} from "../api/exchange_house_api";


const TransactionSubmitter = ({ data, closeTransactionSubmitter }) => {

  const [senderStatus, setSenderStatus] = useState("pending")
  const [receiverStatus, setReceiverStatus] = useState("pending")
  const [bankStatus, setBankStatus] = useState("pending")
  const [submissionStatus, setSubmissionStatus] = useState("pending")
  const [errText, setErrText] = useState("")
  const [infoMessage, setInfoMessage] = useState("")
  const [okDisabled, setOkDisabled] = useState(true)

  useEffect(() => {
    if ((senderStatus === "inprogress") || (receiverStatus === "inprogress") || (bankStatus === "inprogress") || (submissionStatus === "inprogress")) {
      setOkDisabled(true)
    } else {
      setOkDisabled(false)
    }
  }, [senderStatus, receiverStatus, bankStatus, submissionStatus])

  const handleOk = () => {
    if (submissionStatus === "done") {
      history.push('/transactionWizard')
    } else {
      closeTransactionSubmitter(false)
    }
  }

  useEffect(() => {
    console.log('menn transaction post karanoooo')
    setSenderStatus("inprogress")
    exhouse_api_createSender(data.transaction.id)
      .then(resCreateSender => {
        if ((resCreateSender.status === 200) && (resCreateSender.data.status === "success")) {
          setSenderStatus("done")
          setReceiverStatus("inprogress")
          exhouse_api_createReceiver(data.transaction.id)
            .then(resCreateReceiver => {
              if ((resCreateReceiver.status === 200) && (resCreateReceiver.data.status === "success")) {
                setReceiverStatus("done")
                setBankStatus("inprogress")
                exhouse_api_createReceiverBank(data.transaction.id)
                  .then(resCreateReceiverBank => {
                    if ((resCreateReceiverBank.status === 200) && (resCreateReceiverBank.data.status === "success")) {
                      setBankStatus("done")
                      setSubmissionStatus("inprogress")                      
                      exhouse_api_postTransaction(data.transaction.id)
                        .then(resCreateTransaction => {
                          if ((resCreateTransaction.status === 200) && (resCreateTransaction.data.status === "success")) {
                            setSubmissionStatus("done")
                            setInfoMessage(resCreateTransaction.data.data)
                          } else {
                            setSubmissionStatus("error")
                            setErrText(resCreateTransaction.data.message)
                          }
                        })
                        .catch(err => {
                          setSubmissionStatus("error")
                          setErrText(err.response.data.message)
                        })
                    } else {
                      setBankStatus("error")
                      setErrText(resCreateReceiverBank.data.message)
                    }
                  })
                  .catch(err => {
                    setBankStatus("error")
                    setErrText(err.response.data.message)
                  })
              } else {
                setReceiverStatus("error")
                setErrText(resCreateReceiver.data.message)
              }
            })
            .catch(err => {
              setReceiverStatus("error")
              setErrText(err.response.data.message)
            })
        } else {
          setSenderStatus("error")
          setErrText(resCreateSender.data.message)
        }
      })
      .catch(err => {
        setSenderStatus("error")
        setErrText(err.response.data.message)
      })
  }, [])

  const renderStatus = (status) => {
    switch (status) {
      case "pending":
        return <FontAwesomeIcon icon={faCircle} className="text-yellow-500  text-lg" />
      case "inprogress":
        return <FontAwesomeIcon icon={faSpinner} className="text-blue-500  text-lg spinner" />
      case "done":
        return <FontAwesomeIcon icon={faCircle} className="text-green-500  text-lg" />
      case "error":
        return <FontAwesomeIcon icon={faCircle} className="text-red-500  text-lg" />
      default:
        break;
    }
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="font-sans inline-block align-bottom bg-white rounded text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full">
          <div className="bg-white p-0 px-4a pt-5a pb-4a sm:p-6a sm:pb-4a">
            <div className="p-3 grid grid-cols-2 gap-2 font-inter text-sm text-gray-600">
              <div>Creating Sender...</div>
              <div className="text-right">{renderStatus(senderStatus)}</div>
              <div>Creating Receiver...</div>
              <div className="text-right">{renderStatus(receiverStatus)}</div>
              <div>Creating Receiver Bank...</div>
              <div className="text-right">{renderStatus(bankStatus)}</div>
              <div>Transaction Submission...</div>
              <div className="text-right">{renderStatus(submissionStatus)}</div>
            </div>
            <div className="bg-gray-50 px-3 mt-2 py-1 sm:flex sm:flex-row-reverse1 justify-between items-center">
              <span className={"text-xs text-red-500 font-inter " + (errText ? "" : "hidden")}>{errText}</span>
              <span className={"text-xs text-green-500 font-inter " + (infoMessage ? "" : "hidden")}>{infoMessage}</span>
              <span className={(errText || infoMessage) ? "hidden" : ""}></span>
              <button
                onClick={() => handleOk(false)}
                disabled={okDisabled}
                type="button"
                className={"inline-flex justify-center items-center rounded-md border border-transparent shadow-sm w-20 px-4 py-2 text-xs font-semibold focus:outline-none sm:ml-3 uppercase " + (okDisabled ? "border border-gray-200 bg-gradient-to-b from-gray-50 to-gray-100 text-gray-600 cursor-default" : "bg-blue-500 text-white hover:bg-blue-600")}
              >
                OK
              </button>
            </div>
            {/* <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:text-left font-publicSans w-full">
                
              </div>
            </div> */}
          </div>
          {/* <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={() => handleClickOk(isCash)}
              type="button" className="inline-flex justify-center items-center rounded-md border border-transparent shadow-sm w-20 px-4 py-2 bg-blue-500 text-white text-xs font-semibold hover:bg-blue-600 hover:shadow-md focus:outline-none sm:ml-3 uppercase ">              {
                "OK"
              }
            </button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default TransactionSubmitter