
// import countries from "../utils/countries"

import { useContext } from "react"
import GlobalVarContext from "../utils/GlobalVarContext"

export const CountryList = ({ selected, className }) => {
  let countries = useContext(GlobalVarContext).countries
  // let countries = JSON.parse(sessionStorage.getItem("countries"))
  if (!Array.isArray(countries)) {
    return ""
  } else if (countries.length === 0) {
    return ""
  } else {
    return countries.filter(item => item.enabled).map(item => {
      return <option key={item.code} className={className} value={item.code}>{item.name}</option>
    })
  }
}

export const getCountryName = (country_code) => {
  let countries = JSON.parse(sessionStorage.getItem("countries"))
  var country_name = ""
  if (!Array.isArray(countries)) {
    return ""
  }
  countries.forEach(element => {
    if (country_code === element.code) {
      country_name = element.name
    }
  })
  return country_name
}