import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import QuickReport from "../components/QuickReport"
import { report_utility_api_getMyStatement } from "../api/report_utility_api";
import { AlertBoxResult } from '../utils/alert_box';
import history from "../utils/history";
import { isNull } from "lodash";
import axios from "axios";


const AgentMyPayments = (props) => {
  const { agent_id } = props.match.params;
  const [data, setData] = useState()
  const [cashData, setCashData] = useState()
  const [bankData, setBankData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [pageNo, setPageNo] = useState(1)
  const [isShowCashSettlement, setShowCashSettlement] = useState(false)
  const [isShowBankSettlement, setShowBankSettlement] = useState(false)
  const [isCashTabShown, setCashTabShown] = useState(true)
  const [dialogData, setDialogData] = useState()
  const [date,setDate]=useState([])
  
  let formattedDateOne = null
  let formattedDateTwo = null
  
  useEffect(() => {
    window.addEventListener("load", sessionStorage.setItem('formattedDateTwo',''));
    refreshData(agent_id, pageNo)
  }, [])

  const fetchNextPage = async () => {
    let _pageNo = pageNo
    _pageNo++
    if( sessionStorage.getItem('formattedDateTwo')!=''){
      // alert(pageNo)
      const res = await axios.get(`/api/reportUtilities/getMyStatementFetch?page_no=${_pageNo}&agent_id=${agent_id}&start=${sessionStorage.getItem('formattedDateOne')}&end=${sessionStorage.getItem('formattedDateTwo')}`)
      setData(res.data.data)
      console.log(res.data.data)
      var cData = {
        "config" : res.data.data.config,
        "data" : {
        "detail" : res.data.data.data.detailCash },
       "count" : res.data.data.config.cashCount

     }
     setCashData(cData);
     var bData  = {
       "config" : res.data.data.config,
       "data" : {
       "detail" : res.data.data.data.detailBank},
      "count" : res.data.data.config.bankCount

     }
     setBankData(bData);
    console.log(res.data.data.data.detail)
    }else{
      refreshData(_pageNo, agent_id)
    }
    setPageNo(_pageNo)
  }
  
  const fetchPreviousPage = async () => {
    let _pageNo = pageNo
    if (_pageNo !== 1) {
      _pageNo--
      if( sessionStorage.getItem('formattedDateTwo')!=''){
        const res = await axios.get(`/api/reportUtilities/getMyStatementFetch?page_no=${_pageNo}&agent_id=${agent_id}&start=${sessionStorage.getItem('formattedDateOne')}&end=${sessionStorage.getItem('formattedDateTwo')}`)
        setData(res.data.data)
        var cData = {
          "config" : res.data.data.config,
          "data" : {
          "detail" : res.data.data.data.detailCash },
       "count" : res.data.data.config.cashCount

       }
       setCashData(cData);
       var bData  = {
         "config" : res.data.data.config,
         "data" : {
         "detail" : res.data.data.data.detailBank},
      "count" : res.data.data.config.bankCount

       }
       setBankData(bData);
      console.log(res.data.data.data.detail)
      }else{
        refreshData(_pageNo, agent_id)
      }
      setPageNo(_pageNo)
    }
  }

  const refreshData = async (pageNo) => {
    sessionStorage.setItem('page',pageNo)
    sessionStorage.setItem('formattedDateTwo','')
    setIsLoading(true)
    let _res = await report_utility_api_getMyStatement(pageNo)
    //console.log(_res.data.data);

    setData(_res.data.data)
    setIsLoading(false)
    var cData = {
       "config" : _res.data.data.config,
       "data" : {
       "detail" : _res.data.data.data.detailCash },
       "count" : _res.data.data.config.cashCount
    }
    setCashData(cData)
    var bData  = {
      "config" : _res.data.data.config,
      "data" : {
      "detail" : _res.data.data.data.detailBank},
      "count" : _res.data.data.config.bankCount
    }
    setBankData(bData)

    console.log(_res.data.data)

  }

  const search = async (searchText) => {
    //setIsLoading(true)
   // let _res = await report_utility_api_get_pending_transactions(1, searchText)
    //setData(_res.data.data)
   // setIsLoading(false)
  }

  const setCashSettlementDialogResult = (result) => {
    if (result === AlertBoxResult.MB_RESULT_YES) {
      setShowCashSettlement(false)
      history.go(0)
    } else if (result === AlertBoxResult.MB_RESULT_CANCEL) {
      setShowCashSettlement(false)
    }
  }

  const setBankSettlementDialogResult = (result) => {
    if (result === AlertBoxResult.MB_RESULT_YES) {
      setShowBankSettlement(false)
      history.go(0)
    } else if (result === AlertBoxResult.MB_RESULT_CANCEL) {
      setShowBankSettlement(false)
    }
  }

  const showCashSettlementDlg = () => {
    setDialogData({
      agent_id: agent_id,
      amount_to_receive_usd: data.data.payment.usd_cash_balance,
      amount_to_receive_ils: data.data.payment.ils_cash_balance,
      commission_to_be_paid: data.data.payment.commission_cash
    })
    setShowCashSettlement(true)
  }

  const showBankSettlementDlg = () => {
    setDialogData({
      agent_id: agent_id,
      amount_to_receive_usd: data.data.payment.usd_bank_balance,
      amount_to_receive_ils: data.data.payment.ils_bank_balance,
      commission_to_be_paid: data.data.payment.commission_bank
      
    })
    setShowBankSettlement(true)
  }

  const handleTabs = (e) => {
    if(e.target.name === "cashTab"){
      setCashTabShown(true)
    }
    else{
      setCashTabShown(false)
    }

  }
  const formatNumber = (number) => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    if (!(isNull(number))) {
      const formatted = Number(number).toLocaleString('en', options);
      return formatted;
    }
    else {
      return number;
    }
  }
  const handleDateSubmit = async () => {
    const dateone = new Date(date[0]);
    const datetwo = new Date(date[1]);
     formattedDateOne = `${dateone.getFullYear()}-${(dateone.getMonth() + 1).toString().padStart(2, '0')}-${dateone.getDate().toString().padStart(2, '0')}`;
     formattedDateTwo = `${datetwo.getFullYear()}-${(datetwo.getMonth() + 1).toString().padStart(2, '0')}-${datetwo.getDate().toString().padStart(2, '0')}`;
    // console.log({formattedDateOne,formattedDateTwo})
    sessionStorage.setItem('formattedDateTwo',formattedDateTwo)
    sessionStorage.setItem('formattedDateOne',formattedDateOne)

    try {
        const res = await axios.get(`/api/reportUtilities/getMyStatementFetch?page_no=${pageNo}&start=${formattedDateOne}&end=${formattedDateTwo}`)
        setData(res.data.data)

        var cData = {
          "config" : res.data.data.config,
          "data" : {
          "detail" : res.data.data.data.detailCash },
          "count" : res.data.data.config.cashCount 
       }
       setCashData(cData);
       var bData  = {
         "config" : res.data.data.config,
         "data" : {
         "detail" : res.data.data.data.detailBank},
         "count" : res.data.data.config.bankCount 
       }
       setBankData(bData);
        
      console.log(res.data)
      
      // console.log(res.data.data.data)

    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <Helmet>
        <title>Agent Account</title>
      </Helmet>
      <div className={"max-w-7xl mx-auto px-4 mt-1"}>
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-12 py-1 rounded px-2 mt-2 mb-1">
            <div className="font-sora text-base font-semibold text-gray-500"> Account Statement &nbsp; - &nbsp;&nbsp; {(typeof data === 'undefined') ? '' : `${data.data.agent.first_name} ${data.data.agent.last_name}`}</div>
          </div>
          <div className="col-span-12 rounded-md font-inter">
            <div className="grid grid-cols-12 items-center ">
              <div className="col-span-6 rounded-md font-sora">
                <div className="mt-2">
                  <div className="mt-1 rounded border-t-4 border-blue-400">
                    <table className="table-fixed w-full font-sora">
                      <thead>
                        <tr className="border text-xs shadow bg-gray-100 border-l-2 border-r-2">
                          <th className="w-12 border text-gray-600 py-2">Type</th>
                          <th className="w-12 border text-gray-600 py-2">USD to Pay</th>
                          <th className="w-12 border text-gray-600 py-2">ILS to Pay</th>
                          <th className="w-16 border text-gray-600 py-2">Commission to Receive (USD) </th>                          
                        </tr>
                      </thead>
                      <tbody className=" text-sm">      
                        <tr className="text-gray-700  text-xs border-l-2 border-r-2 hover:bg-gray-50 hover:text-blue-800 hover:shadow hover:border-blue-400">
                          <td className="px-1 border text-center font-bold py-2">CASH</td>
                          {/* <td className="px-1 border text-left">{(typeof data === 'undefined') ? '' : data.data.payment.full_usd_cash}</td> */}
                          <td className="px-1 border text-left ">{(typeof data === 'undefined') ? '' : formatNumber(data.data.payment.usd_cash_balance)}</td>
                          <td className="px-1 border text-right">{(typeof data === 'undefined') ? '' : formatNumber(data.data.payment.ils_cash_balance)}</td>
                          <td className="px-1 border text-right">{(typeof data === 'undefined') ? '' : formatNumber(data.data.payment.commission_cash)}</td>
                        </tr>
                        <tr className="text-gray-700 text-xs border-l-2 border-r-2 hover:bg-gray-50 hover:text-blue-800 hover:shadow hover:border-blue-400">
                          <td className="px-1 border text-center font-bold py-2">BANK</td>
                          {/* <td className="px-1 border text-left">{(typeof data === 'undefined') ? '' : data.data.payment.full_usd_bank}</td> */}
                          <td className="px-1 border text-left">{(typeof data === 'undefined') ? '' : formatNumber(data.data.payment.usd_bank_balance)}</td>
                          <td className="px-1 border text-right">{(typeof data === 'undefined') ? '' : formatNumber(data.data.payment.ils_bank_balance)}</td>
                          <td className="px-1 border text-right">{(typeof data === 'undefined') ? '' : formatNumber(data.data.payment.commission_bank)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="text-xs text-red-400 pt-3"> *Commissions will not be calculated for Unposted transactions</p>
                  </div>
                </div>
              </div>
            </div>
        </div>
         
        </div>
        <div className="grid grid-cols-12 gap-2 mt-10">
          <div className="col-span-4 px-3" >
              <button
              onClick={e => handleTabs(e)}
              name="cashTab"
              className={"inline-block px-3 mb-2 mr-2  h-10 w-30 rounded-md font-sora text-sm " + ((isCashTabShown == true) ?  
              "text-white bg-blue-500  : focus:outline-none" : 
              "text-gray-500 bg-gray-50 hover:bg-gray-100  : focus:outline-none")} >CASH Transactions</button>

            <button
              onClick={e => handleTabs(e)}
              name="bankTab"
              className={"inline-block px-3 mb-2 h-10 w-30 rounded-md font-sora text-sm " + ((!isCashTabShown == true) ?  
              "text-white bg-blue-500  : focus:outline-none" : 
              "text-gray-500 bg-gray-50 hover:bg-gray-100  : focus:outline-none")} >BANK Transactions</button>
          </div>
        </div>
        {
        isCashTabShown &&
        <div className="border-2 border-blue-75 rounded-md">
          <QuickReport
            data={cashData}
            handleDateSubmit={handleDateSubmit}
            setDate={setDate}
            refreshData={refreshData}
            rowAction={()=>{
            }}
            search={search}
            fetchPreviousPage={fetchPreviousPage}
            fetchNextPage={fetchNextPage}
            currentPageNo={pageNo}
            isLoading={isLoading}
            source="AgentStatement"
          />
        </div>
        }
        { !isCashTabShown &&
        <div className="bg-gray-50 border-2 rounded-md">
          <QuickReport
            data={bankData}
            handleDateSubmit={handleDateSubmit}
            setDate={setDate}
            refreshData={refreshData}
            rowAction={()=>{
            }}
            search={search}
            fetchPreviousPage={fetchPreviousPage}
            fetchNextPage={fetchNextPage}
            currentPageNo={pageNo}
            isLoading={isLoading}
            source="AgentStatement"
          />  
        </div>
        }
      </div>
    </>
  )
}

export default AgentMyPayments