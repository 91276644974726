import api from "../utils/api"


export const report_utility_api_get_pending_transactions = async (page_no, search = null) => {
  return await api().get(`/api/reportUtilities/getPendingTransactions`, { params: { page_no: page_no, search: search } })
}

export const report_utility_api_get_posted_transactions_cash = async (page_no, search = null) => {
  return await api().get(`/api/reportUtilities/getPostedTransactionsCash`, { params: { page_no: page_no, search: search } })
}

export const report_utility_api_get_posted_transactions_bank = async (page_no, search = null) => {
  return await api().get(`/api/reportUtilities/getPostedTransactionsBank`, { params: { page_no: page_no, search: search } })
}


export const report_utility_api_get_completed_transactions = async (page_no, search = null) => {
  return await api().get(`/api/reportUtilities/getCompletedTransactions`, { params: { page_no: page_no, search: search } })
}

export const report_utility_api_get_agent_transactions_bank = async (page_no, search = null) => {
  return await api().get(`/api/reportUtilities/getAgentTransactionsBank`, { params: { page_no: page_no, search: search } })
}

export const report_utility_api_get_agent_transactions_cash = async (page_no, search = null) => {
  return await api().get(`/api/reportUtilities/getAgentTransactionsCash`, { params: { page_no: page_no, search: search } })
}

export const report_utility_api_getAgentTxSummary = async (page_no, search_text) => {
  var ret = {}

  await api().get("/api/reportUtilities/getAgentTxSummary", { params: { page_no: page_no, query: search_text } })
    .then((response) => {
      switch (response.data.status) {
        case "success":
          ret = { status: "OK", data: response.data.data, code: "", message: "Successfully submitted." }
          break;
        case "error":
          ret = { status: "ERR", data: "", code: "", message: response.data.message }
          break;

        default:
          break;
      }
    })
    .catch(err => {
      if (err.response) {
        ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
      } else if (err.request) {
        ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
      } else {
        ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
      }
    });

  return ret
}

export const report_utility_api_getAgentStatement = async (page_no, agent_id) => {
  return await api().get(`/api/reportUtilities/getAgentStatement`, { params: {page_no: page_no, agent_id: agent_id } })
}

export const report_utility_api_getMyStatement = async (page_no) => {
  return await api().get(`/api/reportUtilities/getMyStatement`, { params: {page_no: page_no} })
}

export const report_utility_api_getUnigirosTransactions = async (page_no, search = null) => {
  return await api().get(`/api/reportUtilities/getUnigirosTransactions`, { params: { page_no: page_no, search: search } })
}

export const report_utility_api_getDashboardBank = async () => {
  return await api().get(`/api/reportUtilities/getDashboardBank`, { })
}
