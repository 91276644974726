
import currencies from "../utils/currencies"

const CurrencyList = ({ selected, hide }) => {
  return currencies.filter(item => {
    if (Array.isArray(hide)) {
      return (item.enabled && (!hide.includes(item.code)))
    }
    return item.enabled
  }).map(item => {
    return <option  key={item.code}>{item.code}</option>
  })
}

export default CurrencyList