const TxDetail = ({ data, readOnly }) => {
  return (
    <div>
      <table className="table-fixed text-sm">
        <thead>
          <tr className="bg-transparent text-gray-600 text-xs">
            <th className=" w-1/12 border-b border-gray-200  pl-2 text-left pb-1">Currency</th>
            <th className=" w-3/12 border-b border-gray-200  pr-1 text-right pb-2">Amount</th>
            <th className=" w-3/12 border-b border-gray-200  pr-5 text-right pb-1">Rate</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((rec, index) => {
              return <TxDetailRow key={index} data={rec}  readOnly={readOnly} />
            })
          }
        </tbody>
      </table>
    </div>
  )
}

const TxDetailRow = ({ data, readOnly }) => {

  return (
    <tr>
      <td className="borderr-b borderr-r border-gray-50 ">
        <input
          name="currency_code"
          value={data.currency_code}
          className={" focus:outline-none  bg-transparent  text-left px-2 w-full " + (readOnly ? "text-gray-800" : "text-blue-400")}
          disabled={readOnly}
        />

      </td>
      <td className="borderr-b borderr-r border-gray-50">
        <input name="amount" type="text"
          value={data.amount}
          disabled={readOnly}
          className={"w-full bg-transparent h-7 px-1 text-sm  text-right focus:outline-none " + (readOnly ? "text-gray-500" : "text-blue-400")} />
      </td>
      <td className="borderr-b borderr-r border-gray-50 text-right">
        {
          (data.rate !== "loading") &&
          <input name="rate" type="number"
            value={data.rate}
            readOnly
            className="w-full h-7 text-sm text-gray-500  bg-transparent text-right focus:outline-none" />
        }
      </td>
    </tr>
  )
}

export default TxDetail