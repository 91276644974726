
// import banks from "../utils/banks"

import { useContext } from "react"
import GlobalVarContext from "../utils/GlobalVarContext"


export const BankList =  ({country_code}) => {
  let banks = useContext(GlobalVarContext).banks
  let country_id
  if(country_code == "LK") {
    country_id=204
  }else if (country_code == "IL") {
    country_id=107
  }
 
  if (!Array.isArray(banks)) {
    return ""
  } else if (banks.length === 0) {
    return ""
  } else {
    return banks.filter(item => item.enabled && item.country_id==country_id).map(item => {
      return <option key={item.code} value={item.code} className="">{item.name}</option>
    })
  }
}


export const getBankName = (code) => {
  let banks = JSON.parse(sessionStorage.getItem("banks"))
  var bank_name = ""
  if (!Array.isArray(banks)) {
    return ""
  } else if (banks.length === 0) {
    return ""
  } else {
    if (code !== "") {
      banks.forEach(element => {
        if (code === element.code) {
          bank_name = element.name
        }
      })
    }
  }
  return bank_name
}
