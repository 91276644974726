
const DeletePopup = ({popupClose , handlePopupDelete}) => {
    return (
    <div className="fixed inset-0 z-50 grid place-items-center bg-slate-900 bg-opacity-10">
    <div className="py-6 px-4 flex gap-6 flex-col relative bg-white shadow-md rounded-md w-88">
            <p className="text-center">Are you sure you want to delete this item?</p>
            <div className="text-center space-x-4">
                <button className="bg-red-400 p-1 border-2 border-red-600" onClick={handlePopupDelete}>Delete</button>
                <button className="bg-gray-100 p-1 border-2 border-gray-300" onClick={popupClose}>Close</button>
            </div>
    </div>
</div>
    )
}

export default DeletePopup;