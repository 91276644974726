import React, { useEffect, useState, useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
// import Layout from "../components/Layout"
import { Helmet } from 'react-helmet-async';
import { auth_api_login, auth_api_get_user, auth_api_set_csrf_cookie } from "../api/auth_api"
// import api from "../utils/api"
import history from "../utils/history"
import { AuthContext } from "../utils/AuthContext";
import { useLocation } from "react-router";

const Login = () => {
  const [formInput, setFormInput] = useState({ email: '', password: '' })
  const [authErr, setAuthErr] = useState(false)
  const [err, setErr] = useState()
  const [isChecked, setIsChecked] = useState(false)
  const [loginEnabled, setLoginEnabled] = useState(false)
  const auth = useContext(AuthContext)
  const location = useLocation()

  let { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    if (!auth.isCsrfOk) {
      setErr(auth.authErr)
      setLoginEnabled(false)
    }
  }, [auth])

  const updateFormInput = e => {
    e.persist()

    setFormInput(prevState => ({ ...prevState, [e.target.name]: e.target.value }))

    setErr()
  }

  const signIn = async (e) => {
    e.preventDefault()
    setLoginEnabled(false)

    setAuthErr(false)
    if (auth.isCsrfOk) {
      auth_api_login(formInput)
        .then(res => {
          if (res.status === "OK") {
            auth_api_get_user()
              .then(user => {
                if (user.status === "OK") {
                  auth.setIsAuthed(true)
                  auth.setUser(user.data)
                  // history.replace(from);
                  history.push("/")
                } else {
                  setErr(res.message)
                  setAuthErr(true)
                  setLoginEnabled(true)
                }
              })
          } else {
            setErr(res.message)
            setAuthErr(true)
            setLoginEnabled(true)
          }
        })
    }
  }

  const handleCheckBoxChange = (e) => {
    setIsChecked(!isChecked)
  }

  useEffect(() => {
    setLoginEnabled(isChecked && auth.isCsrfOk)
  }, [isChecked])

  return (
    <>
      <Helmet>
        <title>{`Log in - ${process.env.REACT_APP_NAME}`}</title>
        <body class="watermark" />
      </Helmet>
      <div className="w-full h-screen flex justify-between items-center px-20" >
        {/* <div className="w-20 hidden sm:block"></div> */}
        <div className="text-center h-full hidden sm:block py-10 rounded-md bg-white1 bg-opacity-801 border1 border-gray-300 p-3 shadow-md">

        </div>
        <div className="p-3 border bg-white bg-opacity-80 border-21 border-gray-300 rounded-md shadow-md">
          <div className="text-2xl text-center text-steel-400 font-semibold font-inter mb-4">Services Login</div>
          <div className="w-50">
            <label className="block text-gray-600 text-sm font-inter">Email</label>
            <input aria-label="Email address" name="email" type="email" required
              onChange={updateFormInput}
              className="appearance-none w-full h-7 px-1 border rounded border-gray-300 text-gray-900 focus:outline-none sm:text-sm sm:leading-5" />
          </div>
          <div className="w-50 mt-6">
            <label className="block text-gray-600 text-sm font-inter">Password</label>
            <input aria-label="Password" name="password" type="password" required
              onChange={updateFormInput}
              className="appearance-none w-full h-7 px-1 border rounded border-gray-300 text-gray-900 focus:outline-none sm:text-sm sm:leading-5" />
          </div>
          {
            (err) &&
            <div className="mt-6 rounded p-1 text-red-300 text-sm">
              {err}
            </div>
          }
          <div className="mt-6 w-50">
            <div className="flex items-center text-gray-600 text-xs">
              <input className="mr-3" type="checkbox" id="conditions" name="conditions" checked={isChecked} onChange={(e) => handleCheckBoxChange(e)} />
              <label htmlFor="conditions" className="font-inter">I Agree to the Terms and Conditions</label>
            </div>
            <div className="w-full flex justify-end mt-3">
              <button
                type="button"
                onClick={(e) => signIn(e)}
                className={"mt-2 text-xs font-semibold rounded px-4 py-2 uppercase font-inter " + (loginEnabled ? "text-white bg-blue-600 hover:bg-blue-800 hover:shadow-md" : "text-gray-500 bg-gray-300 cursor-default")}
                disabled={!loginEnabled}
              >
                {
                  (typeof auth.isCsrfOk === 'undefined') &&
                  <FontAwesomeIcon icon={faSpinner} className="spinner" />
                }
                {
                  (typeof auth.isCsrfOk !== 'undefined') &&
                  <span>Sign In</span>
                }
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login