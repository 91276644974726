
import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPrint, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import TransactionWizardStepOne from './TransactionWizardStepOne';
import TransactionWizardStepTwo from './TransactionWizardStepTwo';
import TransactionWizardStepThree from './TransactionWizardStepThree';
import SenderDlg from '../components/Sender/SenderDlg';
import ReceiverDlg from '../components/Receiver/ReceiverDlg';
import { transaction_api_submit } from "../api/transaction_api"
import { auth_api_login, auth_api_isAlive, auth_api_setCsrfCookie } from "../api/auth_api"
import SearchBox from '../components/SearchBox';
import StepLabel from '../components/StepLabel';
import WizardControlButtons from '../components/WizardControlButtons';
import history from "../utils/history"
import { fetchResponseMessages } from "../utils/err_handler"
import TransactionOwnerSelector from '../components/TransactionOwnerSelector';
import { AlertBoxResult } from '../utils/alert_box';
import AlertBox from '../components/AlertBox/AlertBox';
import PrintPreviewer from '../components/PrintPreviewer/PrintPreviewer';
import TransactionSubmitter from '../components/TransactionSubmitter'

const TransactionWizard = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [searchSenderText, setSearchSenderText] = useState("")
  const [searchReceiverText, setSearchReceiverText] = useState("")
  const [senderDlgProps, setSenderDlgProps] = useState({ mode: "", show: "false" })
  const [receiverDlgProps, setReceiverDlgProps] = useState({ mode: "", show: "false" })
  const [stepOneReceivedData, setStepOneReceivedData] = useState()
  const [stepTwoReceivedData, setStepTwoReceivedData] = useState()
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false)
  const [isPreviousButtonEnabled, setIsPreviousButtonEnabled] = useState(true)
  const [isSavingInProgress, setSavingInProgress] = useState(false)
  const [isAlertShown, setIsAlertShown] = useState(false)
  const [apiResponse, setApiResponse] = useState({ status: "", type: "", data: "", code: "", message: "" })
  const [transactionOwner, setTransactionOwner] = useState()
  const [isWizardCloseAlertShown, setIsWizardCloseAlertShown] = useState(false)
  const [printParams, setPrintParams] = useState({})
  const [showPrintPreview, setShowPrintPreview] = useState(false)
  const [hasValidationError, setHasValidationError] = useState(false)
  const [validationError, setValidationError] = useState("")
  const [exchangeRates, setExchangeRates] = useState()
  const [show,setShow] =useState (false)
  const [clickShow,setClickShow] =useState(false)


  const emptyStepOneData = {
    sender: {
      id: "",
      code: "",
      status: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      gender: "",
      dob: "",
      mobile_no: "",
      home_no: "",
      email: "",
      street_address: "",
      city: "",
      country_code: "IL",
      postal_code: "",
      id_type: "",
      id_number: "",
      id_expiry: "",
      kyc_file: "",
      risk_score: 0,
      compliance_score: 0,
      declaration_file: "",
      documents: [],
      created_at: "",
      updated_at: ""
    }
  };

  const emptyStepTwoData = {
    receiver: {
      id: "",
      sender_id: "",
      code: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      gender: "",
      dob: "",
      phone_no: "",
      street_address: "",
      city: "",
      country_code: "LK",
      postal_code: "",
      account_no: "",
      bank: "",
      branch: "",
      relationship_to_sender: "",
      documents: [],
      payment_method: "",
      currency_code: "",
      rate: "",
      amount: "",
      transaction_id: "",
      created_at: "",
      updated_at: ""
    },
    transaction: {
      id: "",
      tx_date: new Date(),
      usd_to_pay: 0,
      discount: 0,
      fees: 0,
      usd_to_send: 0,
      out_currency_code: "",
      out_amount: 0,
      out_rate: 0,
      out_transfer_amount: 0,
      out_transfer_rate: 0,
      tx_notes: "",
      tx_status: "",
      tx_creator_id: "",
      tx_owner_id: "",
      sender_id: "",
      receiver_id: "",
      transfer_type_id: 1,
      usd_balance: 0,
      documents: [],
      created_at: "",
      updated_at: ""

    },
    tx_details: [

    ]
  };

  const emptyStepThreeData = {
    exchange_house_id: ""
  }

  useEffect(() => {
    if (typeof transactionOwner !== 'undefined') {
      setData({ ...data, exchange_house_id: transactionOwner.id })
    }
  }, [transactionOwner])

  const [data, setData] = useState({ ...emptyStepOneData, ...emptyStepTwoData, ...emptyStepThreeData })

  const updateData = (section, rec) => {
    setData({
      ...data, [section]: rec
    })
  }

  const updateDataItem = (section, column, value) => {
    setData({
      ...data, [section]: { ...data[section], [column]: value }
    })
  }

  const handleSearchSender = (val) => {
    // e.persist();
    setSearchSenderText(val)
  }

  const handleSearchReceiver = (val) => {
    // e.persist();
    setSearchReceiverText(val)
  }

  const prevStep = () => {
    if (currentStep !== 1) {
      setSearchSenderText("")
      setCurrentStep(currentStep - 1)
    }
  }

  const nextStep = () => {
    var ret = false
    if (currentStep !== 3) {
      switch (currentStep) {
        case 1:
          ret = validateStepOne()
          break;
        case 2:
          ret = validateStepTwo()
          break;

        default:
          break;
      }
      if (ret) {
        setValidationError("")
        setHasValidationError(false)
        setSearchSenderText("")
        setCurrentStep(currentStep + 1)
      }
    }
  }

  const validateStepOne = () => {
    const today = new Date
    if (Date.parse(data.sender.id_expiry) < Date.parse(today)) {
      setValidationError("Sender's ID has expired")
      setHasValidationError(true)
      return false
    }
    return true

  }

  const validateStepTwo = () => {
    if (data.transaction.usd_balance !== 0) {
      setValidationError("Incorrect USD Balance. Please check the values.")
      setHasValidationError(true)
      return false
    }
    if (data.transaction.out_amount === 0) {
      setValidationError("Incorrect Amount to Receive. Please check.")
      setHasValidationError(true)
      return false
    }
    return true
  }

  const submit = async () => {
    // setShowTransactionSubmitter(true)
    setSavingInProgress(true)
    console.log(data)
    setApiResponse(await transaction_api_submit(data, exchangeRates, transactionOwner))
    setSavingInProgress(false)
  }

  useEffect(() => {
    if (apiResponse.status === "ERR") {
      setIsAlertShown(true)
    } else if (apiResponse.status === "OK") {
      history.push(`/viewTransaction/${apiResponse.data.id}`)
    }
  }, [apiResponse])

  const showAddSenderPopup = (val) => {
    if (val) {
      setSenderDlgProps({ mode: "add", show: "true" })
    } else {
      setSenderDlgProps({ mode: "", show: "false" })
    }
  }

  const showEditSenderPopup = (val) => {
    if (val) {
      setSenderDlgProps({ mode: "edit", show: "true" })
    } else {
      setSenderDlgProps({ mode: "", show: "false" })
    }
  }

  const showAddReceiverPopup = (val) => {
    if (val) {
      setReceiverDlgProps({ mode: "add", show: "true" })
    } else {
      setReceiverDlgProps({ mode: "", show: "false" })
    }
  }

  const showEditReceiverPopup = (val) => {
    if (val) {
      setReceiverDlgProps({ mode: "edit", show: "true" })
    } else {
      setReceiverDlgProps({ mode: "", show: "false" })
    }
  }

  useEffect(() => {
    var val = false
    switch (currentStep) {
      case 1:
        // setIsNextButtonEnabled((data.sender.id !== "") && (data.sender.documents.length > 0))
        setIsNextButtonEnabled((data.sender.id !== ""))
        setIsPreviousButtonEnabled(false)
        break;
      case 2:
        setIsNextButtonEnabled((data.receiver.id !== "") && (parseFloat(data.transaction.out_amount) > 0) && (data.transaction.usd_to_send !== ""))
        setIsPreviousButtonEnabled(true)
        break;
      case 3:
        if (apiResponse.status === "OK") {
          setIsPreviousButtonEnabled(false)
        } else {
          setIsPreviousButtonEnabled(true)
        }
        setIsNextButtonEnabled(false)
        break;

      default:
        break;
    }
  }, [currentStep, data, apiResponse.status])

  const closeTransactionOwnerModal = () => {
    history.push('/')
  }

  const setWizardCloseDlgResult = (result) => {

    if (result === AlertBoxResult.MB_RESULT_YES) {
      setIsWizardCloseAlertShown(false)
      history.push("/")
    } else if (result === AlertBoxResult.MB_RESULT_NO) {
      setIsWizardCloseAlertShown(false)
    }
  }

  const setTransOwner = (val) => {
    setTransactionOwner(val)
  }

  // task010 & task013
  window.addEventListener('click',()=>{
    if (clickShow){
     setShow(true)
     setClickShow(false)
   }
   else{
     setShow(false)

   }
   })
  // end
  if (typeof transactionOwner === 'undefined') {
    return (
      <TransactionOwnerSelector
        setTransactionOwner={setTransOwner}
        closeTransactionOwnerModal={closeTransactionOwnerModal}
        setExchangeRates={setExchangeRates}
        data={data}
        updateData={updateData}
      />
    )
  } else {
    return (
      <>
        <Helmet>
          <title>Transaction Wizard</title>
        </Helmet>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-3 font-publicSans">
          <div className="grid grid-cols-12 items-baseline mt-6">
            <div className="col-span-2">
              <h2 className="font-publicSans text-blue-500 text-lg font-semibold mb-3 text-left">{"Transaction - " + transactionOwner.description}</h2>
            </div>
            <div className="col-span-3"></div>
            <div className="col-span-3"></div>

          </div>
          <div className="grid grid-cols-12 items-baseline mt-4">
            {/* task010 and task013 start */}
            {
              (currentStep === 1) &&
              <SearchBox
                searchText={searchSenderText==""?"  ": searchSenderText}
                // searchText={searchSenderText}
                handleSearchTextChange={handleSearchSender}
                showEditDlg={showEditSenderPopup}
                showAddDlg={showAddSenderPopup}
                stepNumber={1}
                updateData={updateData}
                showEditButton={data.sender.id !== ""}
                showAddButton={true}
                section="sender"
                api="sender"
                displayColumns="full_name,id_number,mobile_no"
                click={()=>setClickShow(true)}
                show={show}
              />
            }
            {

              (currentStep === 2) &&
              <SearchBox
              searchText={searchReceiverText==""?"  ": searchReceiverText}
                // searchText={searchReceiverText}
                handleSearchTextChange={handleSearchReceiver}
                showEditDlg={showEditReceiverPopup}
                showAddDlg={showAddReceiverPopup}
                stepNumber={2}
                updateData={updateData}
                showEditButton={data.receiver.id !== ""}
                showAddButton={true}
                section="receiver"
                api="receiver"
                displayColumns="full_name,bank_name,account_no"
                additionalParameters={data.sender.id}
                click={()=>setClickShow(true)}
                show={show}
              />
            }
            {/* end */}
            {
              (![1, 2].includes(currentStep)) &&
              <div className="col-span-4"></div>
            }
            <div className="flex text-sm justify-center mt-6a mb-3 items-baseline col-span-4">
              <WizardControlButtons
                currentStep={currentStep}
                handlePrevStep={prevStep}
                handleNextStep={nextStep}
                isNextButtonEnabled={isNextButtonEnabled}
                isPreviousButtonEnabled={isPreviousButtonEnabled}
              />
            </div>
            <div className="flex justify-end col-span-4">
            {
              (currentStep === 3) && (apiResponse.status !== "OK") &&
              <div className="col-span-2 flex justify-end">
                <button
                  className={"mr-2 text-xs font-semibold rounded-full border border-red-700 py-1 uppercase " + (isSavingInProgress ? "text-gray-400 cursor-default" : "bg-gradient-to-b from-red-500 to-red-600 text-white focus:outline-none hover:shadow-md hover:bg-red-7001 hover:from-red-600 hover:to-red-700")}
                  onClick={() => submit()}
                  disabled={isSavingInProgress}
                >
                <div className="flex mx-2 justify-between items-center">
                  <div>Save</div>
                  {
                    isSavingInProgress &&
                    <FontAwesomeIcon icon={faSpinner} className="spinner ml-2" />
                  }
                  {
                    !isSavingInProgress &&
                    <FontAwesomeIcon icon={faCheck} className={"ml-2 text-white"} />
                  }
                </div>
                </button>
              </div>

            }
              <div className="bg-transparent  rounded-full border hover:from-red-200 hover:bg-white px-3 py-1 border-gray-400 text-red-600"
                 onClick={() => setIsWizardCloseAlertShown(true)} >
                  <FontAwesomeIcon icon={faTimes} className=" text-red-500" />

              </div>
            </div>
            {
              hasValidationError &&
              <div className="col-span-12 flex justify-end text-red-600">
                {
                  validationError
                }
              </div>
            }
            {
              (currentStep === 3) && ((apiResponse.status === "OK") || (typeof apiResponse.status === 'undefined')) &&
              <div className="col-span-4 flex justify-end">
                {
                  isAlertShown &&
                  <div className={"flex w-full justify-between items-center rounded px-3 h-7  text-sm font-semibold bg-" + (apiResponse.status === "ERR" ? "red" : "green") + "-100 text-" + (apiResponse.status === "ERR" ? "red" : "green") + "-500"}>
                    <span>{fetchResponseMessages(apiResponse.message)}</span>
                  </div>
                }
              </div>
            }
            {
              (currentStep !== 3) &&
              <div className="col-span-4"></div>
            }
          </div>
          <hr className="border-blue-400 shadow border-b" />
          {
            (currentStep === 1) &&
            <TransactionWizardStepOne data={data} updateDataItem={updateDataItem} showAddSenderPopup={showAddSenderPopup} />
          }
          {
            (currentStep === 2) &&
            <TransactionWizardStepTwo data={data} updateDataItem={updateDataItem} updateData={updateData} showAddReceiverPopup={showAddReceiverPopup} transactionOwner={transactionOwner} exchangeRates={exchangeRates} setExchangeRatesInParent={setExchangeRates} />
          }
          {
            (currentStep === 3) &&
            <TransactionWizardStepThree data={data} />
          }
        </div>
        {
          (senderDlgProps.show === "true") &&
          <SenderDlg emptyDataObject={emptyStepOneData} showDlg={showAddSenderPopup} senderDlgProps={senderDlgProps} data={data} updateData={updateData} />
        }
        {
          (receiverDlgProps.show === "true") &&
          <ReceiverDlg emptyDataObject={emptyStepTwoData} showDlg={showAddReceiverPopup} receiverDlgProps={receiverDlgProps} data={data} updateData={updateData} />
        }
        {
          isWizardCloseAlertShown &&
          <AlertBox type="" text="Do you want to exit the wizard?" setResult={setWizardCloseDlgResult} />
        }
        {/* <div className="grid grid-cols-2">
          <pre>{JSON.stringify(data, null, 4)}</pre>
          <pre>{JSON.stringify(exchangeRates, null, 4)}</pre>
        </div> */}
      </>
    )
  }
}

export default TransactionWizard
