import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faFileUpload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { file_uploader_api_upload } from "../../api/file_uploader_api"

const DocumentUploader = ({ setShowFileSelectorDlg, setAttachedDocuments }) => {
  const [isUploading, setIsUploading] = useState(false)
  const [attachment, setAttachment] = useState({ document: "", title: "" })
  const [apiResponse, setApiResponse] = useState({ status: "", type: "", data: "", code: "", message: "" })

  const handleUpload = async () => {
    setIsUploading(true)
    setApiResponse(await file_uploader_api_upload(attachment))
    setIsUploading(false)
    setShowFileSelectorDlg(false)
  }

  useEffect(() => {
    if (apiResponse.status === "OK") {
      setAttachedDocuments(apiResponse.data)
    }
  }, [apiResponse])

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-full" aria-hidden="true">&#8203;</span>
        <div className="font-sans inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className={"mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10"}>
                <FontAwesomeIcon icon={faFileUpload} className={"text-blue-600"} />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left font-publicSans w-full">
                <div className="flex justify-between py-2">
                  <h3 className="flex text-lg leading-6 font-semibold items-center text-gray-700 mr-3 h-7" id="modal-title">
                    Document Uploader
                  </h3>
                  <button
                    onClick={() => setShowFileSelectorDlg(false)}
                    className="flex justify-center h-7 w-7 border border-gray-400 rounded-full -mt-4 -mr-2"
                  >
                    <FontAwesomeIcon icon={faTimes} className={"text-blue-500 text-lg mt-1"} />
                  </button>
                </div>
                <hr className="border-b-1 border-gray-300" />
                <div className="mt-2">
                  <input type="file" name="document" accept=".pdf,.png,.jpg,.jpeg" className="h-7 text-sm w-full border rounded" onChange={(e) => setAttachment({ ...attachment, document: e.target.files[0] })} />
                </div>
                <div className="mt-3">
                  <label className="text-sm font-semibold text-gray-700">Document Title</label>
                  <input className="h-7 text-sm w-full border rounded outline-none px-2 font-semibold text-gray-500" type="text" name="title" value={attachment.title} onChange={(e) => setAttachment({ ...attachment, title: e.target.value })} />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button onClick={() => handleUpload()} type="button" className="inline-flex justify-center items-center rounded-md border border-transparent shadow-sm w-20 px-4 py-2 bg-blue-500 text-white text-xs font-semibold hover:bg-blue-600 hover:shadow-md focus:outline-none sm:ml-3 uppercase ">
              {
                !isUploading &&
                "Upload"
              }
              {
                isUploading &&
                <FontAwesomeIcon icon={faSpinner} className="spinner" />
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentUploader