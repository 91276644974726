import api from "../utils/api"

export const currency_rates_api_fetch = async () => {
  var ret = {}

  await api().get("/api/currencies/getRates")
    .then((response) => {
      switch (response.data.status) {
        case "success":
          ret = { status: "OK", data: response.data.data, code: "", message: "Successfully submitted." }
          break;
        case "error":
          ret = { status: "ERR", data: "", code: "", message: response.data.message }
          break;

        default:
          break;
      }
    })
    .catch(err => {
      if (err.response) {
        ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
      } else if (err.request) {
        ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
      } else {
        ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
      }
    });
  return ret
}

export const currency_api_getRatesForEditing = async () => {
  var ret = {}

  await api().get("/api/currencies/getRatesForEditing")
    .then((response) => {
      switch (response.data.status) {
        case "success":
          ret = { status: "OK", data: response.data.data, code: "", message: "Successfully saved." }
          break;
        case "error":
          ret = { status: "ERR", data: "", code: "", message: response.data.message }
          break;

        default:
          break;
      }
    })
    .catch(err => {
      if (err.response) {
        ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
      } else if (err.request) {
        ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
      } else {
        ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
      }
    });
  return ret
}

export const currency_api_saveRates = async (data) => {
  var ret = {}
  await api().post("/api/currencies/saveRates", data)
    .then((response) => {
      switch (response.data.status) {
        case "success":
          ret = { status: "OK", data: response.data.data, code: "", message: "Successfully saved." }
          break;
        case "error":
          ret = { status: "ERR", data: "", code: "", message: response.data.message }
          break;

        default:
          break;
      }
    })
    .catch(err => {
      if (err.response) {
        ret = { status: "ERR", type: "RESPONSE", data: "", code: err.response.status, message: err.response.data.message }
      } else if (err.request) {
        ret = { status: "ERR", type: "REQUEST", data: "", code: "", message: err.request }
      } else {
        ret = { status: "ERR", type: "GENERAL", data: "", code: "", message: err.message }
      }
    });
  return ret
}
