
import id_types from "../utils/id_types"

export const IdTypeList = () => {
  return id_types.filter(item => item.enabled).map(item => {
    return <option key={item.code} value={item.code} className=""> {item.name}</option >
  })
}

export const getIdTypeName = (code) => {
  var id_type_name = ""
  id_types.forEach(element => {
    if (code === element.code) {
      id_type_name = element.name
    }
  })
  return id_type_name
}