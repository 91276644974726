import Dashboard from "../pages/Dashboard"
import Login from "../pages/Login"
import TransactionWizard from "../pages/TransactionWizard"
import SenderOverview from "../components/Sender/SenderOverview";
import ViewTransaction from "../pages/ViewTransaction";
import AgentOverview from "../components/Agent/AgentOverview";
import PendingTransactionReport from "../pages/PendingTransactionReport";
import PostedTransactionReportBank from "../pages/PostedTransactionReportBank";
import PostedTransactionReportCash from "../pages/PostedTransactionReportCash";
import CompletedTransactionReport from "../pages/CompletedTransactionReport";
import NotFound from "../components/NotFound";
import CurrencyMarkup from "../pages/CurrencyMarkup";
import AgentTransactionReportCash from "../pages/AgentTransactionReportCash";
import AgentTransactionReportBank from "../pages/AgentTransactionReportBank";
import ChangePassword from "../pages/ChangePassword";
import AgentPayments from "../pages/AgentPayments";
import AgentMyPayments from "../pages/AgentMyPayments";
import AgentReport from "../pages/AgentReport";
import UnigirosTransactionReport from "../pages/UnigirosTransactionReport";
import UnigirosViewTransaction from "../pages/UnigirosViewTransaction";
import EventViewer from "../pages/EventViewer";
import SystemParameters from "../pages/SystemParameters";
import Calculator from "../pages/Calculator";
import BankOverview from '../components/Bank/BankOverview';


const routes = [
  {
    path: '/login',
    component: Login,
    isPrivate: false,
    exact: true,
    componentName: "Login",
    //componentPath: "../pages/Login"
  },
  {
    path: '/404',
    component: NotFound,
    isPrivate: true,
    exact: true,
    componentName: "NotFound",
    //componentPath: "../components/NotFound"
  },
  {
    path: '/',
    component: Dashboard,
    isPrivate: true,
    exact: true,
    componentName: "Dashboard",
    //componentPath: "../pages/Dashboard"
  },
  {
    path: '/agents',
    component: AgentOverview,
    isPrivate: true,
    exact: true,
    componentName: "AgentOverview",
    //componentPath: "../components/Agent/AgentOverview"
  },
  {
    path: '/agents/:agent_id/payments',
    component: AgentPayments,
    isPrivate: true,
    exact: true,
    componentName: "AgentPayments",
    //componentPath: "../pages/AgentPayments"
  },
  {
    path: '/agents/agentReport',
    component: AgentReport,
    isPrivate: true,
    exact: true,
    componentName: "AgentReport",
    //componentPath: "../pages/AgentReport"
  },
  {
    path: '/senders',
    component: SenderOverview,
    isPrivate: true,
    exact: true,
    componentName: "SenderOverview",
    //componentPath: "../components/Sender/SenderOverview"
  },
  {
    path: '/transactionWizard',
    component: TransactionWizard,
    isPrivate: true,
    exact: true,
    componentName: "TransactionWizard",
    //componentPath: "../pages/TransactionWizard"
  },
  {
    path: '/viewTransaction/:id',
    component: ViewTransaction,
    isPrivate: true,
    exact: true,
    componentName: "ViewTransaction",
    //componentPath: "../pages/ViewTransaction"
  },
  {
    path: '/viewExuTransaction/:id/:ex_id',
    component: UnigirosViewTransaction,
    isPrivate: true,
    exact: true,
    componentName: "UnigirosViewTransaction",
    //componentPath: "../pages/UnigirosViewTransaction"
  },
  {
    path: '/reports/pendingTransactionReport',
    component: PendingTransactionReport,
    isPrivate: true,
    exact: true,
    componentName: "PendingTransactionReport",
    //componentPath: "../pages/PendingTransactionReport"
  },
  {
    path: '/reports/postedTransactionReportBank',
    component: PostedTransactionReportBank,
    isPrivate: true,
    exact: true,
    componentName: "PostedTransactionReportBank",
    //componentPath: "../pages/PostedTransactionReport"
  },
  {
    path: '/reports/postedTransactionReportCash',
    component: PostedTransactionReportCash,
    isPrivate: true,
    exact: true,
    componentName: "PostedTransactionReportCash",
    //componentPath: "../pages/PostedTransactionReport"
  },
  {
    path: '/reports/completedTransactionReport',
    component: CompletedTransactionReport,
    isPrivate: true,
    exact: true,
    componentName: "CompletedTransactionReport",
    //componentPath: "../pages/CompletedTransactionReport"
  },
  {
    path: '/reports/agentTransactionReportBank',
    component: AgentTransactionReportBank,
    isPrivate: true,
    exact: true,
    componentName: "AgentTransactionReportBank",
    //componentPath: "../pages/AgentTransactionReport"
  },
  {
    path: '/reports/agentTransactionReportCash',
    component: AgentTransactionReportCash,
    isPrivate: true,
    exact: true,
    componentName: "AgentTransactionReportCash",
    //componentPath: "../pages/AgentTransactionReport"
  },
  {
    path: '/reports/myStatement',
    component: AgentMyPayments,
    isPrivate: true,
    exact: true,
    componentName: "AgentMyPayments",
    //componentPath: "../pages/AgentTransactionReport"
  },
  {
    path: '/currencyMarkup',
    component: CurrencyMarkup,
    isPrivate: true,
    exact: true,
    componentName: "CurrencyMarkup",
    //componentPath: "./pages/CurrencyMarkup"
  },
  {
    path: '/changePassword',
    component: ChangePassword,
    isPrivate: true,
    exact: true,
    componentName: "ChangePassword",
    //componentPath: "../pages/ChangePassword"
  },
  {
    path: '/exutransactions',
    component: UnigirosTransactionReport,
    isPrivate: true,
    exact: true,
    componentName: "UnigirosTransactionReport",
    //componentPath: "../pages/UnigirosTransactionReport"
  },
  {
    path: '/eventLogs/eventviewer',
    component: EventViewer,
    isPrivate: true,
    exact: true,
    componentName: "EventViewer",
    //componentPath: "../pages/EventViewer"
  },
  {
    path: '/systemParameters/sysparam',
    component: SystemParameters,
    isPrivate: true,
    exact: true,
    componentName: "SystemParameters",
    //componentPath: "../pages/EventViewer"
  },
  {
    path: '/calculator',
    component: Calculator,
    isPrivate: true,
    exact: true,
    componentName: "Calculator",
    //componentPath: "../pages/EventViewer"
  },
  {
    path: '/bank',
    component: BankOverview,
    isPrivate: true,
    exact: true,
    componentName: "BankOverview",
    //componentPath: "../pages/EventViewer"
  }
];


export default routes;