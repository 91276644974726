
import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import {
  Redirect,
  Router, Switch
} from "react-router-dom";
import history from "./utils/history"
import './App.css';
import NavBar from './components/NavBar';
import routes from "./utils/routes"
import AppRoute from './components/AppRoute';
import { AuthProvider } from './utils/AuthContext';
import { GlobalVarProvider } from './utils/GlobalVarContext';
import CookieConsent from "react-cookie-consent";

function App() {
  const [menuStatus, _setMenuStatus] = useState()
  const myMenuStateRef = React.useRef(menuStatus);
  const setMenuStatus = data => {
    myMenuStateRef.current = data;
    _setMenuStatus(data);
  };

  const handleClickOutside = (e) => {
    if (typeof myMenuStateRef.current === "object") {
      setMenuStatus(
        Object.fromEntries(Object.entries(myMenuStateRef.current).map(item => {
          if (item[0] === e.target.getAttribute('name')) {
            return [...item]
          }
          return [item[0], false]
        }
        ))
      )
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return (() => {
      document.removeEventListener("click", handleClickOutside);
    })
  }, [])

  return (
    <AuthProvider>
      <GlobalVarProvider>
        <HelmetProvider>
        <Router history={history} basename={process.env.PUBLIC_URL}>
            <NavBar menuStatus={menuStatus} setMenuStatus={setMenuStatus} />
            <Switch>
              {
                routes.map((route) => (
                  <AppRoute
                    key={route.path}
                    path={route.path}
                    component={route.component}
                    componentName={route.componentName}
                    isPrivate={route.isPrivate}
                    exact={route.exact}
                    //componentPath={route.componentPath}
                  />
                ))
              }
              <Redirect to="/404" />
            </Switch>
          </Router>
          {/* <CookieConsent buttonStyle={{ color: "#4e503b", fontSize: "14px", borderRadius: "5px" }}>This site uses cookies. We use cookies to better understand the user and deliver a rich user experience.</CookieConsent> */}
        </HelmetProvider>
      </GlobalVarProvider>
      {/* <pre>{JSON.stringify(menuStatus, null, 4)}</pre> */}
    </AuthProvider>
  )
}

export default App;
