import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes, faSpinner, faArrowDown, faArrowRight, faCalculator, faUndo } from '@fortawesome/free-solid-svg-icons';
import CurrencyList from '../components/CurrencyList';
import SectionHead from '../components/SectionHead';
import { formatNumberToCurrency, formatCurrencyToNumber } from "../utils/currency_util"
import { isNumber } from '../utils/number_util';
import { tx_fee_api_get_fees } from "../api/tx_fee_api"
import { fetchExchangeRates } from "../utils/currency_rates";
import { initial, update } from 'lodash';
import history from "../utils/history"


const Calculator = () => {
  const [transactionRate, setTransactionRate] = useState(0)
  const [receiveLkrMode, setReceiveLkrMode] = useState(false)
  const [showFileSelectorDlg, setShowFileSelectorDlg] = useState(false)
  const [fees, setFees] = useState({ status: "", type: "", data: "", code: "", message: "" })
  // const [tf, setTf] = useState({ status: "", type: "", data: "", code: "", message: "" })
  const [exchangeRates, setExchangeRate] = useState()
  const [exchangeRateFetched, setExchangeRateFetched] = useState(false)
  // const [exchangeRates, setExchangeRates] = useState()

  const initialDataSet = {
    transaction: {
      id: "",
      usd_to_pay: 0,
      usd_balance: 0,
      discount: 0,
      fees: 0,
      tf: 0,
      usd_to_send: 0,
      out_currency_code: "LKR",
      out_amount: 0,
      out_rate: 0,
      out_transfer_amount: 0,
      out_transfer_rate: 0

    },
    tx_details: {
      ils_amount : 0,
      usd_amount : 0
    }
  };

  const [data, setData] = useState({...initialDataSet})

  useEffect(() => {
    const init = async () => {
      var ret = await tx_fee_api_get_fees()
      if (typeof ret === 'object' && ret !== null) {
        setFees(ret)
      }
      var exret  = await fetchExchangeRates()
      if (typeof ret === 'object' && ret !== null) {
        setExchangeRate(exret)
        setExchangeRateFetched(true)

        let exchange_rate_obj = exret['LKR']
        updateData("transaction", { ...data.transaction, out_rate: exchange_rate_obj.rate })
      }
    }
    init()
  }, [])

  useEffect(() => {
    const grid_sum = parseFloat(getGridSum())
    let usdbal = 0;
    usdbal = Math.ceil((data.transaction.usd_to_pay - grid_sum) * 100) / 100;

    updateData("transaction", {
      ...data.transaction,
      usd_balance: usdbal
    })

    //setOutAmount(data.transaction.out_currency_code)
  }, [data.transaction.usd_to_pay, data.tx_details.usd_amount, data.tx_details.ils_amount])

  useEffect(() => {
    setOutAmount(data.transaction.out_currency_code)
  }, [data.transaction.out_currency_code])

  useEffect(() => {
    var tr = 0
    if (typeof data.transaction.out_rate !== 'undefined') {
      tr = data.transaction.out_rate
    }
    var temp = data.transaction.usd_to_send * parseFloat(tr)
    temp = parseInt(temp * 100) / 100
    updateData("transaction", { ...data.transaction, out_amount: temp })
  }, [data.transaction.out_rate])


  const updateData = (section, rec) => {
    console.log('before')
    console.log(data)
    console.log(section)
    console.log(rec)

    setData({
      ...data, [section]: rec
    })
    console.log('after')
    console.log(data)
  }

  const getGridSum = () => {
    let grid_sum = 0;
    if(typeof exchangeRates !== "undefined"){
    grid_sum = grid_sum + (10000 * parseFloat(data.tx_details.ils_amount) / (10000 * parseFloat(exchangeRates["ILS"].rate)))
    grid_sum = grid_sum + parseFloat(data.tx_details.usd_amount)
    }
    return grid_sum

  }

  const revCalc = () => {
    var tr = 0
    if (typeof data.transaction.out_rate !== 'undefined') {
      tr = data.transaction.out_rate
    }

    var grid_sum = parseInt(getGridSum() * 100) / 100
    var fee = getFee(grid_sum)
    var usd_to_send = (grid_sum + parseFloat(data.transaction.discount) - parseFloat(fee))
    updateData("transaction", {
      ...data.transaction,
      usd_to_pay: grid_sum,
      discount: 0,
      fees: fee,
      usd_to_send: usd_to_send,
      out_amount: getOutAmount(usd_to_send),
    })
  }

  const setAmountOnCellClick = (e, index) => {

    if (data.transaction.usd_to_pay > 0) {
      if (e.target.name === "ils_amount") {
        if((typeof(data.tx_details.usd_amount !== "undefined")) && (data.tx_details.usd_amount !== "") && (data.tx_details.usd_amount !== 0) && (!(isNaN(data.tx_details.usd_amount)))) {
          let ils_value = Math.ceil(parseFloat(data.transaction.usd_to_pay - data.tx_details.usd_amount) * parseFloat(exchangeRates["ILS"].rate) * 100) / 100
          if (ils_value < 0){
            ils_value = 0
          }
          updateData("tx_details", { ...data.tx_details, ils_amount: ils_value})

        }
        else {
          let ils_value = Math.ceil(parseFloat(data.transaction.usd_to_pay) * parseFloat(exchangeRates["ILS"].rate) * 100) / 100
          updateData("tx_details", { ...data.tx_details, ils_amount: ils_value})
        }
      }

      if (e.target.name === "usd_amount") {
        if((typeof(data.tx_details.ils_amount !== "undefined")) && (data.tx_details.ils_amount !== "") && (data.tx_details.ils_amount !== 0) && (!(isNaN(data.tx_details.ils_amount)))) {
          let usd_value = Math.ceil(parseFloat(data.transaction.usd_to_pay) - Math.ceil(parseFloat(data.tx_details.ils_amount / parseFloat(exchangeRates["ILS"].rate)) * 100) / 100)
          if (usd_value < 0){
            usd_value = 0
          }
          updateData("tx_details", { ...data.tx_details, usd_amount: usd_value})
         }
         else {
            updateData("tx_details", { ...data.tx_details, usd_amount: data.transaction.usd_to_pay})
         }
      }
    }
  }

  const handleTxDetailsInputChange = (e, index) => {

    if (e.target.name === "ils_amount") {
      updateData("tx_details", { ...data.tx_details, ils_amount: e.target.value })
    }

    if (e.target.name === "usd_amount") {
      updateData("tx_details", { ...data.tx_details, usd_amount: e.target.value })
    }

    const grid_sum = getGridSum()
  }

  const reset = () => {
    history.go(0)
    setData(initialDataSet)
  }

  const setOutAmount = (out_currency_code) => {
    if ((typeof out_currency_code !== "undefined") && (out_currency_code !== "") && (exchangeRates)) {
      let exchange_rate_obj = exchangeRates[out_currency_code]
      updateData("transaction", { ...data.transaction, out_rate: exchange_rate_obj.rate, out_transfer_rate: exchange_rate_obj.transfer_rate, out_transfer_amount: 1111 })
    }
  }

  const resetTxDetails = () => {
    // const TxDetails = Object.assign({}, txDetails)
    // const tx_details = txDetails.map(item => {
    //   item.amount = 0
    //   return item
    // })
    // updateData("tx_details", tx_details)
  }

  const getFee = (usd_to_send) => {
    let feeSlot = fees.data
      .filter(item => {
        if ((usd_to_send >= item.from_amount) && (usd_to_send <= item.to_amount)) {
          return true
        }
      })
    if (Array.isArray(feeSlot) && (feeSlot.length > 0)) {
      return feeSlot[0].fee
    }
    return 0
  }

  const getFeeInverse = (usd_to_pay) => {
    let feeSlot = fees.data
      .filter(item => {
        if (((usd_to_pay - item.fee) >= item.from_amount) && ((usd_to_pay - item.fee) <= item.to_amount)) {
          return true
        }
      })
    if (Array.isArray(feeSlot) && (feeSlot.length > 0)) {
      return feeSlot[0].fee
    }
    return 0
  }

  const getUsdToPay = (usd_to_send, fees, tf) => {
    resetTxDetails()
    let ret = usd_to_send + fees + tf
    return ret
  }

  const getOutAmount = (usd_to_send) => {
    var out_amount = 0

    if (data.transaction.currency_code === "USD") {
      out_amount = usd_to_send
    } else {
      out_amount = Math.round(parseFloat(usd_to_send) * data.transaction.out_rate * 100) / 100
      if (isNaN(out_amount)) {
        out_amount = 0
      }
    }

    return out_amount
  }

  const getUsdToSend = (out_amount) => {
    var usd_to_send = 0

    if (data.transaction.currency_code === "USD") {
      usd_to_send = out_amount
    } else {
      usd_to_send = 10000 * parseFloat(out_amount) / (10000 * data.transaction.out_rate)
      if (isNaN(usd_to_send)) {
        usd_to_send = 0
      }
    }

    return usd_to_send
  }

  const handleTransactionInputChange = (e) => {
    var usd_to_send = 0
    var usd_to_pay = 0
    var out_amount = 0
    var fees = 0
    var tf = data.transaction.tf
    console.log(data.transaction.tf)
    if (e.target.name === "out_currency_code") {

      updateData("transaction", { ...data.transaction, [e.target.name]: e.target.value, out_amount: "loading" })

    } else if (e.target.name === "out_amount") {
        if (e.target.value === "") {
          console.log('Calc Out Amount -> value blank')
          updateData("transaction", {
            ...data,
            out_amount: ""
          })
        }
        else {
          usd_to_send = getUsdToSend(e.target.value)
          fees = getFee(parseFloat(usd_to_send))
          // tf = getTf(parseFloat(usd_to_send))
          usd_to_pay = getUsdToPay(parseFloat(usd_to_send), parseFloat(fees), parseFloat(tf))

          updateData("transaction", {
            ...data.transaction,
            usd_to_pay: usd_to_pay.toFixed(2),
            discount: 0,
            fees: fees,
            usd_to_send: usd_to_send.toFixed(2),
            out_amount: parseFloat(e.target.value)
          })
        }
    } else if (e.target.name === "usd_to_send") {

      if (e.target.value === "") {
        updateData("transaction", {
          ...data.transaction,
          usd_to_send: 0,
          out_amount: "",
          usd_to_pay:"",
          fee:""
        })
      } else {
        var usd_to_send = parseFloat(e.target.value)
        fees = getFee(parseFloat(usd_to_send))
        usd_to_pay = getUsdToPay(parseFloat(usd_to_send), parseFloat(fees), parseFloat(tf))
        out_amount = getOutAmount(parseFloat(usd_to_send))

        updateData("transaction", {
          ...data.transaction,
          usd_to_pay: usd_to_pay.toFixed(2),
          discount: 0,
          fees: fees,
          usd_to_send: parseFloat(e.target.value),
          out_amount: out_amount,
        })
      }
    } else if (e.target.name === "usd_to_pay") {

      if (e.target.value === "") {
        updateData("transaction", {
          ...data.transaction,
          usd_to_send: 0,
          out_amount: "",
          usd_to_pay :""
        })
      } else {
        var usd_to_pay = parseFloat(e.target.value)
        fees = getFeeInverse(parseFloat(usd_to_pay))
        usd_to_send = usd_to_pay - fees
        out_amount = getOutAmount(parseFloat(usd_to_send))

        updateData("transaction", {
          ...data.transaction,
          usd_to_pay:  parseFloat(e.target.value),
          discount: 0,
          fees: fees,
          usd_to_send: usd_to_send.toFixed(2),
          out_amount: out_amount,
        })
      }
    }

    else {
      updateData("transaction", { ...data.transaction, [e.target.name]: e.target.value })
    }
  }

  const addNewTxDetail = () => {
    // updateData("tx_details", [...txDetails, emptyTxDetail])
  }

  const handleToggleKeyDown = (e) => {
    if (["Enter", "Space"].includes(e.code)) {
      e.preventDefault()
      setReceiveLkrMode(!receiveLkrMode)
    }
  }

  return (
    <>
        {!exchangeRateFetched && <div className=" max-w-4xl mx-auto px-4 mt-6">
          <span className="text-md pr-5 font-rubik text-backblue-600">Fetching Online Exchange Rates. Please Wait ...  </span>
          <FontAwesomeIcon icon={faSpinner} className={"text-2xl " + (exchangeRateFetched ? "hidden" : "spinner")} fixedWidth />
          </div>
        }
        {exchangeRateFetched &&
          <IlsUsdSection
            data={data}
            //txDetailsMarkup={txDetailsMarkup}
            addNewTxDetail={addNewTxDetail}
            handleTransactionInputChange={handleTransactionInputChange}
            handleTxDetailsInputChange  = {handleTxDetailsInputChange}
            setAmountOnCellClick = {setAmountOnCellClick}
            exchangeRates = {exchangeRates}
            // transactionRate={transactionRate}
            revCalc={revCalc}
            reset={() => reset()}
            // transactionOwner={transactionOwner}
          />
          }
    </>
  )
  }

const IlsUsdSection = ({ data, addNewTxDetail, handleTransactionInputChange, exchangeRates, setAmountOnCellClick, handleTxDetailsInputChange, revCalc, reset}) => {
  return (
    <div className="max-w-4xl mx-auto font-ibm bg-white">
      <div className="shadow-lg px-6 py-6 my-6">
        <div className="flex justify-between content-center pb-3">
          <span className="text-xl font-rubik  font-semigbold text-gray-500"> Calculator </span>
          <button
            className="col-start-2 px-3 py-1 bg-gradient-to-b from-green-100 to-green-300 hover:from-green-200 hover:to-green-400 rounded border border-green-200 text-backblue-600"
            onClick={() => reset()}
          >
            <FontAwesomeIcon icon={faUndo} className={"mr-2 text-gray-500"} />
            Reset
          </button>
        </div>
        <div className="w-full px-1 mt-3">
          <div className="grid grid-cols-4 gap-4 text-sm">
            <div className="cols">
              <div className="font-semibold text-backblue-600 text-left text-xs col-span-1 mb-1">USD TO SEND :</div>
              <input type="text" name="usd_to_send" value={data.transaction.usd_to_send} onChange={(e) => handleTransactionInputChange(e)} className="brounded-sm  text-right col-span-1 border  border-textboxgray-100 text-sm font-semibold h-8 p-1 w-full text-backblue-600  focus:outline-none focus:border-gray-400" />
            </div>
            <div className="mr-2">
              <div className="font-semibold text-backblue-600 text-left text-xs mb-1">CURRENCY OUT :</div>
              <select name="out_currency_code" value={data.transaction.out_currency_code} onChange={(e) => handleTransactionInputChange(e)} className="brounded-sm  text-right col-span-1 border  border-textboxgray-100 text-sm font-semibold h-8 p-1 w-full text-backblue-600  focus:outline-none focus:border-gray-400">
                <CurrencyList selected={data.transaction.out_currency_code} hide={["ILS"]} />
              </select>
            </div>
            <div className="mr-3">
              <div className="font-semibold text-backblue-600 text-left text-xs mb-1">OUT RATE :</div>
              <input type="text" name="out_rate" value={data.transaction.out_rate} readOnly className="brounded-sm  text-right border  border-textboxgray-100 text-sm font-semibold h-8 p-1 w-full text-gray-400 focus:outline-none focus:border-gray-400" />
            </div>
            <div>
              <div className="font-semibold text-backblue-600 text-left text-xs mb-1">AMOUNT TO RECEIVE :</div>
              {
                (data.transaction.out_amount === "loading") &&
                <div className="flex items-center justify-center border rounded  h-8 py-1 bg-pink-200"><FontAwesomeIcon icon={faSpinner} className="spinner text-gray-400" /></div>
              }
              {
                (data.transaction.out_amount !== "loading") &&
                <input type="number"  name="out_amount" value={data.transaction.out_amount} onChange={(e) => handleTransactionInputChange(e)} className="brounded-sm  text-right border  border-textboxgray-100 text-sm font-semibold h-8 p-1 w-full text-gray-400 focus:outline-none focus:border-gray-400" />
              }
            </div>
            <div className="mt-4">
              <div className="font-semibold text-backblue-600 text-left mb-1">Discount :</div>
              <input  type="text" readOnly name="discount" value={data.transaction.discount} onChange={(e) => handleTransactionInputChange(e)} className="brounded-sm  text-right border  border-textboxgray-100 text-sm font-semibold h-8 p-1 w-full text-gray-400 focus:outline-none focus:border-gray-400" />
            </div>
            <div className="row-span-3 col-span-3  overflow-hidden mt-4 ">
              <table className="text-sm w-full">
                <thead className="font-ibm text-backblue-600">
                  <tr className=" bg-blue-100 h-7">
                    <th className="w-1/12  border-l text-xs border-b">CURRENCY</th>
                    <th className="w-1/12 border-l text-xs border-b">AMOUNT</th>
                    <th className="w-1/12 border-l text-xs border-b border-r">RATE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="hover:bg-blue-100 h-7">
                    <td className="border border-textboxgray-100 pl-5 font-semibold font-ibm text-gray-400">
                      ILS
                    </td>
                    <td className="border border-textboxgray-100">
                      <input name="ils_amount" type="text"
                        onChange={(e) => handleTxDetailsInputChange(e)}
                        onFocus={(e) => setAmountOnCellClick(e)}
                        value={data.tx_details.ils_amount}
                        className="w-full h-7 px-1 text-sm text-backblue-600 font-semibold text-right focus:outline-none" />
                    </td>
                    <td className={"border border-textboxgray-100 text-center "}>
                      {
                        <input name="rate" type="number"
                          value={exchangeRates['ILS'].rate}
                          readOnly
                          className="w-full h-7 px-1 text-sm text-gray-400 text-right font-semibold focus:outline-none" />
                      }
                    </td>
                  </tr>
                  <tr className="hover:bg-yellow-100 h-7">
                    <td className="border border-textboxgray-100 pl-5 font-semibold font-ibm text-gray-400">
                      USD
                    </td>
                    <td className="border border-textboxgray-100">
                      <input name="usd_amount" type="text"
                        onChange={(e) => handleTxDetailsInputChange(e)}
                        onFocus={(e) => setAmountOnCellClick(e)}
                        value={data.tx_details.usd_amount}
                        className="w-full h-7 px-1 text-sm text-backblue-600 brounded-sm  font-semibold text-right focus:outline-none" />
                    </td>
                   <td className="border border-textboxgray-100 text-center ">
                      {
                        <input name="rate" type="number"
                          value={exchangeRates['USD'].rate}
                          readOnly
                          className="w-full h-7 px-1 text-sm text-gray-400 brounded-sm  text-right font-semibold focus:outline-none" />
                      }
                    </td>
                  </tr>
                  {/* <tr className="h-7 ">
                   <td colSpan='4' className="text-right pt-2">
                      <button
                        className="bg-gradient-to-b from-red-100 to-red-300 rounded border hover:from-red-200 hover:to-red-400 px-2 py-1 border-red-200 text-backblue-600"
                        onClick={() => revCalc()}
                      >
                        <FontAwesomeIcon icon={faCalculator} className={"mr-2 text-gray-500"} />
                        Rev. Calc.
                      </button>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>

          </div>
          <div className="grid grid-cols-4 gap-4 text-sm mt-7 ">
            <div className="cols">
              <div className="font-semibold text-backblue-600 text-left text-xs col-span-1 mb-1">TF :</div>
              <input type="text" name="tf" value={data.transaction.tf} onChange={(e) => handleTransactionInputChange(e)} className="brounded-sm  text-right col-span-1 border  border-textboxgray-100 text-sm font-semibold h-8 p-1 w-full text-backblue-600  focus:outline-none focus:border-gray-400" />
            </div>

          </div>
          <div className="grid grid-cols-4 gap-4 text-sm mt-7 ">
          <div>
              <div className="font-semibold text-backblue-600 text-xs text-left mb-1">FEES : </div>
              <input readOnly type="text" name="fees" value={data.transaction.fees} onChange={(e) => handleTransactionInputChange(e)} className="text-right border  border-textboxgray-100 text-sm font-semibold h-8 p-1 w-full text-gray-400 focus:outline-none focus:border-blue-300" />
            </div>
            <div className="items-center col-start-2">
              <div className="font-semibold font-ibm text-xs text-backblue-600 text-left col-span-1  mb-1">USD TO PAY :</div>
              <input  type="number" name="usd_to_pay" value={data.transaction.usd_to_pay} onChange={(e) => handleTransactionInputChange(e)} className="text-right border brounded-sm  border-textboxgray-100 text-sm font-semibold h-8 p-1 w-full text-gray-400 focus:outline-none focus:border-gray-400" />
            </div>
            <div className=" pb-2 gap-2 items-center">
              <div className="font-semibold font-ibm  text-backblue-600 text-xs mb-1">USD BALANCE :</div>
              <input readOnly type="text" name="usd_to_pay" value={data.transaction.usd_balance} className="text-right border  border-textboxgray-100 text-sm font-semibold brounded-sm h-8 p-1 w-full text-gray-400 focus:outline-none focus:border-gray-400 shadow-xs" readOnly />
            </div>
            <div className="text-right content-center">
              <button className="bg-gradient-to-b from-red-100 to-red-200 rounded-sm border hover:from-red-200 hover:to-red-400 px-2 py-1 border-red-200 text-backblue-600"
                 onClick={() => revCalc()}  >
                 <FontAwesomeIcon icon={faCalculator} className={"mr-2 text-gray-500"} />
                  Rev. Calc.
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Calculator
