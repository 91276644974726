
const CheckingAuthMessage = () => {
  return (
    <div className="flex w-screen-sm justify-center items-center">
      <img className="img-fluid"
        src={`${process.env.REACT_APP_FRONTEND_URL}/loading64.gif`}
        alt="logo" />
    </div>
  )
}

export default CheckingAuthMessage