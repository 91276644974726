import { isNull } from "lodash"

const validate = (rules, messages, data) => {
  let fields = Object.keys(rules)
  fields.forEach((field, index) => {
    let field_rules = rules[field]
    field_rules.forEach((field_rule, index) => {
      //console.log(data[field])
      switch (field_rule) {
        case "required":
          if ((typeof data[field] === 'undefined') || (data[field] === "") || isNull(data[field])) {
            throw {
              status: "ERR",
              type: "VALIDATION",
              data: "",
              code: "",
              message: messages[field][field_rule]
            }
          }
          break;

        default:
          break;
      }
    })
  })
}

const Validator = {
  validate: validate
}

export default Validator