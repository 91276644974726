import React, { useEffect, useState, useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../utils/AuthContext"
import GlobalVarContext from "../utils/GlobalVarContext"
import { faKeyboard, faUser, faHandshake,faSun,faChartBar, faBookmark, faCalendarAlt,faCaretSquareUp, faBuilding } from '@fortawesome/free-regular-svg-icons';
import { nativeTouchData } from "react-dom/cjs/react-dom-test-utils.development";


const NavBar = ({ menuStatus, setMenuStatus }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [navigatorMarkup, setNavigatorMarkup] = useState(<div className="blink_me text-yellow-500">Loading...</div>)
  const [navigatorRefreshing, setNavigatorRefreshing] = useState(false)
  const [loggingOut, setLoggingOut] = useState(false)

  let nav = useContext(GlobalVarContext).navigator

  const iconmap = {
    faKeyboard:faKeyboard,
    faCalendarAlt:faCalendarAlt,
    faUser:faUser,
    faHandshake:faHandshake,
    faSun:faSun,
    faChartBar:faChartBar,
    faBookmark: faBookmark,
    faCaretSquareUp:faCaretSquareUp,
    faBuilding:faBuilding
  }

  const auth = useContext(AuthContext)

  const toggle = prevState => !prevState

  const location = useLocation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(toggle)
  }

  const logout = () => {
    setLoggingOut(true)
    auth.logout()
  }

  useEffect(() => {
    if ((typeof nav !== 'undefined') && (nav.length > 0)) {
      setNavigatorMarkup(
        nav
          .filter(item => {
            if (item.hasOwnProperty('items')) {
              if (item.items.length === 0) {
                return false
              }
            }
            return true
          })
          .map((item, index) => {
            if (item.hasOwnProperty('items')) {
              return <DropDownMenu key={index} index={index} content={item} menuStatus={menuStatus} icon={item.icon} iconmap={iconmap} setMenuStatus={setMenuStatus} className={item.className} />
            } else {
                return <MainLink key={index} path={item.path} text={item.text} name={item.name} icon={item.icon} iconmap={iconmap} className={item.className} />
            }
          })
      )
      setNavigatorRefreshing(false)
    }
  }, [nav, menuStatus])

  useEffect(() => {
    if ((typeof nav !== 'undefined')
      // && (nav.length > 0)
      ) {
      let mnuArr = Object.values(nav).map(item => {
        return [[item.name], false]
      })
      let menu = { ...Object.fromEntries(mnuArr), "account": false }
      setMenuStatus(menu)
    }
  }, [nav])

  useEffect(() => {
    if (!auth.isAuthed) {
      setNavigatorRefreshing(true)
      setNavigatorMarkup(<div className="blink_me text-yellow-500">Loading...</div>)
    }
  }, [auth.isAuthed])

  return (
    <>
    <div className="flex items-center justify-between bg-dashboardblue-300 py-4 px-32">
      <span  className=" inline-block align-center text-gray-100 text-2xl font-balsamiq ">SENDEM</span>
      <nav className="bg-dashboardblue-300  ">
      {
        auth.isAuthed &&
        <>
          <div className="flex items-baseline w-full justify-between gap-8 item-center ">
              {
                navigatorMarkup
              }
          </div>

          <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} border-b border-gray-700 md:hidden`}>
            <div className="px-2 py-3 space-y-1 sm:px-3"> </div>
          </div>
        </>
      }
      </nav>
      <div>
        {
          ((typeof nav !== 'undefined') && (nav.length > 0) && !navigatorRefreshing) && auth.user &&
          <LinkContainer name="account" text={auth.user.name} align="right" menuStatus={menuStatus} setMenuStatus={setMenuStatus} className2="phone:hidden sm:inline inline-block align-center font-roboto text-sm text-white align-middle">
            <SubLink key="auth-changePassword" path="/changePassword" text="Change Password" />
            <button
              key="auth-logout"
              className={"block text-left w-full  text-xs my-1 p-1 bg-gray-800 focus:outline-none text-white hover:text-white hover:bg-gray-500 focus:text-white focus:bg-gray-700"}
              onClick={() => logout()}
            >
              <span className={loggingOut ? "hidden" : "block"}>Log Out</span>
              <span className={"blink_me " + (loggingOut ? "block" : "hidden")}>Logging Out</span>
            </button>
          </LinkContainer>
        }
        </div>
    </div>
    </>
    
  )
}

const DropDownMenu = ({ index, content, menuStatus, setMenuStatus, icon, iconmap, className }) => {
  return (
    <LinkContainer key={index} name={content.name} width="w-40" text={content.text}  icon={icon} iconmap={iconmap}  menuStatus={menuStatus} setMenuStatus={setMenuStatus} className={className} >
      {
        content.items.map((item, i) => {
          return (
            <SubLink key={i} path={item.path} text={item.text} />
          )
        })
      }
    </LinkContainer>
  )
}

const MainLink = ({ path, text, icon, iconmap, handleClick, className, }) => {
  
  const [styling, setStyling] = useState()
  const location = useLocation();

  // useEffect(() => {
  //   if (path === location.pathname) {
  //     setStyling( "text-white bg-gray-100 text-steel-800")
  //   } else {
  //     setStyling("text-backblue-800 hover:text-white hover:bg-gray-500 focus:text-white focus:bg-gray-700")
  //   }
  // }, [path, location.pathname])

  return (
    <div className={className}>
      <Link to={path} >     
        <button className={"font-roboto block px-3  focus:outline-none " + styling} >
         <div className="flex items-center" > 
          <FontAwesomeIcon icon={iconmap[icon]} className={" text-lg stroke-0 text-white font-noto font-light "} />
          <span className={"pl-3 font-roboto font-light  text-sm  text-white"}>{text} </span>
        </div>
        </button>
      </Link>
    </div>
  )
}


const SubLink = ({ path, text, isMenuDropped, setIsMenuDropped }) => {
  const [styling, setStyling] = useState()
  const location = useLocation();

  useEffect(() => {
    if (path === location.pathname) {
      setStyling("text-white bg-gray-600 text-yellow-300")
    } else {
      setStyling("text-backblue-800 hover:text-white hover:bg-gray-500 focus:text-white focus:bg-gray-700")
    }
  }, [path, location.pathname])

  return (
    <Link to={path}>
      <span
         onClick={() => setIsMenuDropped(!isMenuDropped)}
        //onMouseOver={() => setIsMenuDropped(!isMenuDropped)}
        className={"block font-roboto text-md  my-1 p-1 bg-gray-50 focus:outline-none hover:text-white hover:bg-gray-500 focus:text-white focus:bg-gray-700" + styling}
      >
        {text}
      </span>
    </Link>
  )
}

const LinkContainer = ({ text, children, name, width, align, menuStatus, setMenuStatus, icon, iconmap, className, className2 }) => {
  const [isMenuDropped, setIsMenuDropped] = useState(false)

  const handleMenuDrop = (e) => {
    e.stopPropagation()

    setMenuStatus({
      ...Object.fromEntries(
        Object.entries(menuStatus).map(mnu => {
          if (!menuStatus[name]) {
            mnu[1] = menuStatus[name]
          }
          return mnu
        })
      ),
      [name]: !menuStatus[name]
    })
  }

  useEffect(() => {
    if (typeof menuStatus === 'object') {
      setIsMenuDropped(menuStatus[name])
    }
  }, [menuStatus])

  return (
    <div className={className + " relative "}>
      <div onClick={(e) => handleMenuDrop(e)}  name={name} className={"flex text-sm  text-white cursor-default focus:outline-none focus:text-white focus:bg-gray-800 hover:text-white hover:bg-blue-500 "}>
        <span className=" font-roboto text-wite">
          {
            className2 &&
            <>
            <span className={className2}>{text}</span>
            <FontAwesomeIcon icon={faCaretDown} className={"ml-2 mr-3 text-white font-light"} />
            </>
          }
          {
            !className2 &&
            <div className="flex items-center" > 
            <FontAwesomeIcon icon={iconmap[icon]} className={"text-lg"} />
            <span className="pl-2">{text}</span>
            <FontAwesomeIcon icon={faCaretDown} className={"ml-2 mr-3 text-white font-light"} />
            </div>
          }
          
        </span>
      </div>
      {
        <div className={"absolute font-roboto px-1 min-w-max text-md bg-gray-50 border-gray-800 border-l border-b border-r " + (isMenuDropped ? "block opacity-90" : "hidden")}>
          {
            React.Children.map(children, child => {
              let childprops = {
                isMenuDropped: isMenuDropped,
                setIsMenuDropped: (val) => setIsMenuDropped(val)
              }
              return React.cloneElement(child, childprops);
            })
          }
        </div>
      }
    </div>
  )
}

export default NavBar