import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog, faTimesCircle, faSpinner, faTimes, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { CountryList } from '../CountryList'
import { IdTypeList } from "../IdTypeList";
import { GenderList } from "../GenderList";
import { receiver_api_create, receiver_api_update } from "../../api/receiver_api"
import { fetchResponseMessages } from "../../utils/err_handler"
// import DocumentUploader from "../DocumentUploader/DocumentUploader";
// import DocumentPreviewer from "../DocumentPreviewer/DocumentPreviewer";
import { BankList } from "../BankList";
import { BankBranchList } from "../BankBranchList";
import { countries } from "../../utils/countries"
import Validator from "../../utils/Validator";
import axios from "axios";

const ReceiverDlg = ({ emptyDataObject, data, updateData, showDlg, receiverDlgProps }) => {
  const [localData, setLocalData] = useState({ ...emptyDataObject.receiver, sender_id: data.sender.id })
  const [modeColor, setModeColor] = useState("blue")
  const [statusColor, setStatusColor] = useState()
  const [isAlertShown, setIsAlertShown] = useState(false)
  const [isSaveInProgress, setSaveInProgress] = useState(false)
  const [apiResponse, setApiResponse] = useState({ status: "", type: "", data: "", code: "", message: "" })
  const [showFileSelectorDlg, setShowFileSelectorDlg] = useState(false)
  const [flagUrl, setFlagUrl] = useState()
  let [country_id,setCountry_id] = useState()

  useEffect(() => {
    if (receiverDlgProps.mode === "edit") {
      setLocalData(Object.assign({}, { ...data.receiver }))
    } else {
      setLocalData({ ...emptyDataObject.receiver, sender_id: data.sender.id })
    }
  }, [])

  useEffect(() => {
    switch (localData.status) {
      case "active":
        setStatusColor("green")
        break;
      case "blocked":
        setStatusColor("red")
        break;
      default:
        break;
    }
  }, [data])

  useEffect(() => {
    if (apiResponse && ["OK", "ERR"].includes(apiResponse.status)) {
      setIsAlertShown(true)
    } else {
      setIsAlertShown(false)
    }
    if (apiResponse && (apiResponse.status === "OK")) {
      setTimeout(() => {
        updateData("receiver", apiResponse.data[0])
        showDlg(false)
      }, 500);
    }
  }, [apiResponse])

  const handleInputChange = (e) => {
    setLocalData({ ...localData, [e.target.name]: e.target.value })
  }

  const handleSave = async () => {
    let rules = {
      branch: ['required']
    }
    let messages = {
      branch: {
        required: "Branch is required"
      }
    }
    try {
      setApiResponse()
      setSaveInProgress(true)
      Validator.validate(rules, messages, localData)
      if (localData.id === "") {
        setApiResponse(await receiver_api_create([localData]))
      } else {
        setApiResponse(await receiver_api_update([localData]))
      }
      setSaveInProgress(false)
    } catch (err) {
      setSaveInProgress(false)
      setApiResponse(err)
    }
  }

  useEffect(() => {
    if ((localData.country_code !== "")) {
      setFlagUrl(process.env.REACT_APP_FRONTEND_URL + "/flags/" + localData.country_code + ".svg",)
    }
  }, [localData])

  const setAttachedDocuments = (doc) => {
    if (localData.documents.length > 0) {
      setLocalData({ ...localData, documents: [...localData.documents, doc] })
    } else {
      setLocalData({ ...localData, documents: [doc] })
    }
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="font-sans inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className={"mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-" + modeColor + "-100 sm:mx-0 sm:h-10 sm:w-10"}>
                <FontAwesomeIcon icon={faUserCog} className={"text-" + modeColor + "-600"} />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left font-publicSans">
                <div className="flex justify-between">
                  <div className="flex items-center py-2">
                    <h3 className="flex text-lg leading-6 font-semibold items-center text-gray-700 mr-3 w-44 h-7" id="modal-title">
                      {
                        (receiverDlgProps.mode === "add") && "Add new Receiver"
                      }
                      {
                        (receiverDlgProps.mode === "edit") && "Edit Receiver"
                      }
                    </h3>
                    {
                      isAlertShown &&
                      <div className={"flex w-full justify-between items-center rounded px-3 h-7  text-sm font-semibold bg-" + ((apiResponse && apiResponse.status === "ERR") ? "red" : "green") + "-100 text-" + ((apiResponse && apiResponse.status === "ERR") ? "red" : "green") + "-500"}>
                        <span>{apiResponse && fetchResponseMessages(apiResponse.message)}</span>
                        <button onClick={() => setIsAlertShown(false)}><FontAwesomeIcon icon={faTimesCircle} className={"ml-3 text-" + ((apiResponse && apiResponse.status === "ERR") ? "red" : "green") + "-500"} /></button>
                      </div>
                    }
                  </div>
                  <button
                    onClick={() => showDlg(false)}
                    className="flex justify-center h-7 w-7 border border-gray-400 rounded-full -mt-3 -mr-2"
                  >
                    <FontAwesomeIcon icon={faTimes} className={"text-red-500 text-lg mt-1"} />
                  </button>
                </div>
                <hr className="border-b-1 border-gray-300" />
                <div className="mt-2">
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-3 text-sm">
                    {/* <div className="grid grid-cols-6 gap-4 items-baseline">
                      <div className="text-right font-semibold text-gray-500">Receiver Code:</div>
                      <div className="text-blue-400">
                        <input type="text" name="code" className="border-b border-red-400 font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400 uppercase" value={localData.code} onChange={(e) => handleInputChange(e)} />
                      </div>
                      <div></div>
                      <div></div>
                      <div className="text-right font-semibold text-gray-500">Status:</div>
                      <div className="text-blue-400">
                        <span className={"text-center px-3 py-1 font-semibold rounded-full bg-" + statusColor + "-200 text-" + statusColor + "-600 h-7 focus:outline-none uppercase"}>{localData.status}</span>
                      </div>
                    </div> */}
                    <input type="text" hidden name="id" value={localData.id} readOnly />
                    <div className="grid grid-cols-6 gap-4 items-baseline">
                      <div className="text-right font-semibold text-gray-500">First Name:</div>
                      <div className="text-blue-400"><input type="text" name="first_name" className="border-b border-red-400 font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400" value={localData.first_name} onChange={(e) => handleInputChange(e)} /></div>
                      <div className="text-right font-semibold text-gray-500">Middle Name:</div>
                      <div className="text-blue-400"><input type="text" name="middle_name" className="border-b font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400" value={localData.middle_name} onChange={(e) => handleInputChange(e)} /></div>
                      <div className="text-right font-semibold text-gray-500">Last Name:</div>
                      <div className="text-blue-400"><input type="text" name="last_name" className="border-b  border-red-400 font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400" value={localData.last_name} onChange={(e) => handleInputChange(e)} /></div>
                    </div>
                    <div className="grid grid-cols-6 gap-4 items-baseline">
                      <div className="text-right font-semibold text-gray-500">Gender:</div>
                      <select name="gender" value={localData.gender} onChange={(e) => handleInputChange(e)} className="text-blue-400 font-semibold focus:outline-none  border-b border-red-400 py-1 w1-40">
                        <GenderList />
                      </select>
                      <div className="text-right font-semibold text-gray-500">Date of Birth:</div>
                      <div className="text-blue-400"><input type="date" name="dob" className="border-b font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400 uppercase" value={localData.dob} onChange={(e) => handleInputChange(e)} /></div>
                    </div>
                    <div className="grid grid-cols-6 gap-4 items-baseline">
                      <div className="text-right font-semibold text-gray-500">Phone Number:</div>
                      <div className="text-blue-400"><input type="text" name="phone_no" className="border-b border-red-400 font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400 uppercase" value={localData.phone_no} onChange={(e) => handleInputChange(e)} /></div>
                    </div>
                    <div className="grid grid-cols-6 gap-4 items-baseline">
                      <div className="col-span-1 text-right font-semibold text-gray-500">Street Address:</div>
                      <div className="col-span-2 text-blue-400"><input type="text" name="street_address" className="border-b border-red-400 pl-1 font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400" value={localData.street_address} onChange={(e) => handleInputChange(e)} /></div>
                      <div className="col-span-1 text-right font-semibold text-gray-500">City:</div>
                      <div className="col-span-1 text-blue-400"><input type="text" name="city" className="border-b font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400" value={localData.city} onChange={(e) => handleInputChange(e)} /></div>
                    </div>
                    <div className="grid grid-cols-6 gap-4 items-baseline mt-1">
                      <div className="text-right font-semibold text-gray-500">Country:</div>
                      <div className="flex">
                        <select name="country_code" value={localData.country_code} onChange={(e) => handleInputChange(e)} className="text-blue-400 font-semibold focus:outline-none  border-b py-1 w1-40">
                          <CountryList />
                        </select>
                        <img className="ml-3 w-8" src={flagUrl} alt={localData.country_code} />
                      </div>
                      <div className="text-right font-semibold text-gray-500">Postal Code:</div>
                      <input type="text" name="postal_code" className="text-blue-400 border-b font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400 uppercase" value={localData.postal_code} onChange={(e) => handleInputChange(e)} />
                    </div>
                    <hr className="my-4" />
                    <div className="grid grid-cols-6 gap-4">
                      <div className="col-span-2">
                        <div className="grid grid-cols-2 gap-4">
                          <div className="text-right font-semibold text-gray-500">A/C No:</div>
                          <div className="text-blue-400">
                            <input type="text" name="account_no" className="border-b border-red-400 font-semibold h-7 mt-1 mr-2 w-full focus:outline-none focus:border-blue-400" value={localData.account_no} onChange={(e) => handleInputChange(e)} />
                          </div>
                          <div className="text-right font-semibold text-gray-500">Bank:</div>
                          <div className="text-blue-400">
                            <select name="bank" value={localData.bank} onChange={(e) => handleInputChange(e)} className="col-span-2 text-sm text-blue-400 font-semibold border-b border-red-400 py-1 w-40 focus:outline-none focus:border-blue-400 ">
                              <BankList country_code={localData.country_code}/>
                            </select>
                          </div>
                          <div className="text-right font-semibold text-gray-500">Branch:</div>
                          <div className="text-blue-400">
                            <select name="branch" value={localData.branch} className="col-span-2 text-sm text-blue-400 font-semibold border-b border-red-400 py-1 w-40 focus:outline-none focus:border-blue-400 " onChange={(e) => handleInputChange(e)}>
                              <BankBranchList bankCode={localData.bank} />
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-span-2">
                        <DocumentPreviewer
                          documents={localData.documents}
                          setAttachedDocuments={(doc) => setAttachedDocuments(doc)}
                          cssClass="shadow"
                          colSpan="2"
                          docColumns="4"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button disabled={isSaveInProgress} onClick={() => handleSave()} type="button" className="inline-flex justify-center items-center rounded-md border border-transparent shadow-sm w-20 px-4 py-2 bg-blue-500 text-white text-xs font-semibold hover:bg-blue-600 hover:shadow-md focus:outline-none sm:ml-3 uppercase ">
              {
                !isSaveInProgress &&
                "Save"
              }
              {
                isSaveInProgress &&
                <FontAwesomeIcon icon={faSpinner} className="spinner" />
              }
            </button>
            <button onClick={() => showDlg(false)} type="button" className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm w-20 px-4 py-2 bg-white text-gray-700 text-xs font-semibold hover:bg-gray-100 hover:shadow-md focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto uppercase">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReceiverDlg