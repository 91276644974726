import React, { useContext, useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { AuthContext } from "../utils/AuthContext"

const NotFound = () => {
  let auth = useContext(AuthContext)

  // useEffect(() => {
  //   setTimeout(() => {
  //     auth.logout()
  //   }, 300)
  // }, [])

  return (
    <>
      <Helmet>
        <title>404</title>
      </Helmet>
      <div className="flex justify-center">
        <div>
          <div className="font-publicSans text-7xl font-semibold mt-10 text-gray-500">Page Not Found!</div>
          <div className="text-center mt-10 font-publicSans">Please use menu links</div>
        </div>
      </div>
    </>
  )
}

export default NotFound