
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import React from 'react'
import SectionHead from '../components/SectionHead'
import { formatNumberToCurrency } from '../utils/currency_util'
import { getBankName } from '../components/BankList';
import { getBranchName } from '../components/BankBranchList';
import { getCountryName } from '../components/CountryList';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TxDetail from '../components/TxDetail/TxDetail';
import { faArrowRight, faCheck, faSpinner, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
// import CountryList from '../components/CountryList'

const TransactionWizardStepThree = ({ data }) => {
  return (
    <>
      <div className="grid grid-cols-12 gap-4 text-sm  mt-3">
        <div className="col-span-4 div max-w-sm rounded-md bg-redgray-100 overflow-hidden shadow-lg font-poppins  text-gray-120 text-sm pb-5 h-120 border-b border-blue-500">
          <SectionHead titleColor="gray-500" title="Sender Details" topMargin="mt-61" borderColor="blue" />
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-3">
            <div className="text-right  text-gray-500">First Name:</div>
            <input type="text" name="firstName" className="bg-transparent text-gray-700 text-sm  h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={data.sender.first_name + ' ' + data.sender.last_name}readOnly />
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-3">
            <div className="text-right  text-gray-500">Gender:</div>
            <input type="text" name="gender" className="bg-transparent text-gray-700 text-sm  h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={data.sender.gender} readOnly />
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-3">
            <div className="text-right  text-gray-500">Date of Birth:</div>
            <input type="text" name="dob" className="bg-transparent text-gray-700 text-sm  h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={data.sender.dob} readOnly />
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-3">
            <div className="text-right  text-gray-500">Country:</div>
            <input type="text" name="senderCountry" className="bg-transparent text-gray-700 text-sm  h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={data.sender.country_code} readOnly />
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-1">
            <div className="text-right  text-gray-500">ID Type:</div>
            <input type="text" name="idType" className="bg-transparent text-gray-700 text-sm  h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={data.sender.id_type} readOnly />
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-1">
            <div className="text-right  text-gray-500">ID Number:</div>
            <input type="text" name="idNumber" className="bg-transparent text-gray-700 text-sm  h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={data.sender.id_number} readOnly />
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-1">
            <div className="text-right  text-gray-500">ID Expiry Date:</div>
            <input type="text" name="idExpiryDate" className="bg-transparent text-gray-700 text-sm  h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={data.sender.id_expiry} readOnly />
          </div>
        </div>
        <div className="col-span-4 div max-w-sm rounded-md bg-redgray-100 overflow-hidden shadow-lg font-poppins  text-gray-120 text-sm pb-5 h-120 border-b border-yellow-500">
          <SectionHead titleColor="gray-500" title="Receiver Details" topMargin="mt-61" borderColor="blue" />
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-3">
            <div className="text-right  text-gray-500">Transfer :</div>
            <input type="text" name="transferType" className="bg-transparent text-sm text-gray-700  h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value="DFCC" readOnly />
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-3">
            <div className="text-right  text-gray-500">Recipient:</div>
            <div className="text-gray-500"><input type="text" name="recipient" className="bg-transparent text-gray-700 text-sm  h-7 mt-1 mr-2 w-full focus:outline-none uppercase overflow-ellipsis" value={data.receiver.first_name + " " + data.receiver.last_name} title={data.receiver.first_name + " " + data.receiver.last_name} readOnly /></div>
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-3">
            <div className="text-right  text-gray-500">Account:</div>
            <div className="text-gray-500"><input type="text" name="account" className="bg-transparent text-gray-700 text-sm  h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={data.receiver.account_no} readOnly /></div>
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-3">
            <div className="text-right  text-gray-500">Bank:</div>
            <div className="text-gray-500"><input type="text" name="bank" className="bg-transparent text-gray-700 text-sm  h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={getBankName(data.receiver.bank)} readOnly /></div>
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-3">
            <div className="text-right  text-gray-500">Branch:</div>
            <div className="text-gray-500"><input type="text" name="branch" className="bg-transparent text-gray-700 text-sm  h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={getBranchName(data.receiver.bank, data.receiver.branch)} readOnly /></div>
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm items-baseline mt-3">
            <div className="text-right  text-gray-500">Country:</div>
            <div className="text-gray-500"><input type="text" name="receiver_country" className="bg-transparent text-gray-700 text-sm  h-7 mt-1 mr-2 w-full focus:outline-none uppercase" value={getCountryName(data.receiver.country_code)} readOnly /></div>
          </div>
        </div>
        <div className="col-span-4">
          <div className="col-span-4 div max-w-sm rounded-md bg-redgray-100 overflow-hidden shadow-lg font-poppins  text-gray-120 text-sm pb-5 h-120 border-b border-yellow-500">
            <SectionHead titleColor="gray-500" title="Tx Details" topMargin="mt-61" borderColor="blue" />
            <div className="grid grid-cols-2 gap-2 text-sm items-baseline mt-3">
              <div className="text-right  text-gray-500">USD to Pay:</div>
              <input type="text" name="usd_to_pay" className="text-sm pl-2 h-7 w-full bg-transparent text-gray-700 focus:outline-none uppercase" value={formatNumberToCurrency(data.transaction.usd_to_pay, 2)} readOnly />
            </div>
            <div className="grid grid-cols-2 gap-2 text-sm items-baseline mt-3">
              <div className="text-right  text-gray-500">Discount:</div>
              <input type="text" name="discount" className="text-sm pl-2 h-7 mt-1 mr-2 w-full bg-transparent text-gray-700 focus:outline-none uppercase" value={formatNumberToCurrency(data.transaction.discount, 2)} readOnly />
            </div>
            <div className="grid grid-cols-2 gap-2 text-sm items-baseline mt-3">
              <div className="text-right  text-gray-500">Fee:</div>
              <input type="text" name="fees" className="text-sm pl-2 h-7 mt-1 mr-2 w-full bg-transparent text-gray-700 focus:outline-none uppercase" value={formatNumberToCurrency(data.transaction.fees, 2)} readOnly />
            </div>
            <div className="grid grid-cols-2 gap-2 text-sm items-baseline mt-3">
              <div className="text-right  text-gray-500">USD to Send:</div>
              <input type="text" name="out_amount" className="text-sm pl-2 h-7 w-full bg-transparent text-gray-700 focus:outline-none uppercase" value={formatNumberToCurrency(data.transaction.usd_to_send, 2)} readOnly />
            </div>
            <div className="grid grid-cols-2 gap-2 text-sm items-baseline mt-3">
              <div className="text-right  text-gray-500">Amount to Receive:</div>
              <input type="text" name="out_amount" className="text-sm pl-2 h-7 mt-1 mr-2 w-full bg-transparent text-gray-700 focus:outline-none uppercase" value={data.transaction.out_amount + '  ' + data.transaction.out_currency_code} readOnly />
            </div>
          </div>
          <div className="col-span-4 text-sm div max-w-sm rounded-md bg-redgray-100 overflow-hidden shadow-lg font-poppins  h-35 border-b mt-5 pt-2  border-yellow-500">
            <>
              <TxDetail data={[...data.tx_details]}  readOnly />
            </>
          </div>
        </div> 
      </div>
    </>
  )
}

export default TransactionWizardStepThree