import React, { useEffect, useState } from 'react'
import axios from 'axios'
import BankDlg from './BankDlg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons';

const BankOverview = () => {

    const [editPopup, setEditPopup] = useState(false);
    const [currentBank, setCurrentBank] = useState()
    const [pageNo, setPageNo] = useState(1)

    const fetchNextPage = () => {
        setPageNo((pageNo) => ++pageNo)
        fetchData()
      }

      const fetchPreviousPage = () => {
        setPageNo((pageNo) => --pageNo)
        fetchData()
      }

    const bank = {

        bank_id: "",
        name: "",
        branch: "",
        country: "",
        code: "",

    }

    const [data, setData] = useState([])

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const res = await axios.get(`/api/allBranches?page=${pageNo}`);
            console.log(res.data)
            setData(res.data.data);
        } catch (err) {
            console.log(err);
        }



    }
    const editBank = (bank) => {
        setEditPopup(true)
        setCurrentBank(bank)
        
    }

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-3">
            <h1 className="text-gray-600 text-lg font-semibold mr-4 font-publicSans">Banks</h1>
            <hr className="my-3" />

            <div className=" flex items-center text-xs text-gray-500 ">
                <div className="font-noto font-semibold px-3 ml-auto"> page no : {pageNo} </div>
                <button
                    className={"focus:outline-none uppercase font-noto text-center text-steel-500 border rounded-l-full px-1 py-1   w-8 bg-gradient-to-b  hover:from-steel-400 hover:to-steel-500 hover:text-white hover:border-transparent "}
                  disabled={pageNo === 1}
                  onClick={() => fetchPreviousPage()}
                >
                    <FontAwesomeIcon icon={faLessThan} fixedWidth />
                    
                </button>

                <button
                    className={"ml-2 focus:outline-none uppercase font-noto text-center text-steel-500  border rounded-r-full px-1 py-1 w-8   bg-gradient-to-b hover:from-steel-400 hover:to-steel-500 hover:text-white hover:border-transparent "}
                //   disabled={data && data.data.count === pageNo}
                  onClick={() => fetchNextPage()}
                >
                    
                    <FontAwesomeIcon icon={faGreaterThan} fixedWidth />
                </button>
            </div>

            <div className="mt-4 rounded border-yellow-5001 border-t-4 border-blue-400 w-full overflow-x-auto">

                <table className="table-fixed text-sm w-full font-publicSans" style={{ width: "100%" }}>
                    <thead>
                        <tr className="border shadow bg-gray-100 border-l-2 border-r-2">
                            <th style={{ width: "200px" }} className=" border bg-gray-1001 text-gray-600">Bank</th>
                            <th style={{ width: "150px" }} className=" border bg-gray-1001 text-gray-600">Branch</th>
                            <th style={{ width: "50px" }} className=" border bg-gray-1001 text-gray-600">Code</th>
                            <th style={{ width: "50px" }} className=" border bg-gray-1001 text-gray-600">Country</th>
                            <th style={{ width: "50px" }} className=" border bg-gray-1001 text-gray-600"></th>
                        </tr>
                    </thead>

                    <tbody>
                        {data.map(bank => (
                            <tr key={bank.bank_id } className="text-gray-700 font-inter border-l-2 border-r-2 hover:bg-gray-50 hover:text-blue-800 hover:shadow hover:border-blue-400">
                                <td className="px-1 border ">{bank.name}</td>
                                <td className="px-1 border ">{bank.branch}</td>
                                <td className="px-1 border text-center">{bank.code}</td>
                                <td className="px-1 border text-center">{bank.country}</td>
                                <td className="border py-1">
                                    <div className="flex justify-center">
                                        <button
                                            className="bg-gray-100 text-gray-600 border1 border-gray-300 hover:border-blue-700 hover:bg-blue-500 hover:text-white rounded px-2 py-1 text-xs focus:outline-none"
                                            onClick={(e) => editBank(bank)}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
            {
                editPopup && (
                    <BankDlg currentBank={currentBank} setEditPopup={setEditPopup} setCurrentBank={setCurrentBank} />
                )
            }
        </div>
    );
}

export default BankOverview;